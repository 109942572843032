import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Button from "../../Components/Buttons/Button";
import {
  orderHistoryForAssignmentHelp,
  orderHistoryForLiveSessionHelp,
} from "../../services/orderHistory";
import { dateFormat } from "./dateFormat";
import Doubtq from "../../Assets/logo/logo.png";
import Email from "../../Assets/logo/email.png";
import YouTube from "../../Assets/logo/youtube.png";
import Instagram from "../../Assets/logo/instagram.png";
import Linkedin from "../../Assets/logo/linkedin.png";
import Facebook from "../../Assets/logo/facebook.png";
import jsPDF from "jspdf";
import { Helmet } from "react-helmet";

const DashboardOrderHistory = () => {
  const [pageData, setPageData] = useState([]);

  const [paymentSection, setPaymentSection] = useState("AssignmentHelp");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [invoice, setInvoice] = useState(null);
  const [status, setStatus] = useState("Unpaid");
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      let data;
      if (paymentSection === "AssignmentHelp") {
        data = await orderHistoryForAssignmentHelp("", currentPage, 10, status);
      } else {
        data = await orderHistoryForLiveSessionHelp(
          "",
          currentPage,
          10,
          status
        );
      }
      setPageData(data?.data || []);
      setTotalPages(data?.totalPages || 1);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [paymentSection, currentPage, status]);

  const handleSectionClick = (section) => {
    setPaymentSection(section);
    setCurrentPage(1);
  };

  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleViewButtonClick = (order) => {
    localStorage.setItem("serviceType", order.paymentSection);
    if (order.studentPaymentStatus === "Unpaid") {
      // navigate(`/orderStatus/${order._id}`);
      navigate(`/dashboard/orderStatus/${order._id}`);
    } else {
      const route =
        paymentSection === "AssignmentHelp"
          ? `/dashboard/viewanswer/${order._id}`
          : `/dashboard/viewanswer-live/${order._id}`;
      navigate(route, {
        state: order,
      });
    }
  };

  const formatTime = (time) => {
    return time < 10 ? "0" + time : time;
  };

  const formatDateAndTime = (date, time) => {
    const [year, month, day] = date.split("-");
    const formattedMonth = month.length === 1 ? `0${month}` : month;
    const formattedDay = day.length === 1 ? `0${day}` : day;
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
    const formattedTime = `${time}:00`;
    const dateTime = `${formattedDate}T${formattedTime}`;
    return dateTime;
  };

  const calculateDateTimeDifference = (dateTime1, dateTime2) => {
    const date1 = new Date(dateTime1);
    const date2 = new Date(dateTime2);
    const difference = Math.abs(date2 - date1);
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      hours,
      minutes,
      seconds,
    };
  };

  const calculateRemainingTime = (deadlineDate) => {
    const now = new Date().getTime();
    const difference = deadlineDate - now;
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return {
      hours,
      minutes,
      seconds,
    };
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedPageData = pageData.map((order) => {
        const dateTime1 = getCurrentDateTime();
        if (order.paymentSection === "LiveSessionHelp") {
          const dateTime2 = formatDateAndTime(order.date, order.time);
          if (dateTime2 > dateTime1) {
            const remainingTime = calculateDateTimeDifference(
              dateTime1,
              dateTime2
            );
            return { ...order, remainingTime };
          } else {
            return { ...order };
          }
        } else {
          if (order.deadlineDate > dateTime1) {
            const remainingTime = calculateRemainingTime(
              new Date(order.deadlineDate).getTime()
            );
            return { ...order, remainingTime };
          } else {
            return { ...order };
          }
        }
      });
      setPageData(updatedPageData);
    }, 1000);

    return () => clearInterval(interval);
  }, [pageData]);

  const yourInvoiceHTMLContent = `
  <!DOCTYPE html>
  <html>
  <head>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        background-color: #f8f8f8;
      }
  
      #invoice-content {
        width: 650px;
        margin: 0 auto;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-top: 6%;
      }
  
      .header, .footer {
        text-align: center;
        margin-bottom: 30px;
      }
  
      .header img {
        max-width: 150px;
      }
  
      .header h1 {
        margin: 0;
        font-size: 24px;
        color: #333;
      }
  
      .invoice-details, .invoice-summary {
        margin-bottom: 20px;
      }
  
      .invoice-details p, .invoice-summary p {
        margin: 5px 0;
        font-size: 14px;
      }
  
      .invoice-summary {
        border-top: 2px solid #333;
        padding-top: 10px;
      }
  
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
  
      table, th, td {
        border: 1px solid #ccc;
      }
  
      th, td {
        padding: 10px;
        text-align: left;
        font-size: 14px;
      }
  
      th {
        background-color: #f2f2f2;
      }
  
      .footer p {
        font-size: 12px;
        color: #777;
      }
      .footer{
        margin-top: 4%;
      }
      .contact-icons img {
        max-width: 24px;
        margin: 0 5px;
        padding-top: 20px;
      }
        .terms-conditions {
          margin-top: 20px;
          font-size: 12px;
        }
        .copyright{
          margin-top: 20px;
        }
    </style>
  </head>
  <body>
    <div id="invoice-content">
      <div class="header">
        <img src=${Doubtq} alt="Logo" />
        <h1>VAIDIK EDUCATION</h1>
        <p>B-603, Sky View Heights, Behind Midas Square, Surat, Gujarat, 395010, India</p>
      </div>
      <div class="invoice-details">
        <p><strong>BILL TO:</strong> ${invoice?.studentId?.email}</p>
        <p><strong>INVOICE ID:</strong> ${invoice?.displayId}</p>
        <p><strong>DATE:</strong> ${formatDate(invoice?.updatedAt)}</p>
        <p><strong>PAYMENT ID:</strong> ${invoice?.paymentId}</p>
        <p><strong>TYPE:</strong> ${invoice?.paymentSection}</p>
        <p><strong>CATEGORY:</strong> ${invoice?.subCategoryId?.name}</p>
        <p><strong>SUBJECT:</strong> ${invoice?.subjectId?.subSubjectName}</p>
      </div>
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Estimated Price</td>
            <td> ${invoice?.estimatedPrice?.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Promo Price</td>
            <td> ${invoice?.promoPrice?.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Discount Price</td>
              <td> ${invoice?.discountPrice?.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Total</td>
              <td> ${invoice?.totalPrice?.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <div class="invoice-summary">
        <p><strong>Total Amount Paid:</strong> ${invoice?.totalPrice?.toFixed(
          2
        )}</p>
      </div>
       <div class="terms-conditions">
        <h3>Terms and Conditions</h3>
          <p>1. <strong>Project Completion</strong>: Payment is contingent upon the successful completion of the project milestones.</p>
           <p>2. <strong>Dispute Resolution</strong>: Any disputes shall be resolved through info@doubtq.com or  arbitration in Surat, India.</p>
    </div>
      <div class="footer">
        <div class="contact-icons">
       <a href="https://www.facebook.com" target="_blank"><img src=${Facebook} alt="Facebook"/></a>
        <a href="https://www.linkedin.com" target="_blank"><img src=${Linkedin} alt="LinkedIn"/></a>
        <a href="https://www.instagram.com" target="_blank"><img src=${Instagram} alt="Instagram"/></a>
        <a href="https://www.youtube.com" target="_blank"><img src=${YouTube} alt="YouTube"/></a>
        <a href="mailto:info@doubtq.com" target="_blank"><img src=${Email} alt="Email"/></a>
        </div>
        <div class="copyright">
          <p>&copy; 2024 Doubtq.com. All rights reserved. | Get in touch: info@doubtq.com</p>
        </div>
      </div>
    </div>
  </body>
  </html>
  `;

  const generatePdf = () => {
    const doc = new jsPDF("p", "pt", "letter");

    const content = document.getElementById("invoice-content").innerHTML;

    doc.html(content, {
      callback: function (doc) {
        doc.save("invoice.pdf");
      },
      x: 20,
      y: 20,
      width: 572,
      windowWidth: 700,
    });
  };
  useEffect(() => {
    if (invoice) {
      generatePdf();
    }
  }, [invoice, yourInvoiceHTMLContent]);

  const handleInvoiceButtonClick = (order) => {
    setInvoice(order);
  };

  return (
    <div>
      <Helmet>
        <title>
          Order History | Get Personalized Academic Success with DoubtQ | Online
          Question Help
        </title>
        <meta
          name="description"
          content="Welcome to the Order History of DoubtQ"
        />
        <meta property="og:title" content="Order History - DoubtQ" />
        <meta
          property="og:description"
          content="Explore personalized academic help on DoubtQ's Order History"
        />
      </Helmet>
      <Container className="py-5 w-85 dashboard dash-mobile">
        <Row>
          <Col md="6">
            <Button
              className={`mt-3 w-100 order-btn ${
                paymentSection === "AssignmentHelp" ? "actives" : ""
              }`}
              text={"Assignment Help"}
              onClick={() => handleSectionClick("AssignmentHelp")}
              varient={
                paymentSection === "AssignmentHelp"
                  ? "linear-gradient-primary dashboard"
                  : ""
              }
            ></Button>
          </Col>
          <Col md="6">
            <Button
              className={`mt-3 w-100 order-btn ${
                paymentSection === "LiveSessionHelp" ? "actives" : ""
              }`}
              text={"Live Session Help"}
              onClick={() => handleSectionClick("LiveSessionHelp")}
              varient={
                paymentSection === "LiveSessionHelp"
                  ? "linear-gradient-primary dashboard"
                  : ""
              }
            ></Button>
          </Col>
        </Row>

        <Row className="pt-5 mb-4">
          <Col md="6">
            <h1 className="text-start mb-5">Order History</h1>
          </Col>
          <Col md="6" className="text-end">
            <span
              className={`payment-status paid ${
                status === "Unpaid" ? "active-pay" : ""
              }`}
              onClick={() => setStatus("Unpaid")}
            >
              Unpaid
            </span>
            <span
              className={`payment-status unpaid ${
                status === "Paid" ? "active-pay" : ""
              }`}
              onClick={() => setStatus("Paid")}
            >
              Paid
            </span>
          </Col>
        </Row>

        {pageData.length > 0 ? (
          pageData.map((order, index) => {
            return (
              <Row key={index} className="pt-3">
                <Col md="12" className="w-100">
                  <Card className="history-card p-0" style={{ zIndex: "111" }}>
                    <div
                      className="card-hed w-100 d-xl-flex justify-content-between rounded-top-4 px-4 py-4"
                      style={{ backgroundColor: "#D5D9F9" }}
                    >
                      <div className="text-start">
                        <h4>Order Id - {order.displayId} </h4>
                      </div>
                      {order.questionStatus === "Solved" ? (
                        <h4 style={{ color: "#25a53b" }}>Solved</h4>
                      ) : (
                        <div className="d-flex">
                          <h4 className="mx-2">Hrs Left - </h4>
                          <h4>
                            {order.remainingTime
                              ? formatTime(order.remainingTime.hours) + "h"
                              : "0h"}
                          </h4>
                          <h4 className="mx-2">:</h4>
                          <h4>
                            {order.remainingTime
                              ? formatTime(order.remainingTime.minutes) + "m"
                              : "0m"}
                          </h4>
                          <h4 className="mx-2">:</h4>
                          <h4>
                            {order.remainingTime
                              ? formatTime(order.remainingTime.seconds) + "s"
                              : "0s"}
                          </h4>
                        </div>
                      )}
                    </div>
                    <div
                      className="justify-content-between w-100 px-5 py-3"
                      style={{ zIndex: 11111 }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="text-start mb-3 mb-md-0">
                            <h4>Service type</h4>
                            <h5>{order?.subCategoryId?.name}</h5>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="text-start mb-3 mb-md-0">
                            <h4>Amount</h4>
                            <h5>${order?.totalPrice?.toFixed(2)}</h5>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="text-start mb-3 mb-md-0">
                            <h4>Order Date</h4>
                            <h5>{dateFormat(order.updatedAt)}</h5>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="text-end justify-content-between gap-1">
                            {order.studentPaymentStatus === "Completed" ||
                            order.studentPaymentStatus === "Paid" ? (
                              <>
                                <Button
                                  varient="outline-secondary"
                                  className="p-2 px-2 cancel-order"
                                  text={"Invoice"}
                                  onClick={() =>
                                    handleInvoiceButtonClick(order)
                                  }
                                ></Button>
                                <div
                                  id="invoice-content"
                                  style={{ display: "none" }}
                                  dangerouslySetInnerHTML={{
                                    __html: yourInvoiceHTMLContent,
                                  }}
                                ></div>
                              </>
                            ) : (
                              ""
                            )}
                            <Button
                              onClick={() => handleViewButtonClick(order)}
                              varient="linear-gradient-primary"
                              className="p-2 px-4 mx-2"
                              text={"View"}
                            ></Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            );
          })
        ) : (
          <h2>No data found.</h2>
        )}

        <Row className="pt-3 float-end">
          <Col className="text-center">
            <Pagination className="mt-4">
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                  previous
                  onClick={() => handlePaginationClick(currentPage - 1)}
                />
              </PaginationItem>
              {Array.from({ length: totalPages || 0 }, (_, i) => (
                <PaginationItem key={i + 1} active={i + 1 === currentPage}>
                  <PaginationLink onClick={() => handlePaginationClick(i + 1)}>
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage === (totalPages || 1)}>
                <PaginationLink
                  next
                  onClick={() => handlePaginationClick(currentPage + 1)}
                />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardOrderHistory;
