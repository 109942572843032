import React, { useEffect, useState } from "react";
import "./Universities.scss";
import background from "../Assets/images/New/Universities.png";
import Button from "./Buttons/Button";
import { getAllUniversity } from "../services/getAllUniversity";
import { Link } from "react-router-dom";

const Universities = ({
  categoryId,
  // setIsModelOpen = () => {},
  // btnDisabled = false,
}) => {
  const [universityData, setUniversityData] = useState([]);
  useEffect(() => {
    const fetchAllUniversityData = async () => {
      try {
        const response = await getAllUniversity();
        setUniversityData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllUniversityData();
  }, []);

  const chunkArray = (array, chunkCount) => {
    const chunkSize = Math.ceil(array.length / chunkCount);
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array?.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const universityChunks = chunkArray(universityData, 3);

  let titleText;

  switch (categoryId) {
    case "65524e633c005ef3b51907c9":
      titleText =
        "Assignment Help by DoubtQ Trusted by Over 50,000 Students Globally from Various Universities";
      break;
    case "65524e353c005ef3b51907c2":
      titleText =
        "Live Session Help by DoubtQ Trusted by Over 50,000 Students Globally from Various Universities";
      break;
    case "65524e5e3c005ef3b51907c6":
      titleText =
        "Homework Help by DoubtQ Trusted by Over 50,000 Students Globally from Various Universities";
      break;
  }

  return (
    <div
      className="universities-container"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="universities-steps-container text-center">
        <h2 className="universities-title">{titleText}</h2>
        <div className="universities-description">
          DoubtQ is trusted by international students. DoubtQ has helped 50,000+
          top university students. While institutions prepare students for
          success, we ease their academic path.
        </div>
      </div>
      <div className="universities-list">
        <div className="row">
          {universityChunks?.map((chunk, index) => (
            <div key={index} className="col-md-4 universitie-table">
              {chunk?.map((university, uniIndex) => (
                <Link
                  to={`/university/${university?.universityUrl}`}
                  className="text-decoration-none box"
                  key={uniIndex}
                >
                  {university?.universityName?.substring(0, 40) +
                    (university?.universityName?.length > 40 ? "..." : "")}
                </Link>
              ))}
            </div>
          ))}
        </div>
        {/* <div className="row">
          <div className="col-md-4 universitie-table">
            <div className="box many-more">
              <Link to="/university-list" className="many-more-btn">
                & Many More
              </Link>
            </div>
          </div>
        </div> */}
      </div>
      {/* {!btnDisabled ? ( */}
      <Link to="/university-list">
        <Button
          text={"& Many More"}
          varient="primary"
          // onClick={() => setIsModelOpen(true)}
        />
      </Link>
      {/* ) : (
        <></>
      )} */}
    </div>
  );
};

export default Universities;
