import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { scroller } from "react-scroll";
import "../../Components/Course/ParticularCourse.scss";
import CourseOverView from "../../Components/Course/CourseOverView";
import CourseReview from "../../Components/Course/CourseReview";
import CourseFaq from "../../Components/Course/CourseFaq";
import DashSpStudyNotes from "../../Components/Course/DashSpStudyNotes";
import DashPracPaper from "../../Components/Course/DashPracPaper";
import DashQuestionBank from "../../Components/Course/DashQuestionBank";
// import DashCombinedPackage from "../../Components/Course/DashCombinedPackage";
import { toast } from "react-toastify";
import { getCourseBySlug } from "../../services/getCourseBySlug";
import { Helmet } from "react-helmet";

const DashboardParticularCourse = () => {
  const { universitySlug, courseSlug } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 425);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      const response = await getCourseBySlug(universitySlug, courseSlug);

      if (response.isSuccess) {
        setData(response?.data);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch courses");
    }
  };

  useEffect(() => {
    fetchData();
  }, [universitySlug, courseSlug]);

  const handleTabClick = (id) => {
    setActiveTab(id);
    if (isMobile) {
      scroller.scrollTo("all-alphabet", {
        duration: 300,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -80,
      });
    }
  };

  const tabs = [
    { id: "1", label: "Overview" },
    { id: "2", label: "Special Study Notes" },
    { id: "3", label: "Practice Papers" },
    { id: "4", label: "Question Bank" },
    // { id: "5", label: "Combined Package" },
    { id: "5", label: "Reviews" },
    { id: "6", label: "FAQ's" },
  ];

  const activeTabLabel = tabs.find((tab) => tab.id === activeTab)?.label || "";

  return (
    <>
    <Helmet>
        <title>{`Get Certified in ${data?.courseName} | Expert-Led Online Course on DoubtQ`}</title>
        <meta
          name="description"
          content={`Enhance your knowledge and skills in ${data?.courseName} with expert-led courses on DoubtQ. Get comprehensive study materials, personalized guidance, and hands-on learning to achieve academic excellence!`}
        />
        <meta
          property="og:title"
          content={`${data?.courseName} Page - DoubtQ`}
        />
        <meta
          property="og:description"
          content={`Enhance your knowledge and skills in ${data?.courseName} with expert-led courses on DoubtQ. Get comprehensive study materials, personalized guidance, and hands-on learning to achieve academic excellence!`}
        />
      </Helmet>
      <div className="container">
        <div className="subject-tabs-div mb-5">
          <div className="row border">
            <div className="col-lg-4 col-md-4 px-0">
              <ul className="ft-link nav-tabs">
                {tabs.map((tab) => (
                  <li
                    key={tab.id}
                    className={`nav-item ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                    onClick={() => handleTabClick(tab.id)}
                  >
                    <Link to="#" className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-3">
                        {tab.label}
                      </div>
                      <i className="feather-chevron-right fs-1 visibility-hover" />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-8 col-md-8">
              <div className="row all-alphabet ps-4 pt-4">
                <h2 className="p-0">{activeTabLabel}</h2>
                {activeTab === "1" && (
                  <CourseOverView overviewData={data?.overview} />
                )}

                {activeTab === "2" && (
                  <DashSpStudyNotes
                    Link={data?.studyNotes}
                    course={data}
                    id={"spsn"}
                  />
                )}
                {activeTab === "3" && (
                  <DashPracPaper
                    Link={data?.practicePapers}
                    course={data}
                    id={"pp"}
                  />
                )}
                {activeTab === "4" && (
                  <DashQuestionBank
                    Link={data?.questionBank}
                    course={data}
                    id={"qb"}
                  />
                )}
                {/* {activeTab === "5" && (
                  <DashCombinedPackage Link={data?.combinedPackage} />
                )} */}
                {activeTab === "5" && (
                  <CourseReview
                    courseID={data?._id}
                    avgRatings={data?.ratings}
                  />
                )}
                {activeTab === "6" && <CourseFaq />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardParticularCourse;
