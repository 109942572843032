import React from "react";
import bannerimage from "../../Assets/images/background/icon-bg.png";
import file from "../../Assets/images/files.png";
import "./ParticularCollegeBanner.scss";
import { Link, useNavigate } from "react-router-dom";

const ParticularCollegeBanner = ({ data }) => {
  const navigator = useNavigate();
  const slug = data?.universityUrl;

  const handleNavigation = (filterValue) => {
    navigator(`/after-university/${slug}`, {
      state: {
        ...data,
        initialFilter: {
          "Items": filterValue
        }
      }
    });
  };

  return (
    <>
      <div className="banner-main-wrapper">
        <img
          className="banner-image"
          src={bannerimage}
          alt="ParticularCollege-banner"
        />
        <div className="banner-content-pc">
          <div className="banner-content-title-wrapper">
            <div className="title title-que-banner">
              <div>
                <p>
                  <strong className="ql-size-huge ql-font-monospace">
                    {data?.universityName}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="banner-content-subtitle pc-subtitle-pd">
            <span>
              <div>
                Latest uploads at {data?.universityName}. Looking for notes at{" "}
                {data?.universityName}? We have lots of notes, study guides and
                study notes available for your school.
              </div>
            </span>
          </div>

          <div className="container pt-5">
            <div className="clg-document">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row box-border">
                    <div className="col-lg-4 col-md-6">
                      <button
                        onClick={() => { handleNavigation("Special Study Notes") }}
                        className="text-decoration-none"
                        style={{ border: "none" }}
                      >
                        <div className="clg-document-box">
                          <img src={file} alt="file-icon" />
                          <p>
                            <span>{data?.studyNotesCount}</span> Special Study Notes
                          </p>
                          <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                      </button>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <button
                        onClick={() => { handleNavigation("Practice Papers") }}
                        className="text-decoration-none"
                        style={{ border: "none" }}
                      >
                        <div className="clg-document-box">
                          <img src={file} alt="file-icon" />
                          <p>
                            <span>{data?.practicePapersCount}</span> Practice Papers
                          </p>
                          <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                      </button>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <button
                        onClick={() => { handleNavigation("Question Bank") }}
                        className="text-decoration-none"
                        style={{ border: "none" }}
                      >
                        <div className="clg-document-box">
                          <img src={file} alt="file-icon" />
                          <p>
                            <span>{data?.questionBankCount}</span> Question Bank
                          </p>
                          <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticularCollegeBanner;
