import React, { useEffect, useState } from 'react'
import { getStory } from '../../services/getStory';
import { Container, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import "react-multi-carousel/lib/styles.css";
import "../../Components/StudentStories.scss";
import StudentStoriesIcon from "../../Assets/images/background/student-stories-icon.svg";
import Footer from '../../Components/Footer';
import ScrollTop from '../../Components/Buttons/ScrollTop';
import { Rating } from 'react-simple-star-rating';
import Men from "../../Assets/images/MEN.png"
import Women from "../../Assets/images/WOMEN.png"
import Navbar from '../../Components/Navbar';
import Reviewabanner from './Reviewabanner';
import Nav from '../../Components/Nav';
import { Link } from 'react-router-dom';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import GetPopup from './GetPopup';
import { getTestimonial } from '../../services/getAllTestimonial';
import ReactPlayer from "react-player";
import { FaRegPlayCircle } from 'react-icons/fa';
import WorldWide from '../../Components/WorldWide';
import { Helmet } from "react-helmet";


const Reviews = () => {
    const [storyData, setStoryData] = useState([]);
    const [testimonialData, setTestimonialData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(9);
    const fetchData = async () => {
        await getStory()
            .then((data) => {
                setStoryData(data.data);
            })
            .catch((error) => console.log(error));
    };
    const fetchTestimonialData = async () => {
        await getTestimonial()
            .then((data) => {
                setTestimonialData(data.data);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        fetchData();
        fetchTestimonialData();
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [playingIndex, setPlayingIndex] = useState(null);

    const openModal = (url, index) => {
        setVideoUrl(url);
        setIsOpen(true);
        setPlayingIndex(index);
    };

    const closeModal = () => {
        setIsOpen(false);
        setVideoUrl('');
    };


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = storyData.slice(indexOfFirstItem, indexOfLastItem);
    const currentTestimonial = testimonialData.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <Helmet>
                <title>Reviews | Get Personalized Academic Success with DoubtQ | Online Question Help</title>
                <meta name="description" content="Welcome to the Reviews of DoubtQ" />
                <meta property="og:title" content="Reviews - DoubtQ" />
                <meta property="og:description" content="Explore personalized academic help on DoubtQ's Reviews" />
            </Helmet>
            {/* <Nav />
            <Navbar /> */}
            <div className="row mt-4 mb-4 px-4 m-0">
                <div className="col-lg-12">
                    <div className="breadcrumb-inner text-start">
                        <ul className="page-list">
                            <li className="rbt-breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <div className="icon-right">
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                            </li>
                            <li className="rbt-breadcrumb-item active">Reviews</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Reviewabanner />
            <Container fluid>
                <main className="rbt-main-wrapper questionAnswerjustify">
                    <div className="student-stories-container banner">
                        <div className="carousel-container pt-4 pb-4">
                            <h1 className="text-center">Students Testimonials</h1>
                            <div className="row">
                                {currentTestimonial.map((e, index) => {
                                    const isPlaying = index === playingIndex;
                                    return (
                                        <div key={index} className="col-lg-4 mt-4">
                                            <div className="carousel-header">
                                                <div
                                                    className="react-player-wrapper"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {isPlaying ? (
                                                        <ReactPlayer
                                                            url={e.videoLink}
                                                            light={true}
                                                            playIcon={<i className='pay-icon'><FaRegPlayCircle /></i>}
                                                            width="100%"
                                                            height="300px"
                                                            className="react-player px-4"
                                                        />
                                                    ) : (
                                                        <div onClick={() => openModal(e.videoLink, index)}>
                                                            <ReactPlayer
                                                                url={e.videoLink}
                                                                light={true}
                                                                playIcon={<i className='pay-icon'><FaRegPlayCircle /></i>}
                                                                width="100%"
                                                                height="300px"
                                                                className="react-player px-4"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="float-end mt-4">
                                {testimonialData.length > itemsPerPage && (
                                    <Pagination className="pagination justify-content-center">
                                        <PaginationItem disabled={currentPage === 1}>
                                            <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
                                        </PaginationItem>
                                        {Array(Math.ceil(testimonialData.length / itemsPerPage))
                                            .fill()
                                            .map((_, i) => (
                                                <PaginationItem key={i} active={i + 1 === currentPage}>
                                                    <PaginationLink onClick={() => paginate(i + 1)}>
                                                        {i + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                        <PaginationItem disabled={currentPage === Math.ceil(testimonialData.length / itemsPerPage)}>
                                            <PaginationLink next onClick={() => paginate(currentPage + 1)} />
                                        </PaginationItem>
                                    </Pagination>
                                )}
                            </div>
                        </div>
                        <div className="carousel-container">
                            <h1 className="text-center">Students Reviews</h1>
                            <div className="row">
                                {currentItems.map((e, index) => {
                                    return (
                                        <div key={index} className="col-lg-4 mt-4">
                                            <div className="carousel">
                                                <div className="carousel-header">
                                                    <img
                                                        className="carousel-img"
                                                        src={e.status === "Male" ? Men : Women}
                                                        alt={e.status === "Male" ? "Man" : "Woman"}
                                                    />
                                                    <div>
                                                        <div className="carousel-title">{e.name}</div>
                                                        <div className="carousel-subtitle">{e.title}</div>
                                                    </div>
                                                    <div className="carousel-icon">
                                                        <img
                                                            src={StudentStoriesIcon}
                                                            className="carousel-img"
                                                            alt="carousel-icon"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="carousel-description">{e.description}</div>
                                                <div className='rating-review'>
                                                    <Rating
                                                        initialValue={parseFloat(e.rating)}
                                                        readonly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="float-end mt-4">
                                {storyData.length > itemsPerPage && (
                                    <Pagination className="pagination justify-content-center">
                                        <PaginationItem disabled={currentPage === 1}>
                                            <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
                                        </PaginationItem>
                                        {Array(Math.ceil(storyData.length / itemsPerPage))
                                            .fill()
                                            .map((_, i) => (
                                                <PaginationItem key={i} active={i + 1 === currentPage}>
                                                    <PaginationLink onClick={() => paginate(i + 1)}>
                                                        {i + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                        <PaginationItem disabled={currentPage === Math.ceil(storyData.length / itemsPerPage)}>
                                            <PaginationLink next onClick={() => paginate(currentPage + 1)} />
                                        </PaginationItem>
                                    </Pagination>
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </Container>
            <WorldWide />
            <Footer />
            <ScrollTop />
            <GetPopup />
            <Modal isOpen={isOpen} toggle={closeModal} className="video-modal" size="lg">
                <ModalHeader toggle={closeModal} className='popup-modal'></ModalHeader>
                <ModalBody>
                    <ReactPlayer
                        url={videoUrl}
                        controls
                        width="100%"
                        height="400px"
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}

export default Reviews