import React, { useEffect, useState, useCallback } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ExamPreparationCards.scss";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import File from "../../Assets/images/fileImg.png";
import { getCourseBySpecialStudyNotes } from "../../services/courseServices";

const StudyNotes = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
    },
  };

  const [storyData, setStoryData] = useState({
    data: [],
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
  });
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async (searchTerm = '', page = 1) => {
    setIsLoading(true);
    try {
      const response = await getCourseBySpecialStudyNotes({
        search: searchTerm,
        page,
        limit: 5
      });

      if (response?.isSuccess) {
        setStoryData({
          data: response.data || [],
          currentPage: response.pagination?.page || page,
          totalPages: response.pagination?.totalPages || 0,
          totalItems: response.pagination?.total || 0,
        });
      } else {
        setStoryData({ data: [], currentPage: 1, totalPages: 0, totalItems: 0 });
        console.error("API Error:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setStoryData({ data: [], currentPage: 1, totalPages: 0, totalItems: 0 });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let timeoutId = null;

    if (search.length >= 3) {
      timeoutId = setTimeout(() => {
        fetchData(search);
      }, 300);
    } else if (search.length === 0) {
      fetchData();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [search, fetchData]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="student-stories-container prac-paper-container">
      <div className="title-search">
        <div className="mb-3">
          <h1 className="text-start">Special Study Notes</h1>
          <p className="text-start">Recently Prepared DoubtQ</p>
        </div>
        <div className="prac-paper-search mb-3">
          <i className="feather-search fs-icon fs-2"></i>
          <input
            type="text"
            placeholder="Find Course, University"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      {isLoading ? (
        <p className="text-center mt-4">Loading...</p>
      ) : storyData?.data.length > 0 ? (
        <div className="carousel-container">
          <Carousel
            responsive={responsive}
            autoPlay={false}
            arrows={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
          >
            {storyData?.data.map((e, index) => (
              <div key={index} className="carousel story py-5 px-5 m-4">
                <Link
                  className="text-decoration-none"
                  to={`/course/${e?.university?.universityUrl}/${e.courseUrl}`}
                  state={{ id: "2" }}
                >
                  <div className="carousel-img-container text-center mb-5">
                    <img className="carousel-img" src={File} alt="file" />
                  </div>
                  <div className="subtitle-lh mt-2">
                    {e?.courseName?.length > 15
                      ? `${e.courseName.substring(0, 15)}...`
                      : e?.courseName}
                  </div>
                  <div className="dec-lh">
                    {e?.university?.universityName?.length > 30
                      ? `${e.university?.universityName.substring(0, 30)}...`
                      : e?.university?.universityName}
                  </div>
                  <div className="rating-stars">
                    <Rating
                      size={20}
                      initialValue={parseFloat(e.courseRatings)}
                      readonly
                    />
                  </div>
                </Link>
              </div>
            ))}
          </Carousel>
          <div className="mt-4">
            <Link to="/special-study-notes" className="text-decoration-none">
              <button className="rbt-btn btn-gradient btn-sm">
                View All Special Study Notes
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <p className="text-center mt-4">
          {search.length > 0 && search.length < 3
            ? "Please enter at least 3 characters to search"
            : "No results found"}
        </p>
      )}
    </div>
  );
};

export default StudyNotes;