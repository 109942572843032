import React from "react";
// import Nav from "../../Components/Nav";
// import Navbar from "../../Components/Navbar";
import AllUniversityBanner from "../../Components/University/AllUniversityBanner";
import FilterUniversity from "../../Components/University/FilterUniversity";
import Footer from "../../Components/Footer";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const AfterUniversity = () => {
  const location = useLocation();
  const { initialFilter, ...coursesData } = location.state || {};
  return (
    <>
      {/* <Nav />
      <Navbar /> */}
      <Helmet>
        <title>{`Explore the ${coursesData?.universityName} Courses | Personalized Learning with DoubtQ`}</title>
        <meta
          name="description"
          content={`Get expert academic support for ${coursesData?.universityName} students. Access top-quality assignment help, personalized tutoring, and timely solutions to excel in your studies with DoubtQ!`}
        />
        <meta
          property="og:title"
          content={`${coursesData?.universityName} Page - DoubtQ`}
        />
        <meta
          property="og:description"
          content={`Get expert academic support for ${coursesData?.universityName} students. Access top-quality assignment help, personalized tutoring, and timely solutions to excel in your studies with DoubtQ!`}
        />
      </Helmet>
      <AllUniversityBanner coursesData={coursesData} />
      <FilterUniversity initialFilter={initialFilter} />
      <Footer />
      <ScrollTop />
    </>
  );
};

export default AfterUniversity;
