import React, { useEffect, useState } from "react";
import "./DashDocPreview.scss";
import logo from "../../Assets/logo/logo.svg";
import { GiCheckMark } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Buttons/Button";

const DashDocPreview = ({ courseData, id }) => {
  const navigator = useNavigate();
  // let price = 0;  

  const [course, setCourse] = useState({});
  // const [isId, setIsId] = useState(null);

  useEffect(() => {
    setCourse(courseData);
    // setIsId(id);
  }, [courseData, id]);

  // if (course && Object.keys(course).length > 0) {
  //   if (isId) {
  //     if (id === "spsn") {
  //       price = course.studyNotes.price;
  //     } else if (id === "pp") {
  //       price = course.practicePapers.price;
  //     } else if (id === "qb") {
  //       price = course.questionBank.price;
  //     }
  //   } else {
  //     price =
  //       course?.type === "Special Study Notes"
  //         ? course?.studyNotes?.price
  //         : course?.type === "Practice Papers"
  //         ? course?.practicePapers?.price
  //         : course?.type === "Question Bank"
  //         ? course?.questionBank?.price
  //         : "N/A";
  //   }
  // }

  return (
    <div className="doc-preview-container mb-5">
      <div className="both-pages">
        {/* Page 1 */}
        <div className="page-one">
          <div className="company-logo">
            <img src={logo} alt="Company Logo" />
          </div>
          <div className="course-name">
            <p className="">
              University Name:
              <Link
                // to={course.courseUrl}
                className="text-black text-decoration-none"
              >
                <span className="text-black">
                  {" "}
                  {courseData?.university?.universityName}
                </span>
              </Link>
            </p>
            <p className="">
              Course Name:
              <Link
                // to={course.courseUrl}
                className="text-black text-decoration-none "
              >
                <span className="text-black"> {course?.courseName}</span>
              </Link>
            </p>
            <p className="">
              Material:
              <span className="text-black"> {courseData?.tempCourse?.courseType}</span>
            </p>
          </div>

          <div className="position-absolute bottom-0 end-0 p-3">
            <p>Page 1</p>
          </div>
        </div>

        {/* Page 2 */}
        <div className="page-two">
          <div className="blur-overlay">
            <div className="overlay-content">
              <h1>Export Study Material</h1>
              <h1> ${courseData?.tempCourse?.coursePrice || 0}</h1>
              {/* <p>This course examines number of theories of personality,</p> */}
              <Button
                type="submit"
                text="Buy Now"
                varient="linear-gradient-primary"
                className=" my-1 px-4 py-3"
                onClick={() => {
                  navigator("/dashboard/paynow", {
                    state: { ...course, price: courseData?.tempCourse.coursePrice || 0 },
                  });
                }}
              />
              <p className="mb-4">All Rights Reserved to DoubtQ !</p>

              <div className="overlay-content-list">
                <div className="overlay-content-item">
                  <GiCheckMark className="overlay-content-item-icon" />
                  <p>100% satisfaction guarantee</p>
                </div>
                <div className="overlay-content-item">
                  <GiCheckMark className="overlay-content-item-icon" />
                  <p>Accurate Study Notes</p>
                </div>
                <div className="overlay-content-item">
                  <GiCheckMark className="overlay-content-item-icon" />
                  <p>Available in PDF</p>
                </div>
                <div className="overlay-content-item">
                  <GiCheckMark className="overlay-content-item-icon" />
                  <p>No Additional Costs</p>
                </div>
              </div>
            </div>
          </div>
          <div className="position-absolute bottom-0 end-0 p-3">
            <p>Page 2</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashDocPreview;
