import React, { useEffect, useState, useCallback } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ExamPreparationCards.scss";
import { Link } from "react-router-dom";
import hook from "../../Assets/icons/hook.jpg";
import { getHotCourses } from "../../services/getHotCourses";

const HotCoursesHelp = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
    },
  };

  const [storyData, setStoryData] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const fetchData = useCallback(async (searchTerm) => {
    setIsLoading(true);
    try {
      const response = await getHotCourses(searchTerm);
      if (response?.isSuccess) {
        setStoryData(response.data.slice(0, 5));
      } else {
        setStoryData([]);
        console.error("API Error:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setStoryData([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!initialDataFetched) {
      fetchData("");
      setInitialDataFetched(true);
    }
  }, [fetchData, initialDataFetched]);

  useEffect(() => {
    let timeoutId = null;

    if (search.length >= 3) {
      timeoutId = setTimeout(() => {
        fetchData(search);
      }, 300);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [search, fetchData]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (!value.trim()) {
      fetchData("");
    }
  };

  return (
    <div className="student-stories-container prac-paper-container">
      <div className="title-search">
        <div className="mb-3">
          <h1 className="text-start">Hot Courses Help</h1>
          <p className="text-start">Taken by DoubtQ Students</p>
        </div>
        <div className="prac-paper-search mb-3">
          <i className="feather-search fs-icon fs-2"></i>
          <input
            type="text"
            placeholder="Find Course, University"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {isLoading ? (
        <p className="text-center mt-4">Loading...</p>
      ) : storyData.length > 0 ? (
        <div className="carousel-container">
          <Carousel
            responsive={responsive}
            autoPlay={false}
            arrows={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
          >
            {storyData?.map((e, index) => {
              return (
                <div
                  key={index}
                  className="carousel story py-5 px-5 m-4 carousel-bg"
                >
                  <div
                    className="bg-white text-center radius-10"
                    style={{ borderTop: "40px solid #6754E9" }}
                  >
                    <div style={{ borderTop: "10px solid #C948E6" }}>
                      <div>
                        <h2 className=" mt-5 text-center">
                          {e?.courseName?.length > 15
                            ? `${e.courseName.substring(0, 15)}...`
                            : e?.courseName}
                        </h2>
                        <div className="dec-lh text-center pb-3">
                          {e?.university?.universityName?.length > 25
                            ? `${e.university?.universityName.substring(
                                0,
                                25
                              )}...`
                            : e?.university?.universityName}
                        </div>
                      </div>
                      <div className="text-center">
                        <Link to={`/course/${e?.university?.universityUrl}/${e?.courseUrl}`}>
                          <button className="rbt-btn btn-gradient btn-sm view-btn mb-5">
                            View
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <img src={hook} alt="hook" className="hook-icon" />
                </div>
              );
            })}
          </Carousel>
          <div className="mt-4">
            <button className="rbt-btn btn-gradient btn-sm">
              View All Courses
            </button>
          </div>
        </div>
      ) : (
        <p className="text-center mt-4">No results found</p>
      )}
    </div>
  );
};

export default HotCoursesHelp;
