import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./AllSubjectsCategory.scss";
import Home from "../Assets/images/home.svg";
import { scroller } from "react-scroll";
import { getSubjectWiseSubSubject } from "../services/getSubjectWiseSubSubject";

const AllSubjectsCategory = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
  const [subSubjectData, setSubSubjectData] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [filterLetter, setFilterLetter] = useState("All");

  const fetchSubSubjectWiseData = async () => {
    try {
      const response = await getSubjectWiseSubSubject();
      setSubSubjectData(response.data);
      if (response.data.length > 0) {
        setActiveTab(response.data[0].mainSubjectId);
      }
    } catch (error) {
      console.error("Error fetching subsubject data:", error);
    }
  };

  useEffect(() => {
    fetchSubSubjectWiseData();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 425);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTabClick = (id) => {
    setActiveTab(id);
    if (isMobile) {
      scroller.scrollTo("tab-content", {
        duration: 300,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -80,
      });
    }
  };

  const handleFilterClick = (letter) => {
    setFilterLetter(letter);
  };

  return (
    <div className="container my-5">
      {subSubjectData.length > 0 && (
        <>
          <div className="alphabetic-tabs mt--40 mb--40">
            <div className="row d-flex justify-content-center alphabetic-tabs-div">
              <ul className="d-flex mb-0 flex-wrap justify-content-center">
                <li className={`${filterLetter === "All" ? "active" : ""}`}>
                  <Link to="#" onClick={() => handleFilterClick("All")}>
                    All
                  </Link>
                </li>
                {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((letter) => (
                  <li
                    key={letter}
                    className={`${filterLetter === letter ? "active" : ""}`}
                  >
                    <Link to="#" onClick={() => handleFilterClick(letter)}>
                      {letter}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="subject-tabs-div">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <ul className="ft-link nav-tabs">
                  {subSubjectData?.map((subject) => (
                    <li
                      key={subject?.mainSubjectId}
                      className={`nav-item ${
                        activeTab === subject?.mainSubjectId ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(subject?.mainSubjectId)}
                    >
                      <Link to="#" className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-3">
                          <div className="rbt-icon-1">
                            <img
                              src={Home}
                              //   src={subject.mainSubjectImage}
                              alt={subject?.mainSubject}
                              width={25}
                              height={25}
                              className="home-img"
                            />
                          </div>
                          <span className="text-capitalize">
                            {subject?.mainSubject?.replace(" Academic Help", "")}
                          </span>
                        </div>
                        <i className="feather-chevron-right fs-1 visibility-hover" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-8 col-md-8">
                <div id="tab-content" className="tabcontent">
                  <div className="subject-tabs">
                    <h2 className="mb-4 text-start">
                      {subSubjectData.find(
                        (subject) => subject?.mainSubjectId === activeTab
                      )?.mainSubject?.replace(" Academic Help", "") || "Select a Subject"}
                    </h2>
                    <ul className="d-flex justify-content-start flex-wrap">
                      {subSubjectData
                        .find((subject) => subject?.mainSubjectId === activeTab)
                        ?.subSubject?.filter(
                          (subobjectt) =>
                            filterLetter.toUpperCase() === "ALL" ||
                            subobjectt?.subSubjectName
                              ?.toUpperCase()
                              .startsWith(filterLetter.toUpperCase())
                        )
                        ?.map((subSubject) => (
                          <li key={subSubject?._id}>
                            <Link
                              to={`/${
                                subSubjectData.find(
                                  (subject) =>
                                    subject?.mainSubjectId === activeTab
                                )?.subjectUrl
                              }/${subSubject?.subSubjectUrl}`}
                            >
                               <span className="text-capitalize">{subSubject?.subSubjectName?.replace(" Academic Help", "")}</span>
                            </Link>
                          </li>
                        ))}
                      {subSubjectData
                        .find((subject) => subject?.mainSubjectId === activeTab)
                        ?.subSubject?.filter(
                          (subobjectt) =>
                            filterLetter.toUpperCase() === "ALL" ||
                            subobjectt?.subSubjectName
                              ?.toUpperCase()
                              .startsWith(filterLetter.toUpperCase())
                        ).length === 0 && (
                        <p className="fs-3">No sub-subject found.</p>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AllSubjectsCategory;
