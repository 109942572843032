import React, { useContext, useState } from "react";
import { CategoryContext } from "../../categoryContext";
// import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
// import Nav from "../../Components/Nav";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import AllSubjectsBanner from "../../Components/AllSubjectsBanner";
import { Link } from "react-router-dom";
// import Page from '../Metadata/Page'
import AllSubjectsCategory from "../../Components/AllSubjectsCategory";
import BonusSection from "../../Components/BonusSection";
import AskQue from "../../Components/AskQue";
import WorldWide from "../../Components/WorldWide";
import RecentlyAskedQue from "../../Components/RecentlyAskedQue";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import CommonForm from "../HomePage/Form/CommonForm";
import { Helmet } from "react-helmet";

const AllSubjects = () => {
  const { categoryId } = useContext(CategoryContext);
  const subCategoryId = "655659b9b9cbef8acade1d7c";
  const [isModelOpen, setIsModelOpen] = useState(false);
  const toggle = () => setIsModelOpen(!isModelOpen);

  return (
    <div>
      <Helmet>
        <title>
          Expert Online Assignment & Homework Help for All Subjects | Get
          Personalized Academic Success with DoubtQ
        </title>
        <meta
          name="description"
          content="Get professional assistance with your assignments and homework across all subjects from top tutors. Expert guidance, timely solutions, and high-quality support to help you excel in your studies!"
        />
        <meta property="og:title" content="All Subjects Page - DoubtQ" />
        <meta
          property="og:description"
          content="Get professional assistance with your assignments and homework across all subjects from top tutors. Expert guidance, timely solutions, and high-quality support to help you excel in your studies!"
        />
      </Helmet>
      {/* <Page subCategoryId={subCategoryId} categoryId={categoryId} /> */}
      {/* <Nav />
      <Navbar /> */}
      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">Subjects</li>
            </ul>
          </div>
        </div>
      </div>
      <AllSubjectsBanner setIsModelOpen={setIsModelOpen} />
      <AllSubjectsCategory />
      <RecentlyAskedQue />
      <div className="mt-5">
        <AskQue />
      </div>
      <BonusSection subCategoryId={categoryId} />
      <WorldWide />
      <Footer />
      {isModelOpen && (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "AssignmentHelp",
            type: "assignmentHelp",
            totalSteps: 4,
          }}
          subCategory={subCategoryId}
        />
      )}
      <ScrollTop />
    </div>
  );
};

export default AllSubjects;
