import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import "./AllUniversity.scss";
import { getAllUniversityByCountry } from "../../services/getAllUniversityByCountry";

const AllUniversity = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
  const [rawData, setRawData] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [selectedAlphabet, setSelectedAlphabet] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 425);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllUniversityByCountry();
        setRawData(response.data);
      } catch (error) {
        console.error("Error fetching university data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (rawData.length > 0) {
      const countryTabs = rawData.map((item) => ({
        id: item.countryName.toLowerCase(),
        label: item.countryName,
        university: item.universitiesData.map((uni) => ({
          name: uni.universityName,
          url: uni.universityUrl,
        })),
      }));

      const allUniversitiesTab = {
        id: "all",
        label: "All Universities",
        university: rawData.flatMap((item) =>
          item.universitiesData.map((uni) => ({
            name: uni.universityName,
            url: uni.universityUrl,
          }))
        ),
      };

      setTabs([allUniversitiesTab, ...countryTabs]);
    }
  }, [rawData]);

  const handleTabClick = (id) => {
    setActiveTab(id);
    setSelectedAlphabet(null);
    if (isMobile) {
      scroller.scrollTo("all-alphabet", {
        duration: 300,
        smooth: "easeInOutQuart",
        offset: -80,
      });
    }
  };

  const handleAlphabetClick = (letter) => {
    setSelectedAlphabet(letter);
  };

  const filteredUniversities = tabs
    .find((tab) => tab.id === activeTab)
    ?.university.filter((university) => {
      if (!selectedAlphabet) return true;
      return university.name.toUpperCase().startsWith(selectedAlphabet);
    });

  return (
    <div className="container">
      {rawData.length > 0 && (
        <>
          <div className="alphabetic-tabs mt--40 mb--40">
            <div className="row d-flex justify-content-center alphabetic-tabs-div">
              <ul className="d-flex mb-0 flex-wrap justify-content-center">
                <li
                  className={`${!selectedAlphabet ? "active" : ""}`}
                  onClick={() => handleAlphabetClick(null)}
                >
                  <Link to="#">All</Link>
                </li>
                {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((letter) => (
                  <li
                    key={letter}
                    className={`${selectedAlphabet === letter ? "active" : ""}`}
                    onClick={() => handleAlphabetClick(letter)}
                  >
                    <Link to="#">{letter}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="subject-tabs-div mb-5">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <ul className="ft-link nav-tabs">
                  {tabs.map((tab) => (
                    <li
                      key={tab.id}
                      className={`nav-item ${
                        activeTab === tab.id ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(tab.id)}
                    >
                      <Link to="#" className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-3">
                          {tab.label}
                        </div>
                        <i className="feather-chevron-right fs-1 visibility-hover" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="row all-alphabet">
                  <h2 className="mb-4">
                    {tabs.find((tab) => tab.id === activeTab)?.label ||
                      "Select a Tab"}
                  </h2>
                  {filteredUniversities && filteredUniversities.length > 0 ? (
                    filteredUniversities.map((university, index) => (
                      <div
                        key={index}
                        className="col-lg-6 col-md-12 col-12 uni-name-container"
                      >
                        <Link
                          to={`/university/${university?.url}`}
                          className="university-name text-decoration-none"
                        >
                          {university?.name}
                        </Link>
                        <hr className="my-4" />
                      </div>
                    ))
                  ) : (
                    <p className="fs-3">No universities found.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AllUniversity;
