import React, { useEffect, useState } from "react";
import {
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";
import "./RecentlyAskedQue.scss";
// import { searchQuestion } from "../../services/searchQuestion";
import noques from "../Assets/images/EFEFEFW 1.png";
// import { getAllSubject } from "../services/getAllSubject";
import Button from "./Buttons/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import Smile_Icon from "../Assets/images/Smile_Icon.svg";
import sealCheck from "../Assets/images/sealCheck.svg";
// import CommonForm from "/HomePage/Form/CommonForm";
import CommonForm from "../Pages/HomePage/Form/CommonForm";
import { getAllsubSubject } from "../services/getAllSubSubject";
import { recentQuestionBySubject } from "../services/recentQuestionBySubject";



const RecentlyAskedQueSubject = ({ subjectId }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [formData, setFormData] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isDataFetching, setIsDataFetching] = useState(true);
    const navigate = useNavigate();
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [serviceType, setServiceType] = useState("");


    const fetchData = async () => {
        try {
            // const search = searchParams.get("query");
            const data = await recentQuestionBySubject(currentPage, 5, subjectId);
            setPageData(data.data);
            setTotalPages(data.totalPages);
            setIsDataFetching(false);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSubjectData = async () => {
        try {
            // const response = await getAllSubject();
            const response = await getAllsubSubject();
            setFormData(response.data);
        } catch (error) {
            console.error("Error fetching subject data:", error);
        }
    };

    useEffect(() => {
        fetchData();
        setIsDataFetching(true);
    }, [searchParams]);

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, subjectId]);

    useEffect(() => {
        fetchSubjectData();
    }, []);

    const handlePaginationClick = (pageNo) => {
        setCurrentPage(pageNo);
    };

    const handleViewAnswer = (text = "") => {
        navigate(`/homework-help/question-and-answer/${text}`);
    };

    const handlePopup = (serviceType, type, totalSteps) => {
        setIsModelOpen(true);
        setServiceType({ serviceType, type, totalSteps });
        localStorage.setItem("serviceType", serviceType);
    };

    const [isModelOpen1, setIsModelOpen1] = useState(false);
    const toggle = () => {
        setIsModelOpen1(!isModelOpen1);
        setIsModelOpen(!isModelOpen);
    };

    return (
        <>
            <Container className="mb-5 py-5">
                <div className="library-page search-que">
                    <h2 className="text-start">
                        Recently asked questions by our students:
                    </h2>
                </div>
                <div className="mt-4 que-top">
                    {pageData && pageData.length > 0 ? (
                        pageData.map((item, index) => (
                            <div key={index} className="search-que">
                                <div
                                    className={`library-card d-grid card-${index % 2 === 0 ? "even" : "odd"
                                        }`}
                                >
                                    <div className="que">
                                        <div className="text-start d-flex">
                                            <span>Q:</span>
                                            <p className="mx-2 text-start">
                                                {item?.transcriptText &&
                                                    item.transcriptText.slice(0, 200) +
                                                    (item.transcriptText.length > 200 ? "..." : "")}
                                            </p>
                                        </div>
                                    </div>
                                    {item?.answer && (
                                        <div className="que mt-4">
                                            <div className="text-start d-flex">
                                                <span>A:</span>
                                                <div
                                                    className="mx-2 text-gray text-start"
                                                    dangerouslySetInnerHTML={{
                                                        __html: `${item?.answer.slice(0, 20)}${item?.answer.length > 20 ? "..." : ""
                                                            }`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Button
                                                text="View answer"
                                                varient="search"
                                                className="view-button mt-4 p-0 mx-4 d-block"
                                                onClick={() => handleViewAnswer(item?.transcriptUrl)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="verified-solutions">
                                                <img src={sealCheck} alt="sealCheck" className="mb-1" />
                                                Verified Solutions
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : isDataFetching ? (
                        <div className="fs-4"></div>
                    ) : (
                        <div>
                            <div className="no-que mt-4">
                                <img src={noques} alt="no" />
                            </div>
                            <h2 className="no-data-message mt-4">NO SOLUTION FOUND</h2>
                            <div
                                style={{
                                    backgroundColor: "#f0f0f0",
                                    width: "90%",
                                    padding: "10px",
                                    margin: "50px auto 0",
                                    textAlign: "center",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-md-2 mb-2">
                                        <img
                                            src={Smile_Icon}
                                            alt="smile"
                                            style={{ width: "80px" }}
                                        />
                                    </div>
                                    <div
                                        className="col-md-3  d-flex align-items-center"
                                        style={{
                                            position: "relative",
                                            textAlign: "left",
                                            height: "100%",
                                        }}
                                    >
                                        <h1 style={{ margin: 0, fontWeight: "800" }}>
                                            <span style={{ color: "black" }}>Don't</span>{" "}
                                            <span style={{ color: "blue" }}>worry</span>
                                        </h1>
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "-10px",
                                                bottom: "-10px",
                                                right: "-10px",
                                                borderRight: "2px solid #CCCCCC",
                                            }}
                                        ></div>
                                    </div>
                                    <div className="col-md-3 mb-3 px-3">
                                        <h1
                                            style={{
                                                fontSize: "15px",
                                                color: "#000000",
                                                textAlign: "start",
                                            }}
                                        >
                                            Ask an expert.
                                        </h1>
                                        <div
                                            style={{
                                                fontSize: "12px",
                                                color: "black",
                                                fontWeight: "bold",
                                                textAlign: "start",
                                            }}
                                        >
                                            Get a step-by-step solution in as little as 15 minutes!*
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3 px-3">
                                        <button
                                            className="do-my-ass"
                                            style={{
                                                background:
                                                    "linear-gradient(90deg, #5956e9 5.64%, #e541ed 96.39%)",
                                                boxShadow: "0px 10px 20px rgba(74, 74, 112, 0.1)",
                                                border: "2px solid #FFFFFF",
                                                fontSize: "14px",
                                                color: "#FFF",
                                                borderRadius: "9px",
                                                padding: "1rem",
                                            }}
                                            onClick={() => handlePopup("HomeworkHelp", "mcqHelp", 3)}
                                        >
                                            Ask a Question
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="float-end">
                        <Pagination className="mt-4">
                            <PaginationItem disabled={currentPage === 1}>
                                <PaginationLink
                                    previous
                                    onClick={() => handlePaginationClick(currentPage - 1)}
                                />
                            </PaginationItem>
                            <PaginationItem active={currentPage === 1}>
                                <PaginationLink onClick={() => handlePaginationClick(1)}>
                                    1
                                </PaginationLink>
                            </PaginationItem>
                            {totalPages > 2 &&
                                [2, 3].map((page) => (
                                    <PaginationItem key={page} active={currentPage === page}>
                                        <PaginationLink onClick={() => handlePaginationClick(page)}>
                                            {page}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                            {currentPage > 4 && totalPages > 5 && (
                                <PaginationItem disabled>
                                    <PaginationLink>...</PaginationLink>
                                </PaginationItem>
                            )}
                            {currentPage > 3 && currentPage < totalPages && (
                                <PaginationItem active>
                                    <PaginationLink
                                        onClick={() => handlePaginationClick(currentPage)}
                                    >
                                        {currentPage}
                                    </PaginationLink>
                                </PaginationItem>
                            )}
                            {currentPage < totalPages - 3 && totalPages > 5 && (
                                <PaginationItem disabled>
                                    <PaginationLink>...</PaginationLink>
                                </PaginationItem>
                            )}
                            {totalPages > 3 && (
                                <PaginationItem active={currentPage === totalPages}>
                                    <PaginationLink
                                        onClick={() => handlePaginationClick(totalPages)}
                                    >
                                        {totalPages}
                                    </PaginationLink>
                                </PaginationItem>
                            )}
                            <PaginationItem disabled={currentPage === totalPages}>
                                <PaginationLink
                                    next
                                    onClick={() => handlePaginationClick(currentPage + 1)}
                                />
                            </PaginationItem>
                        </Pagination>
                    </div>
                </div>
            </Container>
            {isModelOpen ? (
                <CommonForm
                    isOpen={isModelOpen}
                    toggle={toggle}
                    typeData={serviceType}
                />
            ) : (
                <></>
            )}

        </>
    );
};

export default RecentlyAskedQueSubject;
