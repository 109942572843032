import React, { useCallback, useEffect, useState } from "react";
import thankYou from "../../Assets/images/thankyou.png";
import pdf from "../../Assets/images/pdf.png";
import doc from "../../Assets/images/xls.png";
import "../thankyou/ThankYou.scss";
import { Col, Row } from "reactstrap";
import { MultiStepProgressBar } from "../../Components/Popup/MultiStepProgressBar";
import { Link, useSearchParams } from "react-router-dom";
import Button from "../../Components/Buttons/Button";
import { Rating } from "react-simple-star-rating";
import { getSettings } from "../../services/getSettings";
import { getCoursesRandomly } from "../../services/getCoursesRandomly";
import axios from "axios";

const DashboardThankYou = () => {
  const [courseData, setCourseData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentIntent = searchParams.get("payment_intent");
  const [orderData, setOrderData] = useState(() => {
    return JSON.parse(localStorage.getItem("orderData")) || null;
  });
  useEffect(() => {
    const fetchSuggestedCourseData = async () => {
      try {
        const response = await getCoursesRandomly();
        setCourseData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSuggestedCourseData();
  }, []);

  const [settingData, setSettingData] = useState([]);

  const fetchSettingData = async () => {
    try {
      const res = await getSettings();
      setSettingData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSettingData();
  }, []);

  const updatePayment = useCallback(async () => {
    if (paymentIntent) {
      const token = localStorage.getItem("token");
      const apiEndPoint = process.env.REACT_APP_API;
      const response = await axios.post(
        `${apiEndPoint}student/payment/paymentDetailsCourse`,
        { paymentIntent },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      if (data.isSuccess) {
        setSearchParams();
        setOrderData(data);
        // Store orderData in localStorage
        localStorage.setItem("orderData", JSON.stringify(data));
      }
    }
  }, [paymentIntent]);

  useEffect(() => {
    updatePayment();
  }, [orderData]);

  return (
    <>
      <div className="container my-5">
        <div className="">
          <img src={thankYou} alt="thank you" className="img-fluid" />
        </div>
        <div className="thank-you-container">
          <h2 className="course-name">
            {orderData?.data?.courseData?.courseId?.courseName}
          </h2>
          <h3 className="college-name">
            {orderData?.data?.courseData?.university} -{" "}
            {orderData?.data?.courseData?.courseInnerType}
          </h3>
          <p className="dec">
            ( Our Expert team is working on your{" "}
            {orderData?.data?.courseData?.courseInnerType} )
          </p>
          <hr />
          <h1 className="order-time">
            You will Receive Your Materials by 24 Hours
          </h1>
          <p className="dec">
            ( We will send the materials by your whatsapp Number or email
            address )
          </p>
        </div>
        <div className="progress-bar-line">
        <Row className="m-3 custom-w-progress-line">
            <Col className="align-self-center mb-5">
              <MultiStepProgressBar
                step={2}
                totalSteps={4}
                hasStepZero={true}
                stepTexts={[
                  <span className="steps-css">Expert Compilation</span>,
                  <span className="steps-css">Content Formatting</span>,
                  <span className="steps-css">Thorough Review</span>,
                  <span className="steps-css">Final Delivery</span>,
                ]}
              />
            </Col>
          </Row>
        </div>
        <div className="order-contact">
          <div className="py-5">
            <h3 className="college-name">
              {orderData?.data?.courseData?.university} -{" "}
              {orderData?.data?.courseData?.courseInnerType}
            </h3>
            <div className="pdf-doc-container">
              <img src={pdf} alt="pdf" />
              <img src={doc} alt="doc" />
            </div>
            <h1 className="order-time">You Materials will be Updated soon!</h1>
            <h3 className="till-contact">
              Till that time please contact through our executive to regular
              track your materials.
            </h3>
            <div className="all-btn-container">
              <Link
                to={`mailto:${settingData?.contactEmail}`}
                className="text-decoration-none"
                target="_blank"
              >
                <button className="email-w-us">
                  <i
                    className="fa-regular fa-envelope"
                    style={{ color: "#fff" }}
                  ></i>
                  <span>Email us</span>
                </button>
              </Link>
              <Link
                className="text-decoration-none"
                to={`https://wa.me/91${settingData?.contactNumber}`}
                target="_blank"
              >
                <button className="chat-w-whatsapp">
                  <i
                    className="fa-brands fa-whatsapp"
                    style={{ color: "#fff" }}
                  ></i>
                  <span>Chat with us</span>
                </button>
              </Link>
              <Link
                className="rbt-btn btn-gradient btn-sm text-decoration-none"
                to="/"
              >
                Back To Homepage
              </Link>
            </div>
          </div>
        </div>
        <hr className="my-5" />
        {/* <SuggestedCourseCard /> */}

        <div className="row">
          <h3 className="my-5">
            Suggested Courses based on the document that you are currently
            viewing
          </h3>
          {courseData?.map((course, index) => (
            <div
              className="col-lg-6 col-12 mb-4 d-flex justify-content-center"
              key={index._id}
            >
              <div className="suggestion-card">
                <div>
                  <div className="uni-name">
                    <p>
                      {course?.courseName?.length > 15
                        ? `${course?.courseName.substring(0, 15)}...`
                        : course?.courseName}
                      <span>
                        (
                        {course?.university?.universityName?.length > 15
                          ? `${course.university?.universityName.substring(
                              0,
                              15
                            )}...`
                          : course?.university?.universityName}
                        )
                      </span>
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <div className="rating-stars">
                      <Rating
                        size={20}
                        initialValue={parseFloat(course?.ratings)}
                        readonly
                      />
                    </div>
                    <div className="purchase-text">
                      <i className="fa-solid fa-cart-shopping pe-2"></i>
                      <span>{course?.purchases} Purchases</span>
                    </div>
                  </div>
                </div>
                <div>
                  <Link
                    to={`/dashboard/home/course/${course?.university?.universityUrl}/${course?.courseUrl}`}
                  >
                    <Button
                      varient="linear-gradient-primary"
                      className="px-3 py-2 fs-5"
                      text={"View"}
                    ></Button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DashboardThankYou;
