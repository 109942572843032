import React from 'react'
import { Link } from 'react-router-dom'
import EduReviewer from './EduReviewer'
import Sitejabber from './Sitejabber'
// import TrustPilot from './TrustPilot'
import StudentReviews from './StudentReviews'
import './SiteReviews.scss'

const SiteReviews = () => {

    return (
        <div className='main-div'>
            <div className='r-title'>
                <p>Trusted by 10K+ Happy Students</p>
            </div>
            <div className="main-review-container">
                {/* <Link to="#"
                    // to="https://www.trustpilot.com/review/doubtq.com"
                    // target="_blank"
                    className="text-decoration-none"
                >
                    <TrustPilot rating="4.8" />
                </Link> */}
                <Link
                    to="https://edureviewer.com/services/doubtq-review/"
                    target="_blank"
                    className="text-decoration-none"
                >
                    <EduReviewer rating="4.2" />
                </Link>
                <Link
                    to="https://www.trustpilot.com/review/doubtq.com"
                    target="_blank"
                    className="text-decoration-none"
                >
                    <StudentReviews rating="4.5" />
                </Link>
                <Link
                    to="https://www.sitejabber.com/reviews/doubtq.com"
                    target="_blank"
                    className="text-decoration-none"
                >
                    <Sitejabber rating="4.8" />
                </Link>
                {/* End TrustBox widget */}
            </div>
        </div>
    )
}

export default SiteReviews
