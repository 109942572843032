import axiosInstance from "../axiosInterceptor";

export async function getSessionDataByStudentCourse(body) {
  try {
    const payload = {
      paymentMethod: body.paymentMethod,
      purchaseId: body._id,
    };
    const response = await axiosInstance.post(
      `student/payment/getSessionDataByStudentCourse`,
      payload
    );
    const data = response?.data;
    return data;
  } catch (err) {
    console.log(err);
  }
}
