import axiosInstance from "../axiosInterceptor";

export const getSubSubjectBySlug = async (slug, subslug) => {
    try {
        const response = await axiosInstance.get(`/admin/subSubject/getSubSubjectBySlug/${slug}/${subslug}`);
        const data = response?.data;
        return data;
    } catch (error) {
        return error;
    }
}