import React, { useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import HelpSteps from "../../../Components/HelpSteps";
import StudentStories from "../../../Components/StudentStories";
import HelpService from "../../../Components/HelpService";
import AdvantagesOfQuestions from "../../../Components/AdvantagesOfQuestions";
import Footer from "../../../Components/Footer";
import BonusSection from "../../../Components/BonusSection";
import ShortAnswerImg from "../../../Assets/images/background/short-answer-image.svg";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";
import Navbar from "../../../Components/Navbar";
import Nav from "../../../Components/Nav";
import GetPopup from "../../Staticpages/GetPopup";
import Milestonerewards from "../../../Components/Milestonerewards";
import Universities from "../../../Components/Universities";

const ShortAnswerService = () => {
  const subCategoryId = "6586d40cdafb1f4a8f4610e6";
  const categoryId = "65524e5e3c005ef3b51907c6";
  const [isModelOpen, setIsModelOpen] = useState(false);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  return (
    <div>
      {/* <Nav />
      <Navbar /> */}
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection />
      <AdvantagesOfQuestions
        title="completion/short answer tests"
        arr={[
          "Easy to write",
          "Guessing is less likely then in other types of tests",
          "Adaptable too many types of subject matter",
          "Quite reliable",
        ]}
        img={ShortAnswerImg}
      />
      <HelpService
        helpServiceTitle
        subCategoryID={subCategoryId}
      />
      <HelpSteps subCategoryId={subCategoryId} />
      <Milestonerewards categoryId={categoryId} />
      <BonusSection
        subCategoryId={subCategoryId}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <Universities categoryId={categoryId} />
      <StudentStories />
      <Footer />
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "HomeworkHelp",
            type: "mcqHelp",
            totalSteps: 3,
          }}
          subCategory={subCategoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
      <GetPopup />
    </div>
  );
};

export default ShortAnswerService;
