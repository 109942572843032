import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import Nav from "../../Components/Nav";
// import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import Button from "../../Components/Buttons/Button";
import pdf from "../../Assets/images/New/que-pdf.png";

import { GoEye } from "react-icons/go";
import { Rating } from "react-simple-star-rating";
import { BsCart } from "react-icons/bs";
import ParticularCollege from "../../Components/University/ParticularCollege";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import ParticularCollegeBanner from "../../Components/University/ParticularCollegeBanner";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import ViewPopup from "../../Components/ViewPopup";
import { getCourseByUniversitySlug } from "../../services/getCourseByUniversity";
import { dateFormat } from "../../Components/dateFormat";
import { Helmet } from "react-helmet";

const ParticularUniversity = () => {
  const { slug } = useParams();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const navigator = useNavigate();
  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleViewClick = (course) => {
    const courseWithUni = {
      ...course,
      university: {
        universityName: data?.universityName,
        universityUrl: data?.universityUrl,
        _id: data?.universityId,
        countryName: data?.countryName,
      },
    };
    setSelectedCourse(courseWithUni);
    toggle();
  };

  const handleNavigate = (course) => {
    const courseWithUni = {
      ...course,
      university: {
        universityName: data?.universityName,
        universityUrl: data?.universityUrl,
        _id: data?.universityId,
        countryName: data?.countryName,
      },
    };

    navigator("/paynow", {
      state: courseWithUni,
    });
  };

  const fetchData = async (pageNum) => {
    try {
      const response = await getCourseByUniversitySlug(pageNum, slug);
      if (response.isSuccess) {
        setData(response.data);
        setTotalPages(response.totalPages);
        setCurrentPage(pageNum);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch courses");
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, slug]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      {/* <Nav />
      <Navbar /> */}
      <Helmet>
        <title>{`Expert Academic Assistance for ${data?.universityName} Students | Personalized Learning with DoubtQ`}</title>
        <meta
          name="description"
          content={`Get expert academic support for ${data?.universityName} students. Access top-quality assignment help, personalized tutoring, and timely solutions to excel in your studies with DoubtQ!`}
        />
        <meta
          property="og:title"
          content={`${data?.universityName} Page - DoubtQ`}
        />
        <meta
          property="og:description"
          content={`Get expert academic support for ${data?.universityName} students. Access top-quality assignment help, personalized tutoring, and timely solutions to excel in your studies with DoubtQ!`}
        />
      </Helmet>
      <div className="row my-4 px-4 m-0 breadcrumb-border">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">
                {data?.universityName || "Loading..."}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ParticularCollegeBanner data={data} />
      <ParticularCollege />
      {data?.courseData?.length > 0 && (
        <>
          <div className="dashboard dash-mobile question-bank-history">
            <div className="mt-3 mb-5">
              <h2 className="latest-exam">
                Latest Exam Materials of {data?.universityName || "University"}
              </h2>
            </div>
            <div className="container">
              {data?.courseData?.length > 0 ? (
                data?.courseData?.map((course) => (
                  <div className="question-bank-papers my-5" key={course._id}>
                    <Row className="py-5">
                      <Col md="3">
                        <div className="d-flex flex-column align-items-center">
                          <img src={pdf} alt="pdf" className="w-50" />
                          <div className="mt-2">
                            <Rating
                              size={20}
                              initialValue={parseFloat(course?.ratings)}
                              readonly
                            />
                          </div>
                          <h5 className="mt-2 que-bank-text">
                            <BsCart className="mx-2" />
                            {course?.purchases || 0} purchases
                          </h5>
                        </div>
                        <h6 className="que-bank-text">
                          {course?.views || 0} views
                        </h6>
                      </Col>
                      <Col md="9">
                        <div className="text-start que-course-right">
                          <p className="que-course-name">
                            <Link
                              to={`/course/${course?.universityUrl}/${course?.courseUrl}`}
                              className="text-decoration-none"
                            >
                              <span className="fs-2 fw-bold me-5">
                                {course?.courseName}
                              </span>
                            </Link>
                            {course?.university && `(${course?.university})`} -{" "}
                            {course?.tempCourse?.courseType}
                          </p>
                          <p className="que-course-sub1">

                            {course?.title}
                          </p>
                          <p className="que-bank-text que-course-sub2">
                            {course?.subTitle}
                          </p>
                          <hr />
                          <p className="que-course-name mb-2">
                            <span className="fs-2 fw-bold me-5">
                              $ {course?.tempCourse?.coursePrice}
                              {/* {course?.type === "Special Study Notes"
                                ? course?.studyNotes?.price
                                : course?.type === "Practice Papers"
                                ? course?.practicePapers?.price
                                : course?.type === "Question Bank"
                                ? course?.questionBank?.price
                                : "N/A"} */}
                            </span>
                            {course?.updatedAt && dateFormat(course?.updatedAt)}
                          </p>
                          <div className="d-flex align-items-center que-course-btn">
                            <h4
                              onClick={() => handleViewClick(course)}
                              className="cursor-pointer mobile-none"
                            >
                              <GoEye /> view
                            </h4>
                            <Button
                              varient="linear-gradient-primary"
                              className="p-2 px-4 mx-3"
                              text="Buy Now"
                              onClick={() => {
                                handleNavigate(course);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <p>No courses found for this university.</p>
              )}

              {totalPages > 1 && (
                <Row className="float-end">
                  <Col className="text-center">
                    <Pagination>
                      <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink
                          previous
                          onClick={() => handlePageClick(currentPage - 1)}
                        />
                      </PaginationItem>

                      {Array.from(
                        { length: totalPages },
                        (_, index) => index + 1
                      ).map((page) => {
                        if (
                          page === 1 ||
                          page === totalPages ||
                          Math.abs(currentPage - page) <= 1
                        ) {
                          return (
                            <PaginationItem
                              key={page}
                              active={page === currentPage}
                            >
                              <PaginationLink
                                onClick={() => handlePageClick(page)}
                              >
                                {page}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        }
                        if (
                          (page === currentPage - 2 ||
                            page === currentPage + 2) &&
                          totalPages > 5
                        ) {
                          return (
                            <PaginationItem key={`ellipsis-${page}`} disabled>
                              <PaginationLink>...</PaginationLink>
                            </PaginationItem>
                          );
                        }
                        return null;
                      })}
                      <PaginationItem disabled={currentPage === totalPages}>
                        <PaginationLink
                          next
                          onClick={() => handlePageClick(currentPage + 1)}
                        />
                      </PaginationItem>
                    </Pagination>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </>
      )}
      <Footer />
      <ScrollTop />

      {isModelOpen && (
        <ViewPopup
          isOpen={isModelOpen}
          toggle={toggle}
          course={selectedCourse}
        />
      )}
    </>
  );
};

export default ParticularUniversity;
