import ExamPreparationBanner from "../../Components/ExamPreparationBanner";
import React from "react";
// import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
// import Nav from "../../Components/Nav";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import SiteReviews from "../../Components/site-reviews/SiteReviews";

import ScrollTop from "../../Components/Buttons/ScrollTop";
import PracticePaperComp from "../../Components/ExamPreparationCards/PracticePaperComp";
import HotCoursesHelp from "../../Components/ExamPreparationCards/HotCoursesHelp";
import FamousUniversity from "../../Components/ExamPreparationCards/FamousUniversity";
// import { CategoryContext } from "../../categoryContext";
import StudyNotes from "../../Components/ExamPreparationCards/StudyNotes";
import QuestionBank from "../../Components/ExamPreparationCards/QuestionBankComp";
import Certificate from "../../Components/ExamPreparationCards/Certificate";
import Formatting from "../../Components/ExamPreparationCards/Formatting";
import { Helmet } from "react-helmet";

const ExamPreparation = () => {
  // const { categoryId } = useContext(CategoryContext);

  return (
    <>
      {/* <Nav /> */}
      {/* <Navbar /> */}
      <Helmet>
        <title>Ace Your Exams with the Best Study Resources</title>
        <meta
          name="description"
          content="Prepare effectively for your exams with class notes, textbook summaries, and expert insights from fellow students. Focus on what matters and grasp key concepts quickly for better results!"
        />
        <meta property="og:title" content="Exam Preparation Page - DoubtQ" />
        <meta
          property="og:description"
          content="Prepare effectively for your exams with class notes, textbook summaries, and expert insights from fellow students. Focus on what matters and grasp key concepts quickly for better results!"
        />
      </Helmet>
      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">Exam Preparation</li>
            </ul>
          </div>
        </div>
      </div>
      <ExamPreparationBanner />
      <SiteReviews />
      <StudyNotes />
      <PracticePaperComp />
      <QuestionBank />
      <HotCoursesHelp />
      <FamousUniversity />
      <Certificate />
      <Formatting />
      <Footer />
      <ScrollTop />
    </>
  );
};

export default ExamPreparation;
