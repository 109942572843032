import React, { useEffect, useState } from "react";
// import Nav from "../../Components/Nav";
// import Navbar from "../../Components/Navbar";
import { Link, useParams } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import AllSubjectsBanner from "../../Components/AllSubjectsBanner";
// import RecentlyAskedQue from "../../Components/RecentlyAskedQue";
import ChatHelpExperts from "../../Components/ChatHelpExperts";
// import ReadMoreSection from "../../Components/ReadMoreSection";
import StudentStories from "../../Components/StudentStories";
import Footer from "../../Components/Footer";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import Hashtags from "../../Components/Hashtags";
import CommonForm from "../HomePage/Form/CommonForm";
import { getSubjectBySlug } from "../../services/getSubjectBySlug";
import { toast } from "react-toastify";
import AllSubjectsReadMoresec from "../../Components/AllSubjectsReadMoresec";
import RecentlyAskedQueSubject from "../../Components/RecentlyAskedQueSubject";
import { Helmet } from "react-helmet";

const SubjectCategory = () => {
  const subCategoryId = "655659b9b9cbef8acade1d7c";
  const [isModelOpen, setIsModelOpen] = useState(false);
  const toggle = () => setIsModelOpen(!isModelOpen);
  const { slug } = useParams();
  const [data, setData] = useState({});

  // const [isExpanded, setIsExpanded] = useState(false);
  // const [textToShow, setTextToShow] = useState("");

  // const truncateText = (text, wordLimit) => {
  //   const words = text.split(" ");
  //   if (words.length > wordLimit) {
  //     return words.slice(0, wordLimit).join(" ") + "...";
  //   }
  //   return text;
  // };

  // const handleReadMoreToggle = () => {
  //   setIsExpanded(!isExpanded);
  //   if (!isExpanded) {
  //     setTextToShow(data?.description);
  //   } else {
  //     setTextToShow(truncateText(data?.description, 100));
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await getSubjectBySlug(slug);
      if (response.isSuccess) {
        setData(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchData();
  }, [slug]);

  return (
    <>
      {/* <Nav />
      <Navbar /> */}
      <Helmet>
        <title>{`Expert ${data?.questionSubject} Assignment & Homework Help | Get Personalized Academic Success with DoubtQ`}</title>

        <meta
          name="description"
          content={`Get professional assistance with your ${data?.questionSubject} assignments and homework from top tutors. Receive expert guidance, timely solutions, and high-quality support to help you excel in your studies!`}
        />

        <meta
          property="og:title"
          content={`${data?.questionSubject} Page - DoubtQ`}
        />

        <meta
          property="og:description"
          content={`Get professional assistance with your ${data?.questionSubject} assignments and homework from top tutors. Receive expert guidance, timely solutions, and high-quality support to help you excel in your studies!`}
        />
      </Helmet>

      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">
                {data?.questionSubject}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <AllSubjectsBanner setIsModelOpen={setIsModelOpen} data={data} />
      <RecentlyAskedQueSubject subjectId={data?._id} />
      <div className="container" style={{ paddingTop: "70px" }}>
        <h2 className="title">Popular Subjects for {data?.questionSubject?.replace(" Homework Help", "")}</h2>
        <p className="description">
          You can get the best rated step-by-step problem explanation from
          65000+ expert tutors by ordering DoubtQ {data?.questionSubject}
        </p>
        <Hashtags />
      </div>
      <StudentStories />
      <ChatHelpExperts />
      {data?.description && <AllSubjectsReadMoresec data={data} />}
      <Footer />
      {isModelOpen && (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "AssignmentHelp",
            type: "assignmentHelp",
            totalSteps: 4,
          }}
          subCategory={subCategoryId}
        />
      )}
      <ScrollTop />
    </>
  );
};

export default SubjectCategory;
