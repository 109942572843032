import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import Button from "../../Components/Buttons/Button";
import "./ExamPrepHistory.scss";
import pdf from "../../Assets/images/New/que-pdf.png";
import { Link, useNavigate } from "react-router-dom";
import { GoEye } from "react-icons/go";
import { Rating } from "react-simple-star-rating";
import { BsCart } from "react-icons/bs";
import { getAllCourseForStudentDb } from "../../services/getAllCourseForStudentDb";
import { toast } from "react-toastify";
import { dateFormat } from "./dateFormat";
import DashboardViewPopup from "./DashboardViewPopup";
import { myCourses } from "../../services/myCourses";
import { Helmet } from "react-helmet";

const ExamPrepHistory = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [purchasedCoursesCurrentPage, setPurchasedCoursesCurrentPage] =
    useState(1);
  const [purchasedCoursesTotalPages, setPurchasedCoursesTotalPages] =
    useState(0);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const navigator = useNavigate();

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const fetchPurchaseCourses = async (pageNum) => {
    try {
      const response = await myCourses(pageNum, 5); // Added perPage parameter
      if (response?.isSuccess) {
        setPurchasedCourses(response.data);
        setPurchasedCoursesTotalPages(response.totalPages);
        setPurchasedCoursesCurrentPage(pageNum);
      } else {
        // toast.error(response?.message || "Failed to fetch purchased courses");
      }
    } catch (error) {
      console.error("Error fetching purchased courses:", error);
      toast.error("Failed to fetch purchased courses");
    }
  };

  const fetchCourses = async (pageNum) => {
    try {
      const response = await getAllCourseForStudentDb(pageNum);
      if (response?.isSuccess) {
        setCourses(response.data);
        setTotalPages(response.totalPages);
        setCurrentPage(pageNum);
      } else {
        // toast.error(response?.message || "Failed to fetch courses");
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
      // toast.error("Failed to fetch courses");
    }
  };

  useEffect(() => {
    fetchCourses(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchPurchaseCourses(purchasedCoursesCurrentPage);
  }, [purchasedCoursesCurrentPage]);

  const handlePurchasedCoursesPageClick = (page) => {
    if (page >= 1 && page <= purchasedCoursesTotalPages) {
      setPurchasedCoursesCurrentPage(page);
    }
  };

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleViewClick = (course) => {
    setSelectedCourse(course);
    toggle();
  };

  return (
    <div>
      <Helmet>
        <title>
          Exam Preparation History | Track & Improve Your Performance with
          DoubtQ
        </title>
        <meta
          name="description"
          content="Review your past exam preparations, track performance, and enhance your study strategies with DoubtQ. Get personalized insights for better results!"
        />
        <meta
          property="og:title"
          content="Exam Preparation History - Track & Improve Your Performance | DoubtQ"
        />
        <meta
          property="og:description"
          content="Analyze your exam preparation history, identify areas for improvement, and boost your academic success with expert insights on DoubtQ!"
        />
      </Helmet>

      <Container className="py-5 w-85 dashboard dash-mobile">
        <Row>
          <Col md="12">
            <h1 className="text-start mb-5">Exam Preparation History</h1>
          </Col>
        </Row>

        {purchasedCourses?.length > 0 ? (
          purchasedCourses?.map((courseData, index) => {
            const paymentStatusClass =
              courseData?.studentPaymentStatus === "Pending"
                ? "exam-processing"
                : courseData?.studentPaymentStatus === "Paid"
                  ? "exam-complited"
                  : courseData?.studentPaymentStatus === "Completed"
                    ? "exam-complited"
                    : "";
            return (
              <Row className="pt-3" key={index}>
                <Col md="12" className="w-100">
                  <Card className="history-card p-0" style={{ zIndex: "111" }}>
                    <div
                      className="card-hed w-100 d-xl-flex justify-content-between rounded-top-4 px-4 py-4"
                      style={{ backgroundColor: "#D5D9F9" }}
                    >
                      <div className="text-start">
                        <h4 style={{ paddingTop: "9px" }}>
                          {courseData?.courseId?.courseName} -{" "}
                          {courseData?.courseId?.university?.universityName}
                        </h4>
                      </div>
                      <div className={paymentStatusClass}>
                        <h4 style={{ color: "#000", marginBottom: "0px" }}>
                          {courseData?.studentPaymentStatus}
                        </h4>
                      </div>
                    </div>
                    <div
                      className="justify-content-between w-100 px-5 py-3"
                      style={{ zIndex: 11111 }}
                    >
                      <Row>
                        <Col md="4">
                          <div className="text-start mb-3 mb-md-0">
                            <h4>Service type</h4>
                            <h5>{courseData?.courseInnerType}</h5>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="text-start mb-3 mb-md-0">
                            <h4>Amount</h4>
                            <h5>${courseData?.estimatedPrice}</h5>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="text-start mb-3 mb-md-0">
                            <h4>Order Date</h4>
                            <h5>{dateFormat(courseData.updatedAt)}</h5>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="text-end justify-content-between gap-1">
                            <Link to={`/dashboard/${courseData?._id}`}>
                              <Button
                                varient="linear-gradient-primary"
                                className="p-2 px-4 mx-2"
                                text={"View"}
                              />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            );
          })
        ) : (
          <p>No courses Purchased</p>
        )}

        {purchasedCoursesTotalPages > 1 && (
          <Row className="pt-3 float-end">
            <Col className="text-center">
              <Pagination className="mt-4">
                <PaginationItem disabled={purchasedCoursesCurrentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() =>
                      handlePurchasedCoursesPageClick(
                        purchasedCoursesCurrentPage - 1
                      )
                    }
                  />
                </PaginationItem>

                {Array.from(
                  { length: purchasedCoursesTotalPages },
                  (_, index) => index + 1
                ).map((page) => {
                  if (
                    page === 1 ||
                    page === purchasedCoursesTotalPages ||
                    Math.abs(purchasedCoursesCurrentPage - page) <= 1
                  ) {
                    return (
                      <PaginationItem
                        key={page}
                        active={page === purchasedCoursesCurrentPage}
                      >
                        <PaginationLink
                          onClick={() => handlePurchasedCoursesPageClick(page)}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  }
                  if (
                    (page === purchasedCoursesCurrentPage - 2 ||
                      page === purchasedCoursesCurrentPage + 2) &&
                    purchasedCoursesTotalPages > 5
                  ) {
                    return (
                      <PaginationItem key={`ellipsis-${page}`} disabled>
                        <PaginationLink>...</PaginationLink>
                      </PaginationItem>
                    );
                  }
                  return null;
                })}

                <PaginationItem
                  disabled={
                    purchasedCoursesCurrentPage === purchasedCoursesTotalPages
                  }
                >
                  <PaginationLink
                    next
                    onClick={() =>
                      handlePurchasedCoursesPageClick(
                        purchasedCoursesCurrentPage + 1
                      )
                    }
                  />
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
      <Container className="w-85 dashboard dash-mobile question-bank-history">
        <div className="mt-3 mb-5">
          <h2>You can try Our Practice Papers and question bank</h2>
        </div>
        {courses?.length > 0 ? (
          courses?.map((courseData, index) => (
            <div className="question-bank-papers my-5" key={index}>
              <Row className="py-5">
                <Col md="3">
                  <div className="d-flex flex-column align-items-center">
                    <img src={pdf} alt="pdf" className="w-50" />
                    <div className="mt-2">
                      <Rating
                        size={20}
                        initialValue={courseData?.ratings}
                        readonly
                      />
                    </div>
                    <h5 className="mt-2 que-bank-text">
                      <BsCart className="mx-2" />
                      {courseData?.purchases} purchases
                    </h5>
                  </div>
                  <h6 className="que-bank-text">{courseData?.views} views</h6>
                </Col>
                <Col md="9">
                  <div className="text-start que-course-right">
                    <p className="que-course-name">
                      {" "}
                      <Link
                        to={`/dashboard/home/course/${courseData?.university?.universityUrl}/${courseData?.courseUrl}`}
                        className="text-decoration-none"
                      >
                        <span className="fs-2 fw-bold me-5">
                          {courseData?.courseName}
                        </span>
                      </Link>
                      {" "}
                      ({courseData?.university?.universityName}) -{" "}
                      {courseData?.tempCourse?.courseType}
                    </p>
                    <p className="que-course-sub1">

                      {courseData?.title}
                    </p>
                    <p className="que-bank-text que-course-sub2">
                      {courseData?.subTitle}
                    </p>
                    <hr />
                    <p className="que-course-name mb-2">
                      {" "}
                      <span className="fs-2 fw-bold me-5">
                        $ {courseData?.tempCourse?.coursePrice}
                        {/* {courseData?.type === "Special Study Notes"
                          ? courseData?.studyNotes?.price
                          : courseData?.type === "Practice Papers"
                          ? courseData?.practicePapers?.price
                          : courseData?.type === "Question Bank"
                          ? courseData?.questionBank?.price
                          : "N/A"} */}
                      </span>{" "}
                      (Uploaded{" "}
                      {courseData?.updatedAt &&
                        dateFormat(courseData?.updatedAt)}
                      )
                    </p>
                    <div className="d-flex align-items-center que-course-btn">
                      <h4
                        onClick={() => handleViewClick(courseData)}
                        className="cursor-pointer mobile-none"
                      >
                        <GoEye /> view
                      </h4>

                      <Button
                        varient="linear-gradient-primary"
                        className="p-2 px-4 mx-3"
                        text={"Buy Now"}
                        onClick={() => {
                          navigator("/dashboard/paynow", { state: courseData });
                        }}
                      ></Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ))
        ) : (
          <p>No courses available</p>
        )}
        {totalPages > 1 && (
          <Row className="float-end">
            <Col className="text-center">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageClick(currentPage - 1)}
                  />
                </PaginationItem>

                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1
                ).map((page) => {
                  if (
                    page === 1 ||
                    page === totalPages ||
                    Math.abs(currentPage - page) <= 1
                  ) {
                    return (
                      <PaginationItem key={page} active={page === currentPage}>
                        <PaginationLink onClick={() => handlePageClick(page)}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  }
                  if (
                    (page === currentPage - 2 || page === currentPage + 2) &&
                    totalPages > 5
                  ) {
                    return (
                      <PaginationItem key={`ellipsis-${page}`} disabled>
                        <PaginationLink>...</PaginationLink>
                      </PaginationItem>
                    );
                  }
                  return null;
                })}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageClick(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
      <style>
        {`
          @media (max-width: 600px) {
            .que-course-btn h4 {
              display: none;
            }
          }
        `}
      </style>
      {isModelOpen && (
        <DashboardViewPopup
          isOpen={isModelOpen}
          toggle={toggle}
          courseData={selectedCourse}
        />
      )}
    </div>
  );
};

export default ExamPrepHistory;
