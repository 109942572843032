import React, { useEffect, useState } from "react";
import Button from "./Buttons/Button";
import "./BonusSection.scss";
import { getBonusByID } from "../services/getBonusByID";
import { ImageLink } from "../constants";

const BonusSection = ({
  subCategoryId = "",
  setIsModelOpen = () => {},
  btnDisabled = false,
}) => {
  const [pageData, setPageData] = useState([]);

  const fetchData = async () => {
    await getBonusByID({
      subCategoryId,
    })
      .then((data) => {
        setPageData(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {pageData?.length > 0 && (
        <div className="bonus-section-container">
          <div className="bonus-container container">
            <h2 className="title">Bonuses</h2>
            <p className="description">What you will get?</p>

            <div className="bonus-card-container">
              <div className="bonus-section-bg-overlay"></div>
              {pageData?.map((element, index) => {
                return <BonusCard {...element} key={index} />;
              })}
            </div>
            {!btnDisabled ? (
              <Button
                text={"Get it now!"}
                varient="primary"
                onClick={() => setIsModelOpen(true)}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const BonusCard = ({ image = "", description = "", index = 0, title = "" }) => {
  return (
    <div className="bonus-card" key={index}>
      <div className="bonus-card-icon">
        <img src={ImageLink(image)} alt="" />
      </div>
      <div className="bonus-card-title">{title}</div>
      <div className="bonus-card-description">{description}</div>
    </div>
  );
};

export default BonusSection;
