import React, { useEffect, useState } from "react";
import "./BenefitsSection.scss";
import Button from "./Buttons/Button";
import { getBenefitBySubcategoryId } from "../services/getBenefitBySubcategoryId";
import { ImageLink } from "../constants";

const BenefitsSection = ({
  subCategoryId,
  id = "",
  setIsModelOpen = () => {},
  btnDisabled = false,
}) => {
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getBenefitBySubcategoryId({
      subCategoryId,
    })
      .then((data) => {
        setPageData(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="benefits-section-wrapper">
      <div className="container">
        <div className="benefits-section-container">
          <h3 className="title">
            {pageData?.mainTitle ?? "Multiple Benefits of DoubtQ Help"}
          </h3>
          <div className="description">
            create a healthy platform that helps students to launch into the sky
            of success.
          </div>
        </div>
        <div className="benefits-card-wrapper">
          {pageData?.benefitData?.map((e, index) => {
            return <BenfitCard key={index} {...e} />;
          })}
        </div>
        {!btnDisabled ? (
          <Button
            text={"Place Your Help"}
            varient="primary"
            onClick={() => setIsModelOpen(true)}
          ></Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const BenfitCard = ({ title = "", description = "", image = "" }) => {
  return (
    <div className="benefits-card-container">
      <div className="benefits-card-icon">
        <img src={ImageLink(image)} alt="" />
      </div>
      <h3 className="benefits-card-title">{title}</h3>
      <div className="benefits-card-description">{description}</div>
    </div>
  );
};

export default BenefitsSection;
