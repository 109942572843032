import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import "./PaymentDetails.scss";
import Button from "../Buttons/Button";
import { useNavigate } from "react-router-dom";
import { validatePromocode } from "../../services/validatePromocode";
import axiosInstance from "../../axiosInterceptor";
import { getPaymentSessionByQuestion } from "../../services/getPaymentSessionByQuestion";
import stripe from "../../Assets/logo/stripe.png";
import razorpay from "../../Assets/logo/razorpay.png";

const PaymentDetails = ({
  questionData,
  fetchData,
  disable = false,
  isAddressAvailable = false,
}) => {
  const [open, setOpen] = useState("1");
  const [questionDetails, setQuestionDetails] = useState({});
  const [hasPromoCode, sethasPromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState(questionData.promoCode || "");
  const navigate = useNavigate();

  useEffect(() => {
    setQuestionDetails(questionData);
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [questionData, questionDetails]);

  const [setting, setSetting] = useState([]);
  const flatdiscontent = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    flatdiscontent();
  }, []);

  useEffect(() => {
    if (questionDetails.studentPaymentStatus === "Paid") {
      navigate("/dashboard/order-history");
    }
  }, [questionDetails.studentPaymentStatus, navigate, questionDetails]);

  const fetchPaymentSessionData = async (paymentMethod) => {
    if (isAddressAvailable) {
      const response = await getPaymentSessionByQuestion({
        paymentMethod,
        questionId: questionDetails._id,
      });

      if (response?.isSuccess) {
        if (response.paymentMethod === "Razorpay") {
          navigate("/razorpay-checkout", {
            state: {
              clientSecret: response.clientSecret,
            },
          });
        } else {
          navigate("/checkout", {
            state: {
              clientSecret: response.clientSecret,
            },
          });
        }
      }
    } else {
      navigate(`/billingDetails/${paymentMethod}/${questionDetails._id}`);
    }
  };

  const [validPromoCode, setValidPromoCode] = useState([]);
  const checkPromoCode = async () => {
    const response = await validatePromocode({
      promoCode: promoCode,
      questionId: questionData._id,
      paymentSection: questionData.paymentSection,
    });
    setValidPromoCode(response);
    if (response.isSuccess) {
      setQuestionDetails({
        ...questionDetails,
        promoPrice: response.data?.promoCodeValue,
      });
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 1000);

      return () => clearInterval(intervalId);
    }
  };

  useEffect(() => {
    if (questionData?.promoCode) {
      sethasPromoCode(true);
      setPromoCode(questionData?.promoCode);
    }
  }, [questionData]);

  const handlePayment = (paymentMethod) => {
    fetchPaymentSessionData(paymentMethod);
  };

  const specialSubCategoryIds = [
    "6586dfe7d2f7eeadeea4fafa",
    "6586d9a9dafb1f4a8f461274",
  ];

  return (
    <>
      <Accordion
        open={open}
        toggle={(id) => setOpen(id === open ? null : id)}
        className="accordian-Wrapper content-card"
      >
        <AccordionItem
          className={
            disable && questionDetails?.estimatedPrice === 0 ? "disabled" : ""
          }
        >
          <AccordionHeader targetId="1" className="heading">
            {specialSubCategoryIds.includes(questionDetails?.subCategoryId?._id)
              ? "Payment (We are calculating the best price for you!)"
              : "Payment"}
          </AccordionHeader>

          {!disable || questionDetails?.estimatedPrice !== 0 ? (
            <>
              <AccordionBody accordionId="1">
                <div className="d-flex align-items-center total-amount">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Total Amount
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 w-25 text-end price">
                    ${questionDetails?.estimatedPrice?.toFixed(2)}
                  </span>
                </div>
                <div className="d-flex align-items-center total-amount">
                  {questionDetails?.discountPrice > 0 ? (
                    <>
                      <span className="pe-3 greyTitle w-25 text-start">
                        Discounted Amount
                      </span>
                      <span className="lineSeprator"></span>
                      <span className="ps-3 w-25 text-end price">
                        ${questionDetails?.discountPrice?.toFixed(2)}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="d-flex pt-5 flex-wrap">
                  <div className="d-flex justify-content-between w-100 promoCodeWrapper">
                    <div className="d-xl-flex align-items-center greyTitle havePromocode w-80">
                      <div className="d-sm-flex text-start">
                        <input
                          type="checkbox"
                          checked={hasPromoCode}
                          onChange={() => sethasPromoCode(!hasPromoCode)}
                          disabled={questionDetails?.estimatedPrice === 0}
                          className="w-0"
                        />
                        <span className="ps-4">Have Promo Code?</span>
                      </div>
                      <span className="promoCodeMessage mt-2">
                        {setting.promoCodeLabel}
                      </span>
                    </div>
                    {hasPromoCode ? (
                      <div className="w-20 price">
                        - ${questionDetails?.promoPrice?.toFixed(2)}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {hasPromoCode ? (
                    <div className="mt-5 d-flex align-items-center promocodeField">
                      <span className="greyTitle">Promo Code :</span>
                      <span className="ms-5">
                        <InputGroup className="rounded-end-5 overflow-hidden">
                          <Input
                            placeholder="Enter Your Code here"
                            className="fs-3 p-4 rounded-start-4"
                            name="promoCode"
                            value={promoCode}
                            onChange={(event) =>
                              setPromoCode(event.target.value)
                            }
                          />
                          <InputGroupText className="authentication-form-input-group p-0 overflow-hidden">
                            <Button
                              varient="linear-gradient-primary"
                              text={"Apply"}
                              className="rounded-0 h-100 px-5 py-4"
                              disabled={
                                !promoCode || questionDetails?.promoPrice > 0
                                  ? true
                                  : false
                              }
                              onClick={checkPromoCode}
                            />
                          </InputGroupText>
                        </InputGroup>
                        {validPromoCode?.isSuccess === false ? (
                          <div className="text-danger text-start">
                            {validPromoCode?.message}
                          </div>
                        ) : (
                          <div className="text-success text-start">
                            {validPromoCode?.message}
                          </div>
                        )}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {!hasPromoCode && !questionDetails.isSuccess && (
                    <span className="error-message">
                      {questionDetails.message}
                    </span>
                  )}
                </div>
                <hr className="mt-5" />
                <div className="d-flex align-items-center payable-amount">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Payable Amount
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 w-25 text-end price">
                    ${questionData.totalPrice?.toFixed(2)}
                  </span>
                </div>
                <hr />
                <div className="d-md-flex justify-content-between text-start">
                  <div className="w-100 w-md-80 d-flex flex-column">
                    <div className="finalAmount">
                      ${questionData.totalPrice?.toFixed(2)}
                    </div>
                    <div className="greyTitle">Payable Amount</div>
                  </div>
                  <div className="w-100 w-md-20 mt-3 d-lg-flex mt-md-0 justify-content-md-end">
                    {setting.paymentMethod?.length === 2 && (
                      <div className="mt-2">Choose Payment Options</div>
                    )}
                    {setting.paymentMethod?.includes("Stripe") && (
                      <Button
                        className="payment-paypage mx-lg-2 p-0"
                        text={
                          setting.paymentMethod?.length === 1 &&
                          setting.paymentMethod?.includes("Stripe") ? (
                            "Continue to Pay"
                          ) : (
                            <img
                              src={stripe}
                              alt="Stripe Logo"
                              className="stripe-logo"
                            />
                          )
                        }
                        varient={
                          setting.paymentMethod?.length === 1 &&
                          setting.paymentMethod?.includes("Stripe")
                            ? "linear-gradient-primary"
                            : undefined
                        }
                        onClick={() => handlePayment("Stripe")}
                        disabled={questionDetails?.estimatedPrice === 0}
                      />
                    )}

                    {setting.paymentMethod?.includes("Razorpay") && (
                      <Button
                        className="payment-paypage mx-2 p-0"
                        text={
                          setting.paymentMethod?.length === 1 &&
                          setting.paymentMethod?.includes("Razorpay") ? (
                            "Continue to Pay"
                          ) : (
                            <img
                              src={razorpay}
                              alt="Razorpay Logo"
                              className="razorpay-logo"
                            />
                          )
                        }
                        varient={
                          setting.paymentMethod?.length === 1 &&
                          setting.paymentMethod?.includes("Razorpay")
                            ? "linear-gradient-primary"
                            : undefined
                        }
                        onClick={() => handlePayment("Razorpay")}
                        disabled={questionDetails?.estimatedPrice === 0}
                      />
                    )}
                  </div>
                </div>
              </AccordionBody>
            </>
          ) : (
            <></>
          )}
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default PaymentDetails;
