import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getAllCourseForStudent } from "../../services/getAllCourseForStudent";

const DashBoardExamPrepSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 2) {
        fetchSearchResults();
      } else {
        setOptions([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchSearchResults = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getAllCourseForStudent(searchTerm);
      if (response?.isSuccess && response.data.length > 0) {
        const formattedOptions = response.data.map((item) => ({
          value: item._id,
          label: item.courseName,
          fullItem: item,
        }));
        setOptions(formattedOptions);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      // Redirect to course details or home page
      navigate(
        `/course/${selectedOption?.fullItem?.university?.universityUrl}/${selectedOption.fullItem?.courseUrl}`,
        {
          state: { courseDetails: selectedOption.fullItem },
        }
      );
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      padding: "4px",
      display: "flex",
      alignItems: "center",
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "16px",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      color: state.isSelected ? "white" : "black",
      ":hover": {
        backgroundColor: state.isSelected ? "#007bff" : "#f1f1f1",
      },
      fontSize: "16px",
      textAlign: "left",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
      marginLeft: "30px",
    }),
  };

  return (
    <Select
      isSearchable
      isClearable
      isLoading={isLoading}
      placeholder="Search for courses..."
      onInputChange={(newValue) => setSearchTerm(newValue)}
      options={options}
      onChange={handleSelectChange}
      styles={customStyles}
      noOptionsMessage={() =>
        searchTerm.length > 2 ? "No courses found" : "Start typing to search"
      }
    />
  );
};

export default DashBoardExamPrepSearch;
