import React, { useEffect, useState } from "react";
import "./ReadMoreSection.scss";
import { getSubcategoryWiseContent } from "../services/getSubcategoryWiseContent";

const ExamReadMoreSection = ({ subCategoryId = "" }) => {
  const [pageData, setPageData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [textToShow, setTextToShow] = useState("");

  const fetchData = async () => {
    try {
      const data = await getSubcategoryWiseContent(subCategoryId);
      setPageData(data);
      if (data?.subCategoryData?.LongDescription) {
        setTextToShow(truncateText(data.subCategoryData.LongDescription, 100));
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [subCategoryId]);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };
  const handleReadMoreToggle = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setTextToShow(pageData?.subCategoryData?.LongDescription || "");
    } else {
      setTextToShow(
        truncateText(pageData?.subCategoryData?.LongDescription || "", 100)
      );
    }
  };

  return (
    <>
      {pageData?.subCategoryData?.LongDescription?.length > 0 ? (
        <div className="read-more-section-container">
          <div className="read-more-section-description">
            {textToShow ? (
              <>
                <p dangerouslySetInnerHTML={{ __html: textToShow }} />
                {pageData?.subCategoryData?.LongDescription?.split(" ").length >
                  100 && (
                  <button
                    onClick={handleReadMoreToggle}
                    className="rbt-btn btn-gradient btn-sm mt-4"
                  >
                    {isExpanded ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ExamReadMoreSection;
