import React from "react";
import bannerimage from "../../Assets/images/background/icon-bg.png";
import "./CourseBanner.scss";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { BsCart } from "react-icons/bs";

const CourseBanner = ({ data }) => {

  return (
    <>
      <div className="banner-main-wrapper-course">
        <img
          className="banner-image"
          src={bannerimage}
          alt="ParticularCollege-banner"
        />
        <div className="banner-content-pc">
          {data?.courseName?.length > 0 && (
            <>
              <div className="banner-content-title-wrapper">
                <div className="title title-course-banner">
                  <div>
                    <p>
                      <strong className="ql-size-huge ql-font-monospace">
                        {data?.courseName}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="banner-content-subtitle course-subtitle">
                <Link
                  className="text-decoration-none"
                  to={`/university/${data?.university?.universityUrl}`}
                >
                  <span>
                    <div className="university-url">
                      {" "}
                      &lt; {data?.university?.universityName}
                    </div>
                  </span>
                </Link>
              </div>
              <div className="container pt-4">
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <div>
                    <Rating size={20} initialValue={data?.ratings} readonly />
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-1 course-section-left-text">
                    <p className="course-banner-review">
                      {" "}
                      {data?.totalReviews} Reviews
                    </p>
                    <p> {data?.views} Views </p>
                    <div>
                      <BsCart className="mx-2" />
                      {data?.purchases} purchases
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseBanner;
