import React, { useEffect, useState, useCallback } from "react";
import "./ExamPreparationCards.scss";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import File from "../../Assets/images/fileImg.png";
import { getCourseBySpecialStudyNotes } from "../../services/courseServices";

const StudyNotesPage = () => {
  const [storyData, setStoryData] = useState({
    data: [],
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
  });
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async (searchTerm = '', page = 1) => {
    setIsLoading(true);
    try {
      const response = await getCourseBySpecialStudyNotes({
        search: searchTerm,
        page,
        limit: 10
      });

      if (response?.isSuccess) {
        setStoryData({
          data: response.data || [],
          currentPage: response.pagination?.page || page,
          totalPages: response.pagination?.totalPages || 0,
          totalItems: response.pagination?.total || 0,
        });
      } else {
        setStoryData({ data: [], currentPage: 1, totalPages: 0, totalItems: 0 });
        console.error("API Error:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setStoryData({ data: [], currentPage: 1, totalPages: 0, totalItems: 0 });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let timeoutId = null;

    if (search.length >= 3) {
      timeoutId = setTimeout(() => {
        fetchData(search);
      }, 300);
    } else if (search.length === 0) {
      fetchData();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [search, fetchData]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePaginationClick = (pageNo) => {
    fetchData(search, pageNo);
  };

  const renderPagination = () => {
    const { currentPage, totalPages } = storyData;

    return (
      <Pagination className="mt-4 justify-content-center">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handlePaginationClick(currentPage - 1)}
          />
        </PaginationItem>

        {[...Array(totalPages)].map((_, index) => {
          const pageNumber = index + 1;
          if (
            pageNumber === 1 ||
            pageNumber === totalPages ||
            (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
          ) {
            return (
              <PaginationItem
                key={pageNumber}
                active={currentPage === pageNumber}
              >
                <PaginationLink
                  onClick={() => handlePaginationClick(pageNumber)}
                >
                  {pageNumber}
                </PaginationLink>
              </PaginationItem>
            );
          }
          if (
            pageNumber === currentPage - 2 ||
            pageNumber === currentPage + 2
          ) {
            return (
              <PaginationItem disabled key={pageNumber}>
                <PaginationLink>...</PaginationLink>
              </PaginationItem>
            );
          }
          return null;
        })}

        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            next
            onClick={() => handlePaginationClick(currentPage + 1)}
          />
        </PaginationItem>
      </Pagination>
    );
  };

  return (
    <div className="student-stories-container prac-paper-container">
      <div className="title-search">
        <div className="mb-3">
          <h1 className="text-start">Special Study Notes</h1>
          <p className="text-start">Recently Prepared DoubtQ</p>
        </div>
        <div className="prac-paper-search mb-3">
          <i className="feather-search fs-icon fs-2"></i>
          <input
            type="text"
            placeholder="Find Course, University"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      {isLoading ? (
        <p className="text-center mt-4">Loading...</p>
      ) : storyData.data.length > 0 ? (
        <>
          <div className="grid-container-course-st">
            {storyData.data.map((e, index) => (
              <div key={index} className="grid-course-item story-card py-3 px-3">
                <Link
                  className="text-decoration-none"
                  to={`/course/${e?.university?.universityUrl}/${e.courseUrl}`}
                  state={{ id: "2" }}

                >
                  <div className="course-img-container text-center mb-4">
                    <img className="course-img" src={File} alt="file" />
                  </div>
                  <div className="subtitle-lh-cos mt-2">
                    {e?.courseName?.length > 15
                      ? `${e.courseName.substring(0, 15)}...`
                      : e?.courseName}
                  </div>
                  <div className="dec-lh-cos">
                    {e?.university?.universityName?.length > 30
                      ? `${e.university?.universityName.substring(0, 30)}...`
                      : e?.university?.universityName}
                  </div>
                  <div className="rating-stars-cos">
                    <Rating
                      size={20}
                      initialValue={parseFloat(e.courseRatings)}
                      readonly
                    />
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="float-end me-5">
            {renderPagination()}
          </div>
        </>
      ) : (
        <p className="text-center mt-4">
          {search.length > 0 && search.length < 3
            ? "Please enter at least 3 characters to search"
            : "No results found"}
        </p>
      )}
    </div>
  );
};

export default StudyNotesPage;