import React, { useEffect, useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import BenefitsSection from "../../../Components/BenefitsSection";
import HelpSteps from "../../../Components/HelpSteps";
import BonusSection from "../../../Components/BonusSection";
import StudentStories from "../../../Components/StudentStories";
import ChatHelpExperts from "../../../Components/ChatHelpExperts";
import Footer from "../../../Components/Footer";
import Navbar from "../../../Components/Navbar";
import Nav from "../../../Components/Nav";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import AssignmentList from "../../../Components/AssignmentList";
import Universities from "../../../Components/Universities";
import Milestonerewards from "../../../Components/Milestonerewards";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";
// import { getAllSubject } from "../../../services/getAllSubject";
import { getMaxMinWords } from "../../../services/getMaxMinWords";
import { getSubcategoryWisePrice } from "../../../services/getSubcategoryWisePrice";
import { getHoursByWords } from "../../../services/getHoursByWords";
import { getPriceByWords } from "../../../services/getPriceByWords";
import Page from "../../Metadata/Page";
import ComWorldWide from "../../../Components/ComWorldWide";
import ComReadMoreSection from "../../../Components/ComReadMoreSection";
import { getAllsubSubject } from "../../../services/getAllSubSubject";

const ArticleWriting = () => {
  const subCategoryId = "655659b9b9cbef8acade1d7c";
  const categoryId = "65524e633c005ef3b51907c9";
  const [payload, setPayload] = useState({
    fontSize: "No",
    fontStyle: "No",
    lineSpacing: "No",
    referenceStyle: "No",
    haveReferenceFile: "No",
    subCategory: subCategoryId,
    categoryId: categoryId,
  });
  const [isModelOpen, setIsModelOpen] = useState(false);

  const toggle = () => setIsModelOpen(!isModelOpen);

  const fetchSubjectData = async () => {
    // const response = await getAllSubject();
    const response = await getAllsubSubject();
    setPayload((prevstate) => ({ ...prevstate, subject: response.data }));
  };

  const fetchWords = async () => {
    const response = await getMaxMinWords(subCategoryId);
    setPayload((prevstate) => ({
      ...prevstate,
      noOfWords: response.data.minWords,
      max: response.data.maxWords,
      min: response.data.minWords,
    }));
  };

  useEffect(() => {
    fetchSubjectData();
    fetchWords();
  }, []);

  const fetchQuestionPricingData = async (id) => {
    const response = await getSubcategoryWisePrice(id);
    setPayload((prevstate) => ({
      ...prevstate,
      price: response?.price?.studentPrice,
    }));
  };

  const getAssignmentPrice = async () => {
    const response = await getHoursByWords({
      subCategoryId: subCategoryId,
      words: payload.noOfWords ? Number(payload.noOfWords) : payload.min,
    });

    if (response.isSuccess) {
      const hoursData = response.hours?.reverse();
      let hours = payload.deadline;
      if (hoursData?.includes(parseInt(hours))) {
        hours = payload.deadline;
      } else {
        hours = hoursData[Math.ceil(hoursData.length / 2) ?? 0];
      }
      setPayload((prevstate) => ({ ...prevstate, deadline: hours }));

      const data = await getPriceByWords({
        subCategoryId: subCategoryId,
        words: payload.noOfWords ? Number(payload.noOfWords) : payload.min,
        hours: hours,
      });

      if (data.isSuccess) {
        setPayload((prevstate) => ({
          ...prevstate,
          estimatedPrice: data?.price?.estimatedPrice,
        }));
      }
    }
  };

  useEffect(() => {
    if (payload.noOfWords) {
      const timeoutId = setTimeout(() => {
        getAssignmentPrice();
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [payload.deadline, payload.noOfWords, getAssignmentPrice]);

  useEffect(() => {
    if (payload.questionType) {
      fetchQuestionPricingData(payload.questionType);
    }
  }, [payload.questionType]);

  return (
    <>
      <Page subCategoryId={subCategoryId} categoryId={categoryId} />
      {/* <Nav />
      <Navbar /> */}
      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item">Assignment Help</li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">
                Research/Dissertation Writing Help
              </li>
            </ul>
          </div>
        </div>
      </div>
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection
        onSignUp={() => {
          setIsModelOpen(true);
        }}
      />
      <BenefitsSection
        subCategoryId={subCategoryId}
        setIsModelOpen={setIsModelOpen}
      />
      <AssignmentList
        subCategoryId={subCategoryId}
        setIsModelOpen={setIsModelOpen}
      />
      <HelpSteps subCategoryId={subCategoryId} />
      <Milestonerewards categoryId={categoryId} />
      <BonusSection
        subCategoryId={subCategoryId}
        setIsModelOpen={setIsModelOpen}
      />
      <Universities categoryId={categoryId} setIsModelOpen={setIsModelOpen} />
      <StudentStories />
      <ChatHelpExperts />
      <ComWorldWide id={subCategoryId} />
      <ComReadMoreSection id={subCategoryId} />
      <Footer />
      {isModelOpen && (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "AssignmentHelp",
            type: "assignmentHelp",
            totalSteps: 4,
          }}
          subCategory={subCategoryId}
        />
      )}
      <ScrollTop />
    </>
  );
};

export default ArticleWriting;
