import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axiosInstance from "../../axiosInterceptor";

const Page = ({ subCategoryId, categoryId }) => {
  const [navbarMenu, setNavbarMenu] = useState([]);

  const categoryFilter = navbarMenu?.filter(
    (data) => data.categoryId === categoryId
  );
  const subcatFilter = categoryFilter?.map((item) => item.subcategories);
  const filterId = subcatFilter?.[0]?.filter(
    (cat) => cat._id === subCategoryId
  );

  const fetchData = async () => {
    try {
      // alert("hiii")
      const res = await axiosInstance.get(
        "/admin/category/getCategoryWiseSubcategory"
      );
      setNavbarMenu(res.data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {filterId?.[0] && (
        <Helmet>
          <title>
            {filterId?.[0]?.metaTitle
              ? filterId[0].metaTitle
              : `${filterId?.[0]?.name} | Get Personalized Academic Success with DoubtQ | Online Question Help`}
          </title>

          <meta
            name="description"
            content={`Welcome to the ${
              filterId?.[0]?.metaDescription || filterId?.[0]?.name
            } of DoubtQ`}
          />
          <meta
            property="og:title"
            content={`Explore ${filterId?.[0]?.name} - DoubtQ`}
          />
          <meta
            property="og:description"
            content={`Explore personalized academic help for ${
              filterId?.[0]?.metaDescription || filterId?.[0]?.name
            } on DoubtQ`}
          />
        </Helmet>
      )}
    </>
  );
};

export default Page;
