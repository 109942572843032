import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  // Nav,
  Row,
} from "reactstrap";
// import Navbar from "../../Components/Navbar";
import "./PayNow.scss";
import pdf from "../../Assets/images/New/que-pdf.png";
import { Rating } from "react-simple-star-rating";
import { BsCart } from "react-icons/bs";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import Button from "../../Components/Buttons/Button";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { toast } from "react-toastify";
import { getAllCountries } from "../../services/getAllCountries";
import { dateFormat } from "../../Components/dateFormat";
import { getCourseByTypeId } from "../../services/getCourseByTypeId";
import { purchaseCourse } from "../../services/purchaseCourse";
import { getSessionDataByStudentCourse } from "../../services/getSessionDataByStudentCourse";
import { Helmet } from "react-helmet";

const PayNow = () => {
  const location = useLocation();
  const courseData = location.state;
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const { referral } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    country: "",
    referralCode: referral ? referral : "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [countries, setCountries] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [selelctedData, setSelelctedData] = useState([]);

  useEffect(() => {
    const fetchselelctedData = async () => {
      try {
        const response = await getCourseByTypeId(
          courseData?._id,
          courseData?.tempCourse?.courseTypeId
        );
        setSelelctedData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchselelctedData();
  }, []);

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  const toggle = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  const handleRemoveClick = () => {
    navigate("/");
  };

  useEffect(() => {
    (async function () {
      const res = await getAllCountries();
      setCountries(res.data);
    })();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    try {
      const response = await purchaseCourse({
        email: formData.email,
        password: formData.password,
        countryId: formData.country,
        referralCode: formData.referralCode,
        courseId: selelctedData.courseId,
        courseInnerType: selelctedData.TypeName,
        courseInnerId: selelctedData.courseTypeId,
        price: selelctedData.courseTypePrice,
      });

      if (response.isSuccess) {
        localStorage.setItem("token", response.authToken);
        localStorage.setItem("email", formData.email);

        // toast.success(response.message || "Registration successful");

        if (response?.billingDetails) {
          const responseData = await getSessionDataByStudentCourse(
            response?.data
          );
          navigate(`/course-checkout/${response.data._id}`, {
            state: {
              clientSecret: responseData.clientSecret,
            },
          });
        } else {
          navigate(
            `/billingDetailscourse/${response?.data?.paymentMethod}/${response?.data?._id}`,
            { state: response?.data?._id }
          );
        }
      } else {
        setErrorMessage(response.message || "Registration failed");
      }
    } catch (error) {
      console.error("Registration error:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormValid = formData.email && formData.password && formData.country;

  return (
    <>
      {/* <Nav />
      <Navbar /> */}
      <Helmet>
        <title>
          Shopping Cart | Login for a Personalized Experience or Pay as Guest -
          DoubtQ
        </title>
        <meta
          name="description"
          content="Manage your shopping cart, log in for personalized checkout, or choose 'Pay as Guest' for a quick and easy purchase process with DoubtQ."
        />
        <meta
          property="og:title"
          content="Shopping Cart - Login or Pay as Guest | DoubtQ"
        />
        <meta
          property="og:description"
          content="Explore flexible checkout options: log in for a personalized experience or pay as guest for quick and easy purchases on DoubtQ."
        />
      </Helmet>
      <div className="main-container">
        <div className="inside-container">
          <div className="row p-3">
            <div className="col-lg-12 pn-pg-main">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="pn-progress-step">
                  <div className="progress-circle">1</div>
                  <p> Payment Method</p>
                </div>
                <div className="pn-progress-step pn-inactive">
                  <div className="progress-circle">2</div>
                  <p> Checkout</p>
                </div>
                <div className="pn-progress-step pn-inactive">
                  <div className="progress-circle">3</div>
                  <p> Download</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pn-left-part">
              <div className="mt-5 mb-5">
                <h2>Shopping Cart</h2>
              </div>
              <div className="row">
                <div className="col-md-3 col-12">
                  <div className="cart-item">
                    <img src={pdf} alt="cart-item" />
                    <div className="mt-2">
                      <Rating
                        size={22}
                        initialValue={parseFloat(courseData?.ratings)}
                        readonly
                      />
                    </div>
                    <div className="pn-purchase mt-1">
                      <BsCart className="mx-2" /> {courseData?.purchases}{" "}
                      purchases
                    </div>
                    <div className="pn-views mt-1">
                      {courseData?.views} Views
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-12 pt-4 pb-5 ps-5 pe-5">
                  <div className="cart-item-details">
                    <p className="pn-que-course-name text-start mb-2">
                      <span className="fs-3 me-3">
                        {courseData?.courseName}
                      </span>{" "}
                      ({courseData?.university?.universityName}) -{" "}
                      {courseData?.tempCourse?.courseType}
                    </p>
                    <p className="pn-que-course-sub1 text-start mb-2">
                      <Link
                        to={`/course/${courseData?.university?.universityUrl}/${courseData?.courseUrl}`}
                        className="text-decoration-none"
                      >
                        {courseData?.title}
                      </Link>
                    </p>
                    <p className="pn-que-bank-text pn-que-course-sub2 text-start">
                      {courseData?.subTitle}
                    </p>
                    <hr className="mt-4 mb-4" />
                    <div className="d-flex justify-content-between align-items-center pe-3">
                      <p className="pn-que-course-name text-start mb-2">
                        <span className="fs-3 me-3">
                          ${courseData?.tempCourse?.coursePrice}
                        </span>
                        (
                        {courseData?.updatedAt &&
                          dateFormat(courseData?.updatedAt)}
                        )
                      </p>
                      <RiDeleteBinLine
                        className="fs-2 cursor-pointer"
                        onClick={toggleNested}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pn-right-part">
              <div className="mt-5 mb-5">
                <h2>Login or Pay as Guest</h2>
              </div>
              <div className="form-container text-start">
                <form onSubmit={handleSubmit}>
                  <div className="row inside-form">
                    <div className="col-12">
                      <Label for="email">Email</Label>
                      <InputGroup>
                        <Input
                          type="email"
                          placeholder="Enter your email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-md-12">
                      <Label for="Password">Password</Label>
                      <InputGroup className="overflow-hidden mb-3">
                        <div className="password-wrapper">
                          <Input
                            placeholder="Enter password"
                            className="fs-3 p-2"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={formData.password}
                            onChange={handleChange}
                          />
                          <span
                            className="password-toggle-icon"
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </InputGroup>
                    </div>
                    <div className="col-md-6">
                      <Label for="country">Country</Label>
                      <InputGroup>
                        <Input
                          type="select"
                          name="country"
                          className="fs-3 p-2 select-country-input"
                          value={formData.country}
                          onChange={handleChange}
                        >
                          <option disabled value="">
                            Select Country
                          </option>
                          {countries?.map((country) => (
                            <option
                              key={country.countryCode}
                              value={country._id}
                            >
                              {country.countryName}
                            </option>
                          ))}
                        </Input>
                      </InputGroup>
                    </div>
                    <div className="col-md-6">
                      <Label for="confirm-password">
                        Referral Code (optional)
                      </Label>
                      <InputGroup>
                        <Input
                          type="password"
                          placeholder="Enter your referral code"
                          onClick={handleChange}
                        />
                      </InputGroup>
                    </div>
                    {errorMessage && (
                      <Row className="justify-content-center">
                        <Col xs={12}>
                          <p className="error-msg">{errorMessage}</p>
                        </Col>
                      </Row>
                    )}
                    <div className="col-12">
                      <Button
                        type="submit"
                        text="Pay Now"
                        varient="linear-gradient-primary"
                        className="w-100"
                        disabled={!isFormValid || isSubmitting}
                      >
                        Pay Now
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={nestedModal}
        toggle={toggleNested}
        centered
        className="rounded-5 rounded"
        onClosed={closeAll ? toggle : undefined}
      >
        <ModalBody>
          <div className="p-5">
            <div className="nestedPopup">
              Are you sure you want to Remove this item?
            </div>
            <div className="pt-3 d-flex justify-content-end gap-3">
              <span
                className="fs-3 resumeBtn text-white bg-primary px-3 py-2 rounded-3"
                onClick={handleRemoveClick}
              >
                Remove
              </span>
              <span
                className="fs-3 exitBtn text-white bg-danger px-3 py-2 rounded-3"
                onClick={toggle}
              >
                Cancel
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PayNow;
