import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import cap from "../../Assets/images/capImg.jpg"
import nike from "../../Assets/images/nikeImg.jpg"
import { getCourseReviewbyId } from "../../services/getCourseReviewbyId";
import { toast } from "react-toastify";

const CourseReview = ({ courseID, avgRatings }) => {

    const [review, setReview] = useState([]);

    const fetchReview = async () => {
        try {
            const response = await getCourseReviewbyId(courseID);
            if (response.isSuccess) {
                setReview(response?.data);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch courses");
        }
    }

    useEffect(() => {
        fetchReview();
    }, [courseID]);

    const totalReviews = Array.isArray(review?.review) ? review.review.length : 0

    return (
        <div className="course-review">
            <div className="d-flex gap-4 align-items-center my-3">
                <p className="review-count">{totalReviews} Reviews</p>
                <div className='rating-stars'>
                    <Rating
                        size={15}
                        initialValue={parseFloat(avgRatings)}
                        readonly
                    />
                </div>
            </div>
            {review?.review?.map((rev) => (
                <div className="my-5" key={rev._id}>
                    <div className="d-flex align-items-center gap-4">
                        <div className="cap-img">
                            <img src={cap} alt="cap" />
                        </div>
                        <div>
                            <div className='rating-stars'>
                                <Rating
                                    size={15}
                                    initialValue={parseFloat(rev.ratings)}
                                    readonly
                                />
                            </div>
                            <div className="review-detail">
                                <p className="review-name">By: {rev.name} <span className="review-time">{rev.date}</span></p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="review-dec-card">
                        <p className="review-dec">
                            {rev.review}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CourseReview;
