import axiosInstance from "../axiosInterceptor";

export async function getAllUniversity(search) {
    const response = await axiosInstance.get(`/admin/universities/getAllUniversity`, {
        params: {
            search
        }
    });
    const data = response?.data;
    return data;
}
