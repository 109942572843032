import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { searchQuestion } from "../services/searchQuestion";
import { GoSearch } from "react-icons/go";

const ReactSearch = ({ subjectId = null }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 2) {
        fetchSearchResults();
      } else {
        setOptions([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchSearchResults = async () => {
    setIsLoading(true);
    try {
      const response = await searchQuestion(searchTerm, 1, 100, subjectId);

      if (response?.isSuccess && response.data.length > 0) {
        const formattedOptions = response.data.map((item) => ({
          value: item._id,
          label: item.transcriptText || item.question || "Untitled Question",
          fullItem: item,
        }));
        setOptions(formattedOptions);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error("Search failed:", error);
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      // navigate(`/question/${selectedOption.value}`, {
      //     state: { questionDetails: selectedOption.fullItem }
      // });
      navigate(
        `/homework-help/question-and-answer/${selectedOption.fullItem.transcriptUrl}`
      );
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      padding: "4px",
      display: "flex",
      alignItems: "center",
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "16px",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      color: state.isSelected ? "white" : "black",
      ":hover": {
        backgroundColor: state.isSelected ? "#007bff" : "#f1f1f1",
      },
      fontSize: "16px",
      textAlign: "left",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <GoSearch
        size={16}
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 10,
          color: "#D444ED",
        }}
      />
      <Select
        isSearchable
        isClearable
        isLoading={isLoading}
        placeholder="Search for questions..."
        onInputChange={(newValue) => setSearchTerm(newValue)}
        options={options}
        onChange={handleSelectChange}
        styles={customStyles}
        noOptionsMessage={() =>
          searchTerm.length > 2
            ? "No questions found"
            : "Start typing to search"
        }
      />
    </div>
  );
};

export default ReactSearch;
