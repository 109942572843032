import React, { useState } from "react";
import bannerimage from "../Assets/images/background/icon-bg.png";
import "./AllSubjectsBanner.scss";
import Button from "./Buttons/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

import levelOne from "../Assets/images/level-1.png";
import levelTwo from "../Assets/images/level-2.png";
import levelThree from "../Assets/images/level-3.png";
import ReactSearch from "./ReactSearch";

const AllSubjectsBanner = ({
  id = "",
  setIsModelOpen = () => {},
  btnDisabled = false,
  data,
}) => {
  const [modal, setModal] = useState(false);
  const [payload, setPayload] = useState({
    option: "",
    question: "",
  });

  const [stag, setStag] = useState(1);

  const toggle = () => setModal(!modal);

  const handleChnage = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const handleStage = () => {
    if (stag === 1) {
      setStag(2);
    }
    if (stag === 2) {
      setStag(3);
    }
  };

  return (
    <>
      <div className="banner-main-wrapper">
        <img className="banner-image" src={bannerimage} alt="bannerimage" />
        <div className="banner-content">
          {data?.questionSubject?.length > 0 && (
            <>
              <div className="banner-content-title-wrapper">
                <div className="title">
                  <div className="title-sub-banner">
                    <p>
                      <strong className="ql-size-huge ql-font-monospace">
                        {data?.questionSubject?.replace(" Academic Help", "")} Assignment Help |
                      </strong>
                    </p>
                    <p>
                      <strong className="ql-size-huge ql-font-monospace">
                        {data?.questionSubject?.replace(" Academic Help", "")} Homework Help
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="banner-content-subtitle">
                <span>
                  <div>
                    Get {data?.questionSubject?.replace(" Academic Help", "")} Assignment Help Online From Top{" "}
                    {data?.questionSubject?.replace(" Academic Help", "")} Tutor
                  </div>
                </span>
              </div>
            </>
          )}

          {data?.subSubjectName?.length > 0 && (
            <>
              <div className="banner-content-title-wrapper">
                <div className="title">
                  <div className="title-sub-banner">
                    <p>
                      <strong className="ql-size-huge ql-font-monospace">
                        {data?.subSubjectName?.replace(" Academic Help", "")} Assignment Help |
                      </strong>
                    </p>
                    <p>
                      <strong className="ql-size-huge ql-font-monospace">
                        {data?.subSubjectName?.replace(" Academic Help", "")} Homework Help
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="banner-content-subtitle">
                <span>
                  <div>
                    Get {data?.subSubjectName?.replace(" Academic Help", "")} Assignment Help Online From Top{" "}
                    {data?.subSubjectName?.replace(" Academic Help", "")} Tutor
                  </div>
                </span>
              </div>
            </>
          )}

          {data?.length === 0 || !data ? (
            <>
              <div className="banner-content-title-wrapper">
              
                  <div className="title-sub-banner">
                    <p>
                      <strong className="ql-size-huge ql-font-monospace all-sub-banner-title">
                        Expert Online Assignment & Homework Help for All
                        Subjects
                      </strong>
                    </p>
                    {/* <p>
                      <strong className="ql-size-huge ql-font-monospace">
                        All Subjects Homework Help
                      </strong>
                    </p> */}
                  </div>
         
              </div>
              <div className="banner-content-subtitle">
                <span>
                  <div className="all-sub-banner-subtittle">
                    Get professional assistance with your assignments and
                    homework across all subjects from top tutors. Expert
                    guidance, timely solutions, and high-quality support to help
                    you excel in your studies!
                  </div>
                </span>
              </div>
            </>
          ) : null}

          {!btnDisabled ? (
            <div className="banner-content-available-text">
              <Button
                varient="primary"
                text="Assignment Help"
                onClick={() => setIsModelOpen(true)}
              />
            </div>
          ) : (
            <></>
          )}

          {/* Search Section */}
          <div className="container pt-5">
            <div className="rbt-search">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <label
                      className="text-wight"
                      style={{ fontSize: "20px", fontWeight: "400" }}
                    >
                      Search for Question
                    </label>
                  </div>
                  <div className="inside-form">
                    <ReactSearch />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Search Section */}
        </div>

        {/* Full Screen */}
        <Modal isOpen={modal} toggle={toggle} fullscreen>
          <ModalHeader toggle={toggle} className="border-0"></ModalHeader>
          {stag === 1 && (
            <ModalBody>
              <Container>
                <div className="d-flex justify-content-center">
                  <img src={levelOne} alt="" />
                </div>
                <h1 className="step-header text-center">
                  Place Your Question here
                </h1>
                <h2 className="step-sub-header">How it works</h2>
                <Row>
                  <Col>
                    <FormGroup className="d-flex gap-5">
                      <Input
                        type="radio"
                        name="option"
                        value="Post one question at one time"
                        checked={
                          payload?.option === "Post one question at one time"
                        }
                        onChange={handleChnage}
                      />
                      <Label className="form-label m-0">
                        Post one question at one time
                      </Label>
                    </FormGroup>
                    <FormGroup className="d-flex gap-5">
                      <Input
                        type="radio"
                        name="option"
                        value="Get an instant answer from our experts"
                        checked={
                          payload?.option ===
                          "Get an instant answer from our experts"
                        }
                        onChange={handleChnage}
                      />
                      <Label className="form-label m-0">
                        Get an instant answer from our experts
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-3">
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="question"
                        className="question-textarea"
                        value={payload?.question}
                        onChange={handleChnage}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center gap-5">
                    <Button
                      varient="outline-secondaty"
                      text="Back"
                      onClick={toggle}
                    />
                    <Button
                      varient="linear-gradient-primary"
                      text="Continue"
                      onClick={handleStage}
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          )}
          {stag === 2 && (
            <ModalBody>
              <Container>
                <div className="d-flex justify-content-center">
                  <img src={levelTwo} alt="" />
                </div>
                <h1 className="step-header text-center">Select Your Subject</h1>
                <Row>
                  <FormGroup>
                    <Input
                      type="select"
                      name="subject"
                      className="question-select"
                    >
                      <option value="" disabled selected>
                        Select your subject
                      </option>
                      <option>Math</option>
                      <option>Science</option>
                      <option>English</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="select"
                      name="questionType"
                      className="type-select"
                    >
                      <option value="" disabled selected>
                        Select your question type
                      </option>
                      <option>Fill in the blanks - Final answer</option>
                      <option>True False - With Explanation</option>
                      <option>MCQ - Final answer</option>
                    </Input>
                  </FormGroup>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center gap-5">
                    <Button
                      varient="outline-secondaty"
                      text="Back"
                      onClick={toggle}
                    />
                    <Button
                      varient="linear-gradient-primary"
                      text="Continue"
                      onClick={handleStage}
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          )}

          {stag === 3 && (
            <ModalBody>
              <Container>
                <div className="d-flex justify-content-center">
                  <img src={levelThree} alt="" />
                </div>
                <Row className="justify-content-center mt-2">
                  <Card className="mb-3 ">
                    <CardBody>
                      <CardTitle tag="h5 " className="pb-1">
                        <h1 className="step-header text-center pb-1">
                          Question Preview
                        </h1>
                      </CardTitle>
                      <CardText>
                        <Row>
                          <Col className="d-flex">Question</Col>
                          <Col className="d-flex justify-content-end">
                            whats is next
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex">Subject</Col>
                          <Col className="d-flex justify-content-end">
                            Corporate Accounting
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex">Question Type</Col>
                          <Col className="d-flex justify-content-end">
                            MCQ - Final Answer
                          </Col>
                        </Row>
                        <Row>
                          <hr />
                        </Row>
                        <Row>
                          <Col className="d-flex">Question Price</Col>
                          <Col className="d-flex justify-content-end">
                            <h3>170 USD</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Button
                            varient="linear-gradient-primary"
                            text="Post Question"
                          />
                        </Row>
                      </CardText>
                    </CardBody>
                  </Card>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center gap-5">
                    <Button
                      varient="outline-secondaty"
                      text="Back"
                      onClick={toggle}
                    />
                    <Button
                      varient="linear-gradient-primary"
                      text="Continue"
                      onClick={toggle}
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          )}
        </Modal>
      </div>
    </>
  );
};

export default AllSubjectsBanner;
