import React, { useEffect, useState } from "react";
import { Collapse, Button, CardBody, CardHeader, Container } from "reactstrap";
import { Rating } from "react-simple-star-rating";

const FilterComp = ({ setAppliedFilter, initialFilter }) => {
    useEffect(() => {
        document.title = "Frequently Asked Questions (FAQ) | DoubtQ - Your Questions Answered";
    }, []);

    // Initialize selectedFilter with initialFilter
    const [selectedFilter, setSelectedFilter] = useState(initialFilter || {});
    const [openAccordionIds, setOpenAccordionIds] = useState([]);

    useEffect(() => {
        if (initialFilter) {
            setSelectedFilter(initialFilter); 
            setAppliedFilter(initialFilter);        }
    }, [initialFilter, setAppliedFilter]);

    const toggleAccordion = (accordionId) => {
        setOpenAccordionIds((prevState) =>
            prevState.includes(accordionId) ? prevState.filter((id) => id !== accordionId) : [...prevState, accordionId]
        );
    };

    const handleOptionClick = (title, optionValue) => {
        setSelectedFilter((prevState) => {
            if (title === "Items") {
                // "Items" filter stays selected but can be changed (no removal)
                const newFilter = { ...prevState, [title]: optionValue };
                setAppliedFilter(newFilter);
                return newFilter;
            } else {
                // For other filters, only one option can be selected at a time
                if (prevState[title] === optionValue) {
                    // If the clicked option is already selected, remove it
                    const newFilter = { ...prevState, [title]: undefined };
                    setAppliedFilter(newFilter); 
                    return newFilter;
                } else {
                    // Replace the previous selection with the new one
                    const newFilter = { ...prevState, [title]: optionValue };
                    setAppliedFilter(newFilter); 
                    return newFilter;
                }
            }
        });
    };


    const filterData = [
        {
            id: 1,
            title: "Items",
            options: [
                { label: "Special Study Notes", value: "Special Study Notes" },
                { label: "Practice Papers", value: "Practice Papers" },
                { label: "Question Bank", value: "Question Bank" },
            ],
        },
        {
            id: 2,
            title: "Reviews",
            options: [
                { label: 5, value: "5" },
                { label: 4, value: "4" },
                { label: 3, value: "3" },
                { label: 2, value: "2" },
                { label: 1, value: "1" },
            ],
        },
        {
            id: 3,
            title: "Price",
            options: [
                { label: "High to Low", value: -1 },
                { label: "Low to High", value: 1 },
            ],
        },
        {
            id: 4,
            title: "Purchases",
            options: [
                { label: "Most Recommended", value: -1 },
                { label: "Least Recommended", value: 1 },
            ],
        },
        {
            id: 5,
            title: "Views",
            options: [
                { label: "Maximum Views", value: -1 },
                { label: "Minimum Views", value: 1 },
            ],
        },
    ];

    return (
        <>
            <div className="mobile-animation">
                <h2 className="d-lg-block d-none">Select Filters</h2>
                <Container fluid className="px-0" style={{ paddingTop: "18px" }}>
                    <main className="rbt-main-wrapper questionAnswerjustify">
                        <div className="rbt-accordion-area accordion-style-1 bg-color-white rbt-section-gap">
                            <div className="container px-0">
                                <div className="rbt-accordion-style accordion" style={{ backgroundColor: "#F1F2F8" }}>
                                    {filterData.map((filter) => (
                                        <div key={filter.id} className="acc-border filter-acc">
                                            <CardHeader>
                                                <Button
                                                    className={`accordion-button acc-btn d-flex gap-4 ${openAccordionIds.includes(filter.id) ? "" : "collapsed"}`}
                                                    color="link"
                                                    onClick={() => toggleAccordion(filter.id)}
                                                    aria-expanded={openAccordionIds.includes(filter.id)}
                                                    style={{ backgroundColor: "#F1F2F8" }}
                                                >
                                                    {openAccordionIds.includes(filter.id) ? (
                                                        <i className="fa-solid fa-minus" style={{ color: "black" }}></i>
                                                    ) : (
                                                        <i className="fa-solid fa-plus" style={{ color: "black" }}></i>
                                                    )}
                                                    {filter.title}
                                                </Button>
                                            </CardHeader>
                                            <Collapse
                                                isOpen={openAccordionIds.includes(filter.id)}
                                                aria-labelledby={`heading${filter.id}`}
                                                data-parent="#accordionExamplec3"
                                            >
                                                <CardBody className="card-acc acc-content text-start">
                                                    {filter.options.map((option, idx) => {
                                                        const isSelected = selectedFilter[filter.title] === option.value;
                                                        return (
                                                            <div
                                                                key={idx}
                                                                className={`d-flex align-items-center filter-option ${isSelected ? 'selected' : ''}`}
                                                                onClick={() => handleOptionClick(filter.title, option.value)}
                                                                style={{
                                                                    backgroundColor: filter.title === "Reviews" ? "transparent" : isSelected ? "#EF2AFC" : "transparent",
                                                                    color: filter.title === "Reviews" ? "black" : isSelected ? "white" : "black",
                                                                    borderBottom: filter.title === "Reviews" ? "none" : isSelected ? "2px solid black" : "none",
                                                                    cursor: "pointer",
                                                                    padding: "5px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <span className="ps-4 d-flex align-items-center">
                                                                    {filter.title === "Reviews" ? (
                                                                        <div className="rating-stars d-flex gap-2 align-items-center">
                                                                            <input
                                                                                type="radio"
                                                                                name="review-rating"
                                                                                value={option.value}
                                                                                checked={isSelected}
                                                                                readOnly
                                                                            />
                                                                            <Rating
                                                                                size={20}
                                                                                initialValue={parseFloat(option.label)}
                                                                                readonly
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        option.label
                                                                    )}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </CardBody>
                                            </Collapse>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </main>
                </Container>
            </div>
            <style>
                {`
                    .filter-option:not(.selected):hover {
                        color: #EF2AFC !important;
                    }
                `}
            </style>
        </>
    );
};

export default FilterComp;
