import React, { useEffect, useState } from "react";
import { Col, Container, Row, Input, InputGroup, Label } from "reactstrap";
import assDash from "../../Assets/icons/assignment-dashboard.svg";
import live from "../../Assets/icons/live.svg";
import { getAllBonusOffer } from "../../services/getAllBonusOffer";
import CommonForm from "../HomePage/Form/CommonForm";
import { checkToken } from "../../services/checkToken";
import { getCategories } from "../../services/getCategories";
import { Helmet } from "react-helmet";
import Hwork from "../../Assets/images/New/Hwork01.png";
import Exam01 from "../../Assets/images/New/Exam01.png";
import { IoSearch } from "react-icons/io5";
import Button from "../../Components/Buttons/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getAllUniversity } from "../../services/getAllUniversity";
import { getAllCourseForStudent } from "../../services/getAllCourseForStudent";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import Select from "react-select";
import DashBoardExamPrepSearch from "./DashBoardExamPrepSearch";
import { GoSearch } from "react-icons/go";
import { Link } from "react-router-dom";

const DashboardHome = () => {
  const [pageData, setPageData] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [rawData, setRawData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [phoneValue, setPhoneValue] = useState("");
  const phoneInputRef = React.useRef();

  const category = [
    "Special Study Notes",
    "Exam Preparation",
    "Question bank",
    "Practice Papers",
  ];

  const initialFormState = {
    course: "",
    university: "",
    whatsappNumber: "",
    email: "",
    courseType: "",
  };

  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    const fetchAllUniversityData = async () => {
      try {
        const response = await getAllUniversity();
        setRawData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllUniversityData();
  }, []);

  useEffect(() => {
    const fetchAllCourseForStudentData = async () => {
      try {
        const response = await getAllCourseForStudent();
        setCourseData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllCourseForStudentData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    try {
      await axiosInstance.post("/student/courseEnquiry/createCourseEnquiry", {
        courseName: formData.course,
        universityName: formData.university,
        wpNumber: formData.whatsappNumber,
        email: formData.email,
        courseType: formData.courseType,
      });

      setFormData(initialFormState);
      setSelectedCourse(null);
      setSelectedUniversity(null);
      setPhoneValue("");
      if (phoneInputRef.current) {
        phoneInputRef.current.state.value = "";
      }

      toast.success("Form Submitted Successfully");
    } catch (error) {
      toast.error("Error submitting form");
      console.error("Error submitting form:", error);
    }

    setIsSubmitting(false);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedUniversity(selectedOption);
    setFormData({
      ...formData,
      university: selectedOption ? selectedOption.value : "",
    });
  };

  const handleCourseSelectChange = (selectedOption) => {
    setSelectedCourse(selectedOption);
    setFormData({
      ...formData,
      course: selectedOption ? selectedOption.value : "",
    });
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
    setFormData({ ...formData, whatsappNumber: value });
  };

  const university = [];
  rawData.map((item) =>
    item?.universityNames?.map((item) => university.push(item))
  );

  const isFormValid =
    formData.course &&
    formData.university &&
    formData.whatsappNumber &&
    formData.courseType &&
    formData.email;

  useEffect(() => {
    checkToken();
    return () => {
      const verifyToken = async () => await checkToken();
      const response = verifyToken();
    };
  }, []);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const fetchData = async () => {
    await getAllBonusOffer()
      .then((data) => {
        setPageData(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [categorieData, setCategorieData] = useState([]);

  const fetchDatacategorie = async () => {
    await getCategories()
      .then((data) => {
        setCategorieData(data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchDatacategorie();
  }, []);

  const handlePopup = (serviceType, type, totalSteps) => {
    setIsModelOpen(true);
    setServiceType({ serviceType, type, totalSteps });
    localStorage.setItem("serviceType", serviceType);
  };

  return (
    <>
      <Helmet>
        <title>
          Dashboard | Get Personalized Academic Success with DoubtQ | Online
          Question Help
        </title>
        <meta name="description" content="Welcome to the Dashboard of DoubtQ" />
        <meta property="og:title" content="Dashboard - DoubtQ" />
        <meta
          property="og:description"
          content="Explore personalized academic help on DoubtQ's Dashboard"
        />
      </Helmet>
      <Container className="py-5 pt-0 w-85 dashboard">
        <h1 className="fw-bolder py-5">Get Expert For...</h1>
        <Row>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className="d-flex justify-content-center mb-4"
          >
            <div
              className="category-Card text-decoration-none"
              onClick={() => handlePopup("AssignmentHelp", "assignmentHelp", 4)}
            >
              <img src={assDash} alt="assDash" />
              <h3 className="mt-5">{categorieData[1]?.name}</h3>
              <h3>{categorieData[1]?.description}</h3>
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className="d-flex justify-content-center mb-4"
          >
            <div
              className="category-Card text-decoration-none"
              onClick={() =>
                handlePopup("LiveSessionHelp", "livesessionHelp", 4)
              }
            >
              <img src={live} alt="live" />
              <h3 className="mt-5">{categorieData[3]?.name}</h3>
              <h3>{categorieData[3]?.description}</h3>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className="d-flex justify-content-center mb-4"
          >
            <div
              className="category-Card text-decoration-none"
              onClick={() => handlePopup("HomeworkHelp", "mcqHelp", 3)}
            >
              <img src={Hwork} alt="Hwork" />
              <h3 className="mt-5">{categorieData[2]?.name}</h3>
              <h3>{categorieData[2]?.description}</h3>
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className="d-flex justify-content-center mb-4"
          >
            <Link
              to="/dashboard/dashboard-university"
              className="text-decoration-none category-Card"
            >
              <div>
                <img src={Exam01} alt="Exam01" />
                <h3 className="mt-5">{categorieData[0]?.name}</h3>
                <h3>{categorieData[0]?.description}</h3>
              </div>
            </Link>
          </Col>
        </Row>
        <Row className="mt-5">
          <div className="rbt-search">
            <div className="row">
              <div className="col-lg-12">
                <div style={{ position: "relative", width: "100%" }}>
                  <GoSearch
                    size={16}
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 10,
                      color: "#D444ED",
                    }}
                  />
                  <DashBoardExamPrepSearch />
                </div>
              </div>
            </div>
          </div>
        </Row>
        <br />
        <br />
        <Row className="mt-5">
          <div className="que-bank-form">
            <div className="mb-4">
              <h3>Get Your Special question Bank</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} xl={12}>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={6}
                      xl={6}
                      className="text-start que-bank-all-input"
                    >
                      <Label>
                        <b>
                          <small className="fs-4">Category</small>
                        </b>
                      </Label>
                      <InputGroup className="mb-3">
                        <Input
                          type="select"
                          name="courseType"
                          className="fs-5 px-4 py-2 select-prac-input"
                          value={formData?.courseType}
                          onChange={handleChange}
                        >
                          <option disabled value="">
                            Choose Category
                          </option>
                          {category?.length > 0 &&
                            category.map((item, index) => (
                              <option key={index}>{item}</option>
                            ))}
                        </Input>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={6}
                  xl={6}
                  className="text-start que-bank-all-input mb-4"
                >
                  <Label>
                    <b>
                      <small className="fs-4">Course Name</small>
                    </b>
                  </Label>
                  <Select
                    id="course"
                    name="course"
                    value={selectedCourse}
                    onChange={handleCourseSelectChange}
                    options={
                      Array.isArray(courseData)
                        ? courseData.map((item) => ({
                            value: item.courseName,
                            label: item.courseName,
                          }))
                        : []
                    }
                    isClearable
                    placeholder="Select a course"
                    classNamePrefix="react-select"
                    isSearchable
                    styles={{
                      singleValue: (provided) => ({
                        ...provided,
                        paddingLeft: "10px",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        paddingLeft: "10px",
                        fontSize: "1.3rem",
                      }),
                    }}
                  />
                </Col>

                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={6}
                  xl={6}
                  className="text-start que-bank-all-input mb-4"
                >
                  <Label>
                    <b>
                      <small className="fs-4">University Name</small>
                    </b>
                  </Label>
                  <Select
                    id="university"
                    name="university"
                    value={selectedUniversity}
                    onChange={handleSelectChange}
                    options={
                      Array.isArray(rawData)
                        ? rawData.map((item) => ({
                            value: item.universityName,
                            label: item.universityName || "No name",
                          }))
                        : []
                    }
                    isClearable
                    placeholder="Select a university"
                    classNamePrefix="react-select"
                    isSearchable
                    styles={{
                      singleValue: (provided) => ({
                        ...provided,
                        paddingLeft: "10px",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        paddingLeft: "10px",
                        fontSize: "1.3rem",
                      }),
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={6}
                  xl={6}
                  className="text-start que-bank-all-input"
                >
                  <Label>
                    <b>
                      <small className="fs-4 text-start">WhatsApp Number</small>
                    </b>
                  </Label>
                  <InputGroup className="mb-3">
                    <PhoneInput
                      country={"ca"}
                      className=""
                      inputClass="fs-3"
                      ref={phoneInputRef}
                      inputProps={{
                        name: "whatsappNumber",
                        required: true,
                        className:
                          "fs-3 p-2 select-prac-input c-padding num-padd",
                      }}
                      value={phoneValue}
                      onChange={handlePhoneChange}
                    />
                  </InputGroup>
                </Col>

                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={6}
                  xl={6}
                  className="text-start que-bank-all-input"
                >
                  <Label>
                    <b>
                      <small className="fs-4">Email</small>
                    </b>
                  </Label>
                  <InputGroup className="overflow-hidden mb-3">
                    <Input
                      placeholder="Enter email"
                      className="fs-3 px-4 py-2 select-prac-input"
                      name="email"
                      value={formData?.email}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Col>
              </Row>
              {errorMessage && (
                <Row className="justify-content-center">
                  <Col xs={12}>
                    <p className="error-msg">{errorMessage}</p>
                  </Col>
                </Row>
              )}
              <div className="que-bank-chat-with-us-form2 mt-4">
                <button className="que-bank-chat-with-us-btn-form2">
                  <i
                    className="fa-brands fa-whatsapp fs-1"
                    style={{ color: "#fff" }}
                  ></i>
                  <span>Chat with us</span>
                </button>
                <Button
                  varient="linear-gradient-primary"
                  className="p-2 px-4 col-3 search-btn-db-form2"
                  text={"Submit"}
                  type="submit"
                  disabled={!isFormValid || isSubmitting}
                ></Button>
              </div>
            </form>
          </div>
        </Row>
        {/* <Row className="pt-5 justify-content-center">
          <h1 className="fw-bolder">Ask Question from Experts</h1>
          <h3>Short and Final answers</h3>
          <Button
            className="mt-5 w-50 ask-que"
            text={"Ask Question"}
            onClick={() => handlePopup("HomeworkHelp", "mcqHelp", 3)}
          ></Button>
        </Row> */}
      </Container>
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={serviceType}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DashboardHome;
