import React, { useEffect, useState } from "react";
import "./DocPreview.scss";
import logo from "../Assets/logo/logo.svg";
import Button from "./Buttons/Button";
import { GiCheckMark } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";

const DocPreview = ({ course, id }) => { 
  const navigator = useNavigate();
  let price = 0;
  let typeId = "";
  const [courseData, setCourseData] = useState({});
  const [isId, setIsId] = useState(null);

  useEffect(() => {
    setCourseData(course);
    setIsId(id);
  }, [course, id]);

  if (courseData && Object.keys(courseData).length > 0) {
    if (isId) {
      if (id === "spsn") {
        price = courseData.studyNotes?.price;
        typeId = courseData.studyNotes?._id;
      } else if (id === "pp") {
        price = courseData.practicePapers?.price;
        typeId = courseData.practicePapers?._id;
      } else if (id === "qb") {
        price = courseData.questionBank?.price;
        typeId = courseData.questionBank?._id;
      }
    } else {
      price =
        courseData?.type === "Special Study Notes"
          ? courseData?.studyNotes?.price
          : courseData?.type === "Practice Papers"
          ? courseData?.practicePapers?.price
          : courseData?.type === "Question Bank"
          ? courseData?.questionBank?.price
          : "N/A";
      typeId =
        courseData?.type === "Special Study Notes"
          ? courseData?.studyNotes?._id
          : courseData?.type === "Practice Papers"
          ? courseData?.practicePapers?._id
          : courseData?.type === "Question Bank"
          ? courseData?.questionBank?._id
          : "N/A";
    }
  }

  return (
    <div className="doc-preview-container mb-5">
      <div className="both-pages">
        {/* Page 1 */}
        <div className="page-one">
          <div className="company-logo">
            <img src={logo} alt="Company Logo" />
          </div>
          <div className="course-name">
            <p className="">
              <Link
                // to={course.courseUrl}
                className="text-black text-decoration-none"
              >
                <span className="text-black">
                  {courseData?.university?.universityName}
                </span>
              </Link>
            </p>
            <p className="">
              <Link
                // to={course.courseUrl}
                className="text-black text-decoration-none"
              >
                <span className="text-black">{courseData?.courseName}</span>
              </Link>
            </p>
            <p className="">
              <span className="text-black">
                {course?.tempCourse?.courseType}
              </span>
            </p>
          </div>
          <div className="position-absolute bottom-0 end-0 p-3">
            <p>Page 1</p>
          </div>
        </div>

        <div className="page-two">
          <div className="blur-overlay">
            <div className="overlay-content">
              <h1>Export Study Material</h1>
              <h1> ${course?.tempCourse?.coursePrice || 0}</h1>
              {/* <p>This course examines number of theories of personality,</p> */}
              <Button
                type="submit"
                text="Buy Now"
                varient="linear-gradient-primary"
                className="my-1 px-4 py-3"
                onClick={() => {
                  navigator("/paynow", {
                    state: {
                      ...courseData,
                      price: price || 0,
                      typeId: typeId || "",
                    },
                  });
                }}
              />
              <p className="mb-4">All Rights Reserved to DoubtQ !</p>

              <div className="overlay-content-list">
                <div className="overlay-content-item">
                  <GiCheckMark className="overlay-content-item-icon" />
                  <p>100% satisfaction guarantee</p>
                </div>
                <div className="overlay-content-item">
                  <GiCheckMark className="overlay-content-item-icon" />
                  <p>Accurate Study Notes</p>
                </div>
                <div className="overlay-content-item">
                  <GiCheckMark className="overlay-content-item-icon" />
                  <p>Available in PDF</p>
                </div>
                <div className="overlay-content-item">
                  <GiCheckMark className="overlay-content-item-icon" />
                  <p>No Additional Costs</p>
                </div>
              </div>
            </div>
          </div>
          <div className="position-absolute bottom-0 end-0 p-3">
            <p>Page 2</p>
          </div>
        </div>

        {/* Page 2 */}
        {/* <div className="page-two position-relative">
          
          <div className="position-absolute bottom-0 end-0 p-3">
            <p>Page 2</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DocPreview;
