import React, { useEffect, useState } from "react";
import "./AllSubjectsCategory.scss";
import { Link } from "react-router-dom";
import { getSubSubjectBySubjectSlugRandomly } from "../services/getSubSubjectBySubjectSlugRandomly";

const SubHashtags = ({ slug }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [subSubjectData, setsubSubjectData] = useState([]);

  const fetchsubSubjectData = async () => {
    try {
      const response = await getSubSubjectBySubjectSlugRandomly(slug);
      setsubSubjectData(response.data);
    } catch (error) {
      console.error("Error fetching subsubject data:", error);
    }
  };

  useEffect(() => {
    fetchsubSubjectData();
  }, []);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedContent = isExpanded
    ? subSubjectData
    : subSubjectData.slice(0, 30);

  return (
    <div className="subject-tabs-div overflow-x-hidden">
      <div className="row">
        <div id="tab-content" className="tabcontent">
          <div className="subject-tabs">
            <ul className="d-flex justify-content-start flex-wrap">
              {displayedContent.map((subject, index) => (
                <li key={index}>
                  <Link to={`/${subject?.subjectUrl}/${subject.subSubjectUrl}`}>
                    {subject?.subSubjectName?.replace(" Academic Help", "")}
                  </Link>
                </li>
              ))}
            </ul>
            {subSubjectData?.length > 30 && (
              <button
                onClick={handleToggle}
                className="rbt-btn btn-gradient btn-sm mt-4"
              >
                {isExpanded ? "Show Less" : "Show More"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHashtags;
