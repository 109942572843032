import axiosInstance from "../axiosInterceptor";

export async function getCourseReviewbyId(courseId) {
    try {
        const response = await axiosInstance.get(`admin/courseReview/getCourseReviewById/${courseId}`);
        const data = response?.data;
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return { isSuccess: false, message: "Failed to fetch course review" };
    }
}