import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./DashboardViewPopup.scss";
import { Document, Page } from "react-pdf";
import { Rating } from "react-simple-star-rating";
import { dateFormat } from "./dateFormat";
import DashDocPreview from "../../Components/Course/DashDocPreview";
import Button from "../../Components/Buttons/Button";
import { ImageLink } from "../../constants";
import { GiCheckMark } from "react-icons/gi";

const DashboardViewPopup = ({ isOpen, toggle, courseData }) => {

  const navigator = useNavigate();
  const [numPages, setNumPages] = useState(null);

  var pdfUrl = "";

  var price = 0;
  if (courseData?.type === "Practice Papers") {
    price = courseData?.practicePapers.price;
    pdfUrl = courseData?.practicePapers.filePath;
  } else if (courseData?.type === "Question Bank") {
    price = courseData?.questionBank.price;
    pdfUrl = courseData?.questionBank.filePath;
  } else if (courseData?.type === "Special Study Notes") {
    price = courseData?.studyNotes.price;
    pdfUrl = courseData?.studyNotes.filePath;
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const closeBtn = (
    <button
      className="view-popup-close-btn"
      onClick={toggle}
      style={{ zIndex: 1 }}
      type="button"
    >
      X
    </button>
  );

  return (
    <>
      <div className="view-popup">
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          size="xl"
          className="view-popup-modal"
        >
          <ModalHeader close={closeBtn} className="border-0"></ModalHeader>
          <ModalBody
            style={{ padding: "0px 0px 0px 10px", marginBottom: "0px" }}
          >
            <Row>
              <Col lg={6} md={12} className="view-popup-left">
                <div className="view-popup-left-header">
                  <p>Preview 2 out of 6 Pages</p>
                </div>

                {pdfUrl ? (
                  <div className="view-popup-pdf-container">
                    <div className="view-popup-pdf">
                      {/* <Document
                        file={ImageLink(pdfUrl)}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {Array.from(
                          new Array(Math.min(numPages, 2)),
                          (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                            />
                          )
                        )}
                        <div className="blur-overlay-pdf">
                          <div className="overlay-content">
                            <h1>Export Study Material</h1>
                            <h1>${price}</h1>
                            <p>
                              This course examines number of theories of
                              personality,
                            </p>
                            <Button
                              type="submit"
                              text="Buy Now"
                              varient="linear-gradient-primary"
                              className="mt-3 mb-3"
                              onClick={() => {
                                navigator("/dashboard/paynow", {
                                  state: { ...courseData, price: price || 0 },
                                });
                              }}
                            >
                              Buy Now
                            </Button>

                            <p className="mb-4">All Rights Reserved to DoubtQ !</p>

                            <div className="overlay-content-list">
                              <div className="overlay-content-item">
                                <GiCheckMark className="overlay-content-item-icon" />
                                <p>100% satisfaction guarantee</p>
                              </div>
                              <div className="overlay-content-item">
                                <GiCheckMark className="overlay-content-item-icon" />
                                <p>Accurate Study Notes</p>
                              </div>
                              <div className="overlay-content-item">
                                <GiCheckMark className="overlay-content-item-icon" />
                                <p>Available in PDF</p>
                              </div>
                              <div className="overlay-content-item">
                                <GiCheckMark className="overlay-content-item-icon" />
                                <p>No Additional Costs</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Document> */}

                      <Document
                        file={ImageLink(pdfUrl)}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <div className="pdf-page-wrapper">
                          <Page pageNumber={1} />
                        </div>

                        <div className="pdf-page-wrapper">
                          <div className="blurred">
                            <Page pageNumber={2} />
                          </div>
                          <div className="blur-overlay-pdf">
                            <div className="overlay-content">
                              <h1>Export Study Material</h1>
                              <h1>${price}</h1>
                              <p>
                                This course examines number of theories of
                                personality,
                              </p>
                              <Button
                                type="submit"
                                text="Buy Now"
                                varient="linear-gradient-primary"
                                className="mt-3 mb-3"
                                onClick={() => {
                                  navigator("/dashboard/paynow", {
                                    state: { ...courseData, price: price || 0 },
                                  });
                                }}
                              >
                                Buy Now
                              </Button>

                              <p className="mb-4">
                                All Rights Reserved to DoubtQ !
                              </p>

                              <div className="overlay-content-list">
                                <div className="overlay-content-item">
                                  <GiCheckMark className="overlay-content-item-icon" />
                                  <p>100% satisfaction guarantee</p>
                                </div>
                                <div className="overlay-content-item">
                                  <GiCheckMark className="overlay-content-item-icon" />
                                  <p>Accurate Study Notes</p>
                                </div>
                                <div className="overlay-content-item">
                                  <GiCheckMark className="overlay-content-item-icon" />
                                  <p>Available in PDF</p>
                                </div>
                                <div className="overlay-content-item">
                                  <GiCheckMark className="overlay-content-item-icon" />
                                  <p>No Additional Costs</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Document>
                    </div>
                  </div>
                ) : (
                  <DashDocPreview courseData={courseData} />
                )}
              </Col>
              <Col lg={6} md={12}>
                <div className="view-popup-right">
                  <div className="view-popup-right-header">
                    <p>{courseData?.tempCourse?.courseType}</p>
                    <div className="pb-1">
                      <Rating
                        size={15}
                        initialValue={courseData?.ratings}
                        readonly
                      />
                    </div>
                    <span> ({courseData?.ratings})</span>
                  </div>
                  <div className="view-popup-right-content">
                    <h2>{courseData?.title}</h2>
                    <p>{courseData?.subTitle}</p>
                    <p className="view-popup-price">
                      {" "}
                      <span>
                        $ {courseData?.tempCourse?.coursePrice}
                        {/* {courseData?.type === "Special Study Notes"
                          ? courseData?.studyNotes?.price
                          : courseData?.type === "Practice Papers"
                          ? courseData?.practicePapers?.price
                          : courseData?.type === "Question Bank"
                          ? courseData?.questionBank?.price
                          : "N/A"} */}
                      </span>{" "}
                      <span className="course-date">
                        {courseData?.updatedAt &&
                          dateFormat(courseData?.updatedAt)}
                      </span>
                    </p>
                    <div className="view-popup-right-footer">
                      <Button
                        varient="linear-gradient-primary"
                        className="pt-3 pb-3 ps-4 pe-4"
                        text="Buy Now"
                        onClick={() => {
                          navigator("/dashboard/paynow", {
                            state: courseData,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default DashboardViewPopup;
