import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";

import Library from "./Library";
import { searchQuestion } from "../../services/searchQuestion";
import { useSearchParams } from "react-router-dom";

const DashboardLibrary = () => {
  const [activeSubject, setActiveSubject] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [searchTerm, setSearchTerm] = useSearchParams();

  const [isDataFetching, setIsDataFetching] = useState(true);

  const fetchData = async () => {
    try {
      const search = searchTerm.get("query");
      const data = await searchQuestion(
        search,
        currentPage,
        5,
        activeSubject
      );
      setPageData(data.data);
      setTotalPages(data.totalPages);
      setIsDataFetching(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchQuestion = () => {
    setIsDataFetching(true);
    fetchData();
  };

  useEffect(() => {
    setIsDataFetching(true);
    fetchData();
  }, [currentPage, activeSubject, searchTerm]);


  return (
    <div>
      <div className="library-top py-5">
        <Container className="w-85 dashboard">
          <Row className="mt-5 pb-5">
            <Col lg={6} className="mb-2">
              <Card className="library-top-card p-3 rounded-4">
                <h2 className="fw-bold">
                  <span className="card-number">97%</span> Students <br />
                  got better Grade
                </h2>
              </Card>
            </Col>
            <Col lg={6} className="mb-2">
              <Card className="library-top-card p-3 rounded-4">
                <h2 className="fw-bold">
                  Daily <span className="card-number">9709</span> <br />
                  people use Q&A
                </h2>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Library
        heading={"Access all questions by Subject"}
        pageData={pageData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        activeSubject={activeSubject}
        setActiveSubject={setActiveSubject}
        isDataFetching={isDataFetching}
      />
    </div>
  );
};

export default DashboardLibrary;
