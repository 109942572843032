import React, { useEffect, useState } from "react";
// import WhatsappIcon from "../Assets/icons/whatsapp-icon.svg";
import ind from "../Assets/icons/india.png";
import CA from "../Assets/icons/canada.png";
import ND from "../Assets/icons/netherlands.png";
import emailIcon from "../Assets/icons/email-icon.svg";
// import locationIcon from "../Assets/icons/location-footer.svg";
import whiteLogo from "../Assets/images/New/white-logo.png";
import "./Footer.scss";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { getAllSubject } from "../services/getAllSubject";
const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const Footer = () => {
  const [footerLinks, setFooterLinks] = useState([]);
  const [setting, setSetting] = useState([]);
  const [socialMedia, setsocialMedia] = useState([]);
  // const contactNumber = setting?.contactNumber;

  const [contact, setContact] = useState([]);
  const [subjectData, setSubjectData] = useState([]);

  const fetchSubjectData = async () => {
    try {
      const response = await getAllSubject();
      setSubjectData(response.data);
    } catch (error) {
      console.error("Error fetching subject data:", error);
    }
  };

  useEffect(() => {
    fetchSubjectData();
  }, []);

  const formatContactNumber = (countryName, contactNumber) => {
    switch (countryName) {
      case "India":
        return `+${contactNumber.slice(0, 2)} ${contactNumber.slice(
          2,
          7
        )} ${contactNumber.slice(7)}`;
      case "Canada":
        return `+${contactNumber.slice(0, 1)} (${contactNumber.slice(
          1,
          4
        )}) ${contactNumber.slice(4, 7)}-${contactNumber.slice(7)}`;
      case "Netherlands":
        return `+${contactNumber.slice(0, 2)} ${contactNumber.slice(
          2,
          5
        )} ${contactNumber.slice(5, 7)} ${contactNumber.slice(7)}`;
      default:
        return contactNumber;
    }
  };

  const fetchContactData = async () => {
    try {
      const res = await axiosInstance.get(
        `/admin/contactNumber/getContactNumbers`
      );
      setContact(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await axiosInstance.get(
        `/admin/category/getCategoryWiseSubcategory`
      );
      setFooterLinks(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchsocialMediaData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/socialMedia/getSocialMedia`);
      setsocialMedia(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [cmsData, setCmsData] = useState([]);

  const fetchCMSData = async () => {
    try {
      const response = await axiosInstance.get("/admin/cms/getAllCMS");
      setCmsData(response.data.data);
    } catch (error) {
      console.error("Error fetching CMS data:", error);
    }
  };

  useEffect(() => {
    fetchCMSData();

    fetchData();
    fetchSettingData();
    fetchsocialMediaData();
    fetchContactData();
  }, []);

  const getSubCategoriesByCategory = (categoryName) => {
    const subCategory = footerLinks?.find(
      (x) => x?.categoryName === categoryName
    );
    return subCategory;
  };

  useEffect(() => {
    const rzpPaymentForm = document.getElementById("rzp_payment_form");

    if (!rzpPaymentForm?.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_O7ZeG8gDz86T7H";
      rzpPaymentForm?.appendChild(script);
    }
  });

  return (
    <div className="footer-section">
      <div className="footer-container container">
        <div>
          <div className="row">
            <div className="footer-col col-sm-6 col-md-6 col-12 m-0">
              <img src={whiteLogo} alt="Footer-white-logo" />
            </div>
            <div className="footer-col col-sm-6 col-md-6 col-12 m-0">
              <ul className="social-media-footer mob text-end">
                {/* <li> */}
                {socialMedia?.map((media, index) => (
                  <li
                    key={index}
                    className="li-icon"
                    style={{ display: "inline-block", marginRight: "10px" }}
                  >
                    <Link to={media.url} target="_blank">
                      <img
                        className="footer-icon"
                        src={`${baseURL}${media.image}`}
                        alt={media.name}
                        height={24}
                        width={24}
                      />
                    </Link>
                  </li>
                ))}
                {/* </li> */}
              </ul>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="footer-col col-lg-2 col-sm-6 col-md-4 col-12">
              {getSubCategoriesByCategory("Exam Preparation") ? (
                <>
                  <h3>Exam Preparation</h3>
                  <ul className="p-0">
                    {getSubCategoriesByCategory(
                      "Exam Preparation"
                    )?.subcategories.map((element) => {
                      return (
                        <li key={element.path}>
                          <Link
                            className="text-decoration-none"
                            to={element.path}
                          >
                            {element.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : null}
              {getSubCategoriesByCategory("Live Session Help") ? (
                <>
                  <h3>Live Session Help</h3>
                  <ul className="p-0">
                    {getSubCategoriesByCategory(
                      "Live Session Help"
                    )?.subcategories.map((element) => {
                      return (
                        <li key={element.path}>
                          <Link
                            className="text-decoration-none"
                            to={element.path}
                          >
                            {element.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : null}
            </div>
            <div className="footer-col col-lg-3 col-sm-6 col-md-4 col-12">
              {getSubCategoriesByCategory("Assignment Help") ? (
                <>
                  <h3>Assignment Help</h3>
                  <ul className="p-0">
                    {getSubCategoriesByCategory(
                      "Assignment Help"
                    ).subcategories.map((element) => {
                      return (
                        <li key={element.path}>
                          <Link
                            className="text-decoration-none"
                            to={element.path}
                          >
                            {element.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : null}
              {getSubCategoriesByCategory("Homework Help") ? (
                <>
                  <h3>Homework Help</h3>
                  <ul className="p-0">
                    {getSubCategoriesByCategory(
                      "Homework Help"
                    )?.subcategories.map((element) => {
                      return (
                        <li key={element.path}>
                          <Link
                            className="text-decoration-none"
                            to={element.path}
                          >
                            {element.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : null}
            </div>
            <div className="footer-col col-lg-3 col-sm-6 col-md-4 col-12">
              <>
                <h3>Subjects</h3>
                <ul className="p-0">
                  {subjectData
                    .filter((sub) => sub.showInFooter)
                    .map((sub) => (
                      <li key={sub.subjectUrl}>
                        <Link
                          className="text-decoration-none"
                          to={`/subject/${sub.subjectUrl}`}
                        >
                          {sub.questionSubject}
                        </Link>
                      </li>
                    ))}
                </ul>
              </>
            </div>
            <div className="footer-col col-lg-2 col-sm-6 col-md-4 col-12">
              <h3>About Us</h3>
              <ul className="p-0">
                <li>
                  <Link to="/comingsoon" className="text-decoration-none">
                    Become a tutor
                  </Link>
                </li>
                <li>
                  <Link to="/faqs" className="text-decoration-none">
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link to="/reviews" className="text-decoration-none">
                    Reviews
                  </Link>
                </li>
                {cmsData.map((data, i) => {
                  return (
                    <li key={i}>
                      <Link
                        to={`/${data.slug}`}
                        className="text-decoration-none"
                      >
                        {data.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer-col col-lg-2 col-sm-6 col-md-4 col-12">
              <h3>Contact Us</h3>
              <ul className="p-0">
                {/* <li className="li-icon">
                  <img
                    className="mt-1"
                    src={locationIcon}
                    height={16}
                    width={16}
                  />
                  B-603, Sky View Heights, Behind Midas Square, Surat, Gujarat,
                  395010
                </li> */}
                {contact
                  .filter((c) => c.showInFooter || c.showInHeader)
                  .map((c, index) => {
                    if (c.showInFooter) {
                      const countryFlag =
                        c.countryName === "Canada"
                          ? CA
                          : c.countryName === "Netherlands"
                          ? ND
                          : ind;

                      return (
                        <li key={index} className="li-icon">
                          <Link
                            to={`https://wa.me/${c.contactNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="whatsapp-link"
                          >
                            <img
                              // className="mt-1"
                              src={countryFlag}
                              alt={`${c.countryName} WhatsApp Icon`}
                              height={16}
                              width={16}
                            />
                            {c.countryName}:{" "}
                            {formatContactNumber(
                              c.countryName,
                              c.contactNumber
                            )}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                {/* <li className="li-icon">
                  <Link
                    to="https://wa.me/3197010206802"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp-link"
                  >
                    <img
                      className="mt-1"
                      src={ND}
                      alt="WhatsApp Icon"
                      height={16}
                      width={16}
                    />
                    Netherlands: +31 970 10 206802
                  </Link>
                </li> */}
                <li className="li-icon">
                  <Link
                    to={`mailto:${setting?.contactEmail}`}
                    className="email-link"
                  >
                    <img
                      className="mt-1"
                      src={emailIcon}
                      alt="Email Icon"
                      height={13}
                      width={13}
                    />
                    {` ${setting?.contactEmail}`}
                  </Link>
                </li>
              </ul>
              <ul className="social-media-footer des">
                {/* <li> */}
                {socialMedia.map((media, index) => (
                  <li
                    key={index}
                    className="li-icon"
                    style={{ display: "inline-block", marginRight: "10px" }}
                  >
                    <Link to={media.url} target="_blank">
                      <img
                        className="footer-icon"
                        src={`${baseURL}${media.image}`}
                        alt={media.name}
                        height={24}
                        width={24}
                      />
                    </Link>
                  </li>
                ))}
                {/* </li> */}
              </ul>
            </div>
            <hr />
            <div className="footer-end-section">
              <span>
                <h3>
                  © {new Date().getFullYear()} DoubtQ. All Rights Reserved
                </h3>
              </span>
              <div className="d-flex">
                <p dangerouslySetInnerHTML={{ __html: setting?.disclaimer }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
