import React from "react";
// import Nav from '../../Components/Nav';
// import Navbar from "../../Components/Navbar";
import AllUniversity from "../../Components/University/AllUniversity";
import Certificate from "../../Components/ExamPreparationCards/Certificate";
import Footer from "../../Components/Footer";
import AllUniversityBanner from "../../Components/University/AllUniversityBanner";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import Page from "../Metadata/Page";

const UniversityList = () => {
  // Live DB Id
  // const subCategoryId = "67b58272fa3a63e9ddb921e5";
  // const categoryId = "67b57f038a01c99610fc51b2";

  // Test DB Id
  const subCategoryId = "679480e4e20697cecf77d90c";
  const categoryId = "677e07a9159c38853391c934";

  return (
    <>
      {/* <Nav />
        <Navbar /> */}
      <Page subCategoryId={subCategoryId} categoryId={categoryId} />
      <div className="row my-4 px-4 m-0 breadcrumb-border">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">Universities</li>
            </ul>
          </div>
        </div>
      </div>
      <AllUniversityBanner />
      <AllUniversity />
      <Certificate />
      <Footer />
      <ScrollTop />
    </>
  );
};

export default UniversityList;
