import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import "../../Components/OrderStatusAndBilling/CheckOut.scss";
import LogoHeader from "../../Components/OrderStatusAndBilling/LogoHeader";
import CourseCheckoutForm from "./CourseCheckoutForm";
const CourseCheckOut = (props) => {
  const { stripePromise } = props;
  const { state } = useLocation();
  const { clientSecret = "" } = state ?? {};

  const appearance = {
    theme: "stripe",
  };
  return (
    <div className="checkOutWrapper">
      <LogoHeader />
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
          <CourseCheckoutForm />
        </Elements>
      )}
    </div>
  );
};

export default CourseCheckOut;
