import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { getReferenceStyle } from "../../services/getReferenceStyle";
import { getAllLineSpacing } from "../../services/getAllLineSpacing";

const Requirements = ({ questionData }) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const [refrenceStyle, setRefrenceStyle] = useState([]);
  const [lineSpacing, setLineSpacing] = useState([]);

  const referenceStyleId = questionData?.selectReferenceStyle;
  const lineSpacingId = questionData?.selectLineSpacing;

  const refrencefillter = refrenceStyle.filter(
    (item) => item._id === referenceStyleId
  );
  const lineSpacingfillter = lineSpacing.filter(
    (item) => item._id === lineSpacingId
  );

  const getReference = async () => {
    const response = await getReferenceStyle();
    setRefrenceStyle(response.data);
  };
  const getLineSpacing = async () => {
    const response = await getAllLineSpacing();
    setLineSpacing(response.data);
  };

  useEffect(() => {
    getReference();
    getLineSpacing();
  }, []);

  return (
    <Accordion
      open={open}
      toggle={toggle}
      className="accordian-Wrapper content-card"
    >
      <AccordionItem>
        <AccordionHeader targetId="1" className="heading">
          Requirement
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <div className="d-flex justify-content-between">
            <span className="pe-3 greyTitle w-25 text-start">OrderId</span>
            <span className="lineSeprator"></span>
            <span className="ps-3 subTitle fw-bold text-end">
              {questionData?.displayId}
            </span>
          </div>
          <div className="d-flex  justify-content-between">
            <span className="pe-3 greyTitle w-25 text-start">Subject</span>
            <span className="lineSeprator"></span>
            <span className="ps-3 subTitle  text-end">
              {/* {questionData?.subjectId?.questionSubject} */}
              {questionData?.subjectId?.subSubjectName}
            </span>
          </div>
          <div className="d-flex  justify-content-between">
            <span className="pe-3 greyTitle w-25 text-start">
              Question Type
            </span>
            <span className="lineSeprator"></span>
            <span className="ps-3 subTitle text-end">
              {questionData?.subCategoryId?.name}
            </span>
          </div>
          {questionData?.numerOfWords !== 0 && questionData.subCategoryId?._id !== '6586d9a9dafb1f4a8f461274' && questionData.subCategoryId?._id !== '6586dfe7d2f7eeadeea4fafa' && (
            <div className="d-flex justify-content-between">
              {questionData?.numerOfWords && (
                <span className="pe-3 greyTitle w-25 text-start">
                  Words
                </span>
              )}
              {questionData?.numerOfWords && (
                <span className="lineSeprator"></span>
              )}
              <span className="ps-3 subTitle text-end">
                {questionData?.numerOfWords && (
                  <>
                    {questionData?.numerOfWords}
                  </>
                )}
              </span>
            </div>
          )}



          {refrencefillter[0]?.refStyle && (
            <div className="d-flex  justify-content-between">
              <span className="pe-3 greyTitle w-25 text-start">
                Reference Style
              </span>
              <span className="lineSeprator"></span>
              <span className="ps-3 subTitle text-end">
                {refrencefillter[0]?.refStyle}
              </span>
            </div>
          )}
          {lineSpacingfillter[0]?.lineSpacing && (
            <div className="d-flex  justify-content-between">
              <span className="pe-3 greyTitle w-25 text-start">
                Spacing
              </span>
              <span className="lineSeprator"></span>
              <span className="ps-3 subTitle text-end">
                {lineSpacingfillter[0]?.lineSpacing}
              </span>
            </div>
          )}
          {questionData?.deadlineHours && (
            <div className="d-flex  justify-content-between">
              <span className="pe-3 greyTitle w-25 text-start">
                Deadline
              </span>
              <span className="lineSeprator"></span>
              <span className="ps-3 subTitle text-end">
                {questionData?.deadlineHours} Hours
              </span>
            </div>
          )}
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default Requirements;
