import React from 'react';
import { useLocation } from 'react-router-dom';
import Nav from './Nav';
import Navbar from './Navbar';

const TopHead = () => {
    const location = useLocation();
    const path = location.pathname;

    const excludePaths = [
        "/auth", "/login", "/register", "/register/:referral",
        "/signin", "/signup", "/forgot-password", "/reset-password"
    ];

    const email = localStorage.getItem('email');
    const token = localStorage.getItem('token');

    const shouldRenderNav = !excludePaths.includes(path) && !(email && token);

    return (
        <div>
            {shouldRenderNav && <Nav />}
            {shouldRenderNav && <Navbar />}
        </div>
    );
};

export default TopHead;
