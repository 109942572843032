import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Nav from "../../Components/Nav";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import SiteReviews from "../../Components/site-reviews/SiteReviews";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import FamousUniversity from "../../Components/ExamPreparationCards/FamousUniversity";
import HotCoursesHelp from "../../Components/ExamPreparationCards/HotCoursesHelp";
import Certificate from "../../Components/ExamPreparationCards/Certificate";
import BonusSection from "../../Components/BonusSection";
import StudentStories from "../../Components/StudentStories";
import ExamReadMoreSection from "../../Components/ExamReadMoreSection";
import StudyNotesPage from "../../Components/ExamPreparationCards/StudyNotesPage";
import CommonBanner from "../../Components/ExamPreparationCards/CommonBanner";
import Page from "../Metadata/Page";

const SepcialStudyNote = () => {
  // Live DB Id
  const subCategoryId = "67b5821bfa3a63e9ddb920e3";
  const categoryId = "67b57f038a01c99610fc51b2";

  // Test DB Id
  // const subCategoryId = "677e0a5c159c38853391cd3e";
  // const categoryId = "677e07a9159c38853391c934";
  return (
    <>
      {/* <Nav />
      <Navbar /> */}
      <Page subCategoryId={subCategoryId} categoryId={categoryId} />
      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">
                Special Study Notes
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CommonBanner subCategoryId={subCategoryId} />
      <SiteReviews />
      <StudyNotesPage />
      <HotCoursesHelp />
      <FamousUniversity />
      <Certificate />
      <BonusSection subCategoryId={subCategoryId} />
      <ExamReadMoreSection subCategoryId={subCategoryId} />
      <StudentStories />
      <Footer />
      <ScrollTop />
    </>
  );
};

export default SepcialStudyNote;
