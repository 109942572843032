import React, { useEffect, useState } from "react";
import Header from "../../../Components/Header";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import BenefitsSection from "../../../Components/BenefitsSection";
import HelpSteps from "../../../Components/HelpSteps";
import BonusSection from "../../../Components/BonusSection";
import StudentStories from "../../../Components/StudentStories";
import ChatHelpExperts from "../../../Components/ChatHelpExperts";
import Footer from "../../../Components/Footer";
import { getSubcategoryWiseContent } from "../../../services/getSubcategoryWiseContent";
import Popup from "../../../Components/Popup/Popup";
import { Link, useNavigate } from "react-router-dom";
// import { getAllSubject } from "../../../services/getAllSubject";
import { getCategoryWiseSubcategory } from "../../../services/getCategoryWiseSubcategory";
import { getSubcategoryWisePrice } from "../../../services/getSubcategoryWisePrice";
import { getHoursByWords } from "../../../services/getHoursByWords";
import { getPriceByWords } from "../../../services/getPriceByWords";
import { createQuestionAssignmentHelp } from "../../../services/createQuestionAssignmentHelp";
import { updateAssignmentQuestion } from "../../../services/updateAssignmentQuestion";
import { getMaxMinWords } from "../../../services/getMaxMinWords";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";
import Navbar from "../../../Components/Navbar";
import Nav from "../../../Components/Nav";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import AssignmentList from "../../../Components/AssignmentList";
import Universities from "../../../Components/Universities";
import Milestonerewards from "../../../Components/Milestonerewards";
import Page from "../../Metadata/Page";
import ComWorldWide from "../../../Components/ComWorldWide";
import ComReadMoreSection from "../../../Components/ComReadMoreSection";
import { getAllsubSubject } from "../../../services/getAllSubSubject";

const PresentationWriting = () => {
  const subCategoryId = "6586d98fdafb1f4a8f46126f";
  const categoryId = "65524e633c005ef3b51907c9";
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getSubcategoryWiseContent(subCategoryId)
      .then((data) => {
        setPageData(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);
  const defaultValue = {
    fontSize: "No",
    fontStyle: "No",
    lineSpacing: "No",
    referenceStyle: "No",
    haveReferenceFile: "No",
    subCategory: subCategoryId,
    categoryId: "65524e633c005ef3b51907c9",
  };
  const [categories, setCategories] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [payload, setPayload] = useState(defaultValue);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [isQuestionSubmited, setIsQuestionSubmited] = useState(false);
  const [error, setError] = useState();
  const [isError, setIsError] = useState(false);
  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };
  const totalSteps = 4;

  const fetchSubjectData = async () => {
    // const response = await getAllSubject();
    const response = await getAllsubSubject();
    setFormData((prevstate) => ({ ...prevstate, subject: response.data }));
  };

  const fetchWords = async () => {
    const response = await getMaxMinWords(subCategoryId);
    setPayload({
      ...payload,
      noOfWords: response.data.minWords,
      max: response.data.maxWords,
      min: response.data.minWords,
    });
  };

  useEffect(() => {
    fetchData();
    fetchSubjectData();
    fetchCategories();
    fetchWords();
    // fetchHomeworkCategory();
  }, []);

  const fetchCategories = async () => {
    await getCategoryWiseSubcategory("65524e633c005ef3b51907c9")
      .then((data) => {
        setCategories(data.subCategory);
      })
      .catch((err) => console.log(err));
  };

  const fetchQuestionPricingData = async (id) => {
    const response = await getSubcategoryWisePrice(id);
    setPayload((prevstate) => ({
      ...prevstate,
      price: response?.price?.studentPrice,
    }));
  };

  const handleUploadFile = async (files, name) => {
    const uploaded = payload[name] ? [...payload[name]] : [];

    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    if (uploaded.length === 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    return uploaded;
  };

  const handleChange = async (event) => {
    if (event.target?.files && event.target?.files[0]) {
      const data = await handleUploadFile(
        Array.prototype.slice.call(event.target?.files),
        event.target.name
      );
      setPayload((prevstate) => ({
        ...prevstate,
        [event.target.name]: data,
      }));
    } else if (event.target.name === "noOfWords") {
      const { value } = event.target;
      if (value === "" || parseInt(value) === 0) {
        setIsError(true);
        setError((prevstate) => ({
          ...prevstate,
          [event.target.name]: "Number must be greater than 0",
        }));
        setPayload((prevstate) => ({
          ...prevstate,
          [event.target.name]: event.target.value,
        }));
      } else if (/^\d+$/.test(value) && parseInt(value) <= payload.max) {
        setPayload((prevstate) => ({
          ...prevstate,
          [event.target.name]: event.target.value,
        }));
        setIsError(false);
        setError((prevstate) => ({
          ...prevstate,
          [event.target.name]: "",
        }));
      }
    } else {
      setPayload((prevstate) => ({
        ...prevstate,
        [event.target.name]: event.target.value,
      }));
      if (event.target.value) {
        setIsError(false);
        setError((prevstate) => ({ ...prevstate, [event.target.name]: "" }));
      }
    }
  };

  const handleRemoveFile = (index, key) => {
    const payloadClone = payload;
    if (payloadClone[key]) {
      payloadClone[key]?.splice(index, 1);
    }
    setPayload({ ...payloadClone });
  };

  const getHours = async () => {
    const response = await getHoursByWords({
      subCategoryId: subCategoryId,
      words: payload.noOfWords ? Number(payload.noOfWords) : payload?.min,
    });
    if (response.isSuccess) {
      const hoursArray = response.hours?.reverse();
      setFormData((prevstate) => ({
        ...prevstate,
        hours: hoursArray,
      }));
    }
  };

  const getAssignmentPrice = async () => {
    const response = await getHoursByWords({
      subCategoryId: subCategoryId,
      words: payload.noOfWords ? Number(payload.noOfWords) : payload.min,
    });

    if (response.isSuccess) {
      const hoursData = response.hours?.reverse();
      setFormData((prevstate) => ({
        ...prevstate,
        hours: hoursData,
      }));
      let hours = payload.deadline;
      if (hoursData?.includes(parseInt(hours))) {
        hours = payload.deadline;
      } else {
        hours = hoursData[Math.ceil(hoursData?.length / 2) ?? 0];
      }
      setPayload({ ...payload, deadline: hours });

      const data = await getPriceByWords({
        subCategoryId: subCategoryId,
        words: payload.noOfWords ? Number(payload.noOfWords) : payload.min,
        hours: hours,
      });

      if (data.isSuccess) {
        setFormData((prevstate) => ({
          ...prevstate,
          estimatedPrice: data?.price?.estimatedPrice,
        }));
      }
      //   }
    }
  };

  useEffect(() => {
    let getData = () => {};
    if (payload.noOfWords) {
      getData = setTimeout(() => {
        getAssignmentPrice();
      }, 2000);
    }
    return () => clearTimeout(getData);
  }, [payload.deadline, payload.noOfWords]);

  const handlePostQuestion = async () => {
    const response = await createQuestionAssignmentHelp(payload);
    if (response.isSuccess) {
      if (localStorage.getItem("token")) {
        const updatedQuestion = await updateAssignmentQuestion(
          response.question._id,
          {}
        );
        if (updatedQuestion.isSuccess) {
          localStorage.removeItem("questionId");
          navigate(`/orderStatus/${response.question._id}`);
        }
      } else {
        setIsQuestionSubmited(true);
        localStorage.setItem("questionId", response.question._id);
      }
    }
  };
  useEffect(() => {
    if (payload.questionType) {
      fetchQuestionPricingData(payload.questionType);
    }
  }, [payload.questionType]);

  return (
    <>
      {/* <Header /> */}
      <Page subCategoryId={subCategoryId} categoryId={categoryId} />
      {/* <Nav />
      <Navbar /> */}
      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item">Assignment Help</li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">
                Presentation Writing Help
              </li>
            </ul>
          </div>
        </div>
      </div>
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection
        onSignUp={() => {
          setIsModelOpen(true);
          setIsQuestionSubmited(true);
        }}
      />
      <BenefitsSection
        subCategoryId={subCategoryId}
        setIsModelOpen={setIsModelOpen}
      />
      <AssignmentList subCategoryId={subCategoryId} setIsModelOpen={setIsModelOpen}/>
      <HelpSteps
        subCategoryId={subCategoryId}
        // stepsData={pageData?.helpStepData && pageData?.helpStepData[0]}
      />
      <Milestonerewards categoryId={categoryId} />
      <BonusSection
        subCategoryId={subCategoryId}
        setIsModelOpen={setIsModelOpen}
      />
      <Universities categoryId={categoryId} setIsModelOpen={setIsModelOpen}/>
      <StudentStories />
      <ChatHelpExperts />
      <ComWorldWide id={subCategoryId} />
      <ComReadMoreSection id={subCategoryId} />
      <Footer />
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "AssignmentHelp",
            type: "assignmentHelp",
            totalSteps: 4,
          }}
          subCategory={subCategoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
    </>
  );
};

export default PresentationWriting;
