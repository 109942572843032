import axiosInstance from "../axiosInterceptor";

export async function myCourses(pageNo, perPage) {
    const response = await axiosInstance.post(
        `student/myCourses`,
        {
            pageNo,
            perPage,
        }
    );
    const data = response?.data;
    return data;
}