import React, { useEffect, useState } from "react";
import Button from "../Buttons/Button";
import { Rating } from "react-simple-star-rating";
import { getCoursesRandomly } from "../../services/getCoursesRandomly";
import { Link } from "react-router-dom";

const SuggestedCourseCard = () => {
  const [courseSuggestedCourseData, setSuggestedCourseData] = useState([]);
  useEffect(() => {
    const fetchSuggestedCourseData = async () => {
      try {
        const response = await getCoursesRandomly();
        setSuggestedCourseData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSuggestedCourseData();
  }, []);

  return (
    <>
      <div className="stack-container pdf-comp-stack-container">
        <h4 className="mb-4">
          Suggested Courses based on the document that you are currently viewing
        </h4>
        {courseSuggestedCourseData?.map((course, index) => (
          <div key={index} className="suggestion-card mb-4">
            <div>
              <div className="uni-name">
                <p>
                  {course?.courseName?.length > 15
                    ? `${course?.courseName.substring(0, 15)}...`
                    : course?.courseName}{" "}
                  <span>
                    (
                    {course?.university?.universityName?.length > 15
                      ? `${course.university?.universityName.substring(
                          0,
                          15
                        )}...`
                      : course?.university?.universityName}
                    )
                  </span>
                </p>
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className="rating-stars">
                  <Rating
                    size={20}
                    initialValue={parseFloat(course?.ratings)}
                    readonly
                  />
                </div>
                <div className="purchase-text">
                  <i className="fa-solid fa-cart-shopping pe-2"></i>
                  <span>{course?.purchases} Purchases</span>
                </div>
              </div>
            </div>
            <div>
              <Link
                to={`/course/${course?.university?.universityUrl}/${course?.courseUrl}`}
              >
                <Button
                  varient="linear-gradient-primary"
                  className="px-3 py-2 fs-5"
                  text={"View"}
                />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SuggestedCourseCard;
