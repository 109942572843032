import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../Components/OrderStatusAndBilling/OrderHistory.scss";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import LogoHeader from "../../Components/OrderStatusAndBilling/LogoHeader";
import Requirements from "../../Components/OrderStatusAndBilling/Requirements";
import Remarks from "../../Components/OrderStatusAndBilling/Remarks";
import WhatsappSupport from "../../Components/OrderStatusAndBilling/WhatsappSupport";
import PaymentDetails from "../../Components/OrderStatusAndBilling/PaymentDetails";
import TutorEngagement from "../../Components/OrderStatusAndBilling/TutorEngagement";
import Solution from "../../Components/OrderStatusAndBilling/Solution";

const apiEndPoint = process.env.REACT_APP_API;
const DashboardOrd = () => {
  const [data, setData] = useState({});
  const [isAddressAvailable, setIsAddressAvailable] = useState(false);
  const { questionId } = useParams();
  const navigate = useNavigate();
  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      if (localStorage.getItem("serviceType") === "HomeworkHelp") {
        const response = await axios.get(
          `${apiEndPoint}student/homeworkHelp/getQuestionDetailByID/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response?.data;
        setData(data.question);
        setIsAddressAvailable(data.billingDetails);
      } else if (localStorage.getItem("serviceType") === "AssignmentHelp") {
        const response = await axios.get(
          `${apiEndPoint}student/assignmentHelp/getQuestionDetailByID/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response?.data;
        setData(data.question);
        setIsAddressAvailable(data.billingDetails);
      } else if (localStorage.getItem("serviceType") === "LiveSessionHelp") {
        const response = await axios.get(
          `${apiEndPoint}student/liveSessionHelp/getQuestionDetailByID/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response?.data;
        setData(data.question);
        setIsAddressAvailable(data.billingDetails);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [questionId]);

  return (
    <>
      <div className="wrapper">
        <div className="content-wrapper">
          <h4 className="title">Confirm & Pay</h4>
          <h6 className="subTitle pb-4">
            {data?.paymentSection
              ?.replace(/LiveSessionHelp/g, "Live Session Help")
              .replace(/AssignmentHelp/g, "Assignment Help")
              .replace(/HomeworkHelp/g, "Homework Help")}
          </h6>
          <Requirements questionData={data} className="mt-4" />
          <Remarks questionData={data} />
          <WhatsappSupport questionId={questionId} studentId={data.studentId} />
          <PaymentDetails
            questionData={data}
            fetchData={fetchData}
            disable={true}
            isAddressAvailable={isAddressAvailable}
          />
          <TutorEngagement disable={true} />
          <Solution disable={true} />
        </div>
      </div>
    </>
  );
};

export default DashboardOrd;
