import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getCourseNameByUniversitySlug } from "../../services/getCourseNameByUniversity";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

const ParticularCollege = () => {
    const { slug } = useParams();
    const [data, setData] = useState({});    
    const [courses, setCourses] = useState([]);
    const [filterLetter, setFilterLetter] = useState("All");
    const [searchQuery, setSearchQuery] = useState("");

    const fetchData = async (searchTerm = "") => {
        try {
            const response = await getCourseNameByUniversitySlug(slug, searchTerm); 
            if (response.isSuccess) {
                setData(response?.data);
                const flattenedCourses = response?.data.courses?.reduce((acc, item) => {
                    return [...acc, ...item.courses.map(course => ({
                        ...course,
                        alphabet: item.alphabet
                    }))];
                }, []);
                setCourses(flattenedCourses || []);
            } else {
                // toast.error(response.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch courses");
        }
    };

    useEffect(() => {
        fetchData();
    }, [slug]);

    const handleSearch = debounce((value) => {
        setSearchQuery(value);
        fetchData(value);
    }, 300);

    const filteredCourses = courses.filter(course => {
        const matchesLetter = filterLetter === "All" || course.courseName.startsWith(filterLetter);
        const matchesSearch = course.courseName.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesLetter && matchesSearch;
    });

    const groupedCourses = filteredCourses.reduce((acc, course) => {
        const letter = course.courseName[0].toUpperCase();
        if (!acc[letter]) {
            acc[letter] = [];
        }
        acc[letter].push(course);
        return acc;
    }, {});

    return (
        <div className="container">
            {courses.length > 0 && (
                <>
                    <div className="alphabetic-tabs mt--40 mb--40">
                        <div className="row d-flex justify-content-center alphabetic-tabs-div">
                            <h3 className="title">Major courses at {data.universityName}</h3>
                            <p className="description">Notes available for the following studies at {data.universityName}</p>
                            <ul className="d-flex mb-0 flex-wrap justify-content-center">
                                <li className={`${filterLetter === "All" ? "active" : ""}`}>
                                    <Link to="#" onClick={() => setFilterLetter("All")}>All</Link>
                                </li>
                                {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((letter) => (
                                    <li key={letter} className={`${filterLetter === letter ? "active" : ""}`}>
                                        <Link to="#" onClick={() => setFilterLetter(letter)}>{letter}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div>
                        <label className="fs-4 text-black text-start">Search for Courses</label>
                        <div className="title-search justify-content-center mt-2">
                            <div className="prac-paper-search mb-3">
                                <i className="feather-search fs-icon fs-2"></i>
                                <input
                                    type="text"
                                    placeholder={`Find your major at ${data.universityName}`}
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="all-courses">
                        {Object.keys(groupedCourses).length > 0 ? (
                            Object.entries(groupedCourses)
                                .sort(([a], [b]) => a.localeCompare(b))
                                .map(([letter, letterCourses]) => (
                                    <div key={letter} className="row all-alphabet">
                                        <h2 className="single-alphabet">{letter}</h2>
                                        {letterCourses.map((course, i) => (
                                            <div key={i} className="col-lg-6 col-md-6 col-12">
                                                <Link to={`/course/${course?.universityUrl}/${course.courseUrl}`} className="course-link text-decoration-none">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="university-name">{course.courseName}</p>
                                                        <p className="description p-0">1</p>
                                                    </div>
                                                    <hr className="my-4" />
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                ))
                        ) : (
                            <div className="row all-alphabet">
                                <h2 className="single-alphabet">{filterLetter}</h2>
                                <p className="description p-0">No courses found matching {filterLetter}.</p>
                            </div>
                        )}
                    </div>
                </>
            )}
            {!courses.length && (
                <div className="mt--40 mb--40">
                    <h3 className="title">No courses available</h3>
                    <p className="description">No courses are currently available for this university.</p>
                </div>
            )}
        </div>
    );
};

export default ParticularCollege;
