import React, { useCallback, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ExamPreparationCards.scss";
import { Link } from "react-router-dom";
import clg from "../../Assets/images/clg.png";
import { getAllFeaturedUniversity } from "../../services/getAllFeaturedUniversity";

const FamousUniversity = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
    },
  };

  const [search, setSearch] = useState("");
  const [storyData, setStoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const fetchData = useCallback(async (searchTerm) => {
    setIsLoading(true);

    try {
      const response = await getAllFeaturedUniversity(searchTerm);
      if (response?.isSuccess) {
        setStoryData(response.data || []);
      } else {
        setStoryData([]);
        console.error("API Error:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setStoryData([]);
    } finally {
      setIsLoading(false);
    }

  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);



  useEffect(() => {
    let timeoutId = null;

    if (search.length >= 3) {
      timeoutId = setTimeout(() => {
        fetchData(search);
      }, 300);
    } else if (search.length === 0) {
      fetchData();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [search, fetchData]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="student-stories-container prac-paper-container">
      <div className="title-search">
        <div className="mb-3">
          <h1 className="text-start">Famous University</h1>
          <p className="text-start">DoubtQ Offered Help to</p>
        </div>
        <div className="prac-paper-search mb-3">
          <i className="feather-search fs-icon fs-2"></i>
          <input
            type="text"
            placeholder="Find Course, University"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {isLoading ? (
        <p className="text-center mt-4">Loading...</p>
      ) : storyData?.length > 0 ? (
        <div className="carousel-container">
          <Carousel
            responsive={responsive}
            autoPlay={false}
            arrows={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
          // partialVisible={false}
          >
            {storyData?.map((e, index) => {
              return (
                <Link
                  className="fam-uni-text"
                  to={`/university/${e?.universityUrl}`}
                >
                  <div
                    key={index}
                    className="carousel story py-5 px-5 m-4 carousel-bg"
                  >
                    <div className="fam-uni-box">
                      <h2 className=" mt-4 mb-0 pt-3 text-center">
                        {e?.universityName?.length > 15
                          ? `${e.universityName.substring(0, 15)}...`
                          : e?.universityName}
                      </h2>
                      <div className="dec-lh text-center pb-3">
                        {e?.countryName?.length > 15
                          ? `${e.countryName.substring(0, 15)}...`
                          : e?.countryName}
                      </div>
                      <div>
                        <img src={clg} alt="college" className="w-100" />
                      </div>
                      <div className="university-container">
                        <i className="fa-solid fa-building-columns"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </Carousel>
          <div className="mt-4">
            <Link to="/university-list">
              <button className="rbt-btn btn-gradient btn-sm">
                View All Universities
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <p className="text-center mt-4">
          {search.length > 0 && search.length < 3
            ? "Please enter at least 3 characters to search"
            : "No results found"}
        </p>
      )}
    </div>
  );
};

export default FamousUniversity;
