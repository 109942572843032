import React, { useEffect, useState } from "react";
import "./PracticePapersBanner.scss";
import bannerImage from "../../Assets/images/background/icon-bg.png";
import { Col, Input, InputGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Renderer from "../Renderer";
import Button from "../Buttons/Button";
import { toast } from "react-toastify";
import { getSubcategoryWiseContent } from "../../services/getSubcategoryWiseContent";
import { getAllUniversity } from "../../services/getAllUniversity";
import { getAllCourseForStudent } from "../../services/getAllCourseForStudent";
import axiosInstance from "../../axiosInterceptor";
import { GoSearch } from "react-icons/go";
import ExamPreparationSearch from "../ExamPrepSearch";
import { Link } from "react-router-dom";

const CommonBanner = ({ subCategoryId = "" }) => {
  const [pageData, setPageData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const phoneInputRef = React.useRef();

  const initialFormState = {
    course: "",
    university: "",
    whatsappNumber: "",
    email: "",
    courseType: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [phoneValue, setPhoneValue] = useState("");

  const [setting, setSetting] = useState([]);
  const flatdiscontent = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    await getSubcategoryWiseContent(subCategoryId)
      .then((data) => {
        setPageData(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
    flatdiscontent();
  }, [subCategoryId]);

  useEffect(() => {
    if (pageData?.subCategoryData?.name) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        courseType: pageData.subCategoryData.name,
      }));
    }
  }, [pageData]);

  useEffect(() => {
    const fetchAllUniversityData = async () => {
      try {
        const response = await getAllUniversity();
        setRawData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllUniversityData();
  }, []);

  useEffect(() => {
    const fetchAllCourseForStudentData = async () => {
      try {
        const response = await getAllCourseForStudent();
        setCourseData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllCourseForStudentData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    try {
      await axiosInstance.post("/student/courseEnquiry/createCourseEnquiry", {
        courseName: formData.course,
        universityName: formData.university,
        wpNumber: formData.whatsappNumber,
        email: formData.email,
        courseType: formData.courseType,
      });

      setFormData(initialFormState);
      setSelectedCourse(null);
      setSelectedUniversity(null);
      setPhoneValue("");
      if (phoneInputRef.current) {
        phoneInputRef.current.state.value = "";
      }

      toast.success("Form Submitted Successfully");
    } catch (error) {
      toast.error("Error submitting form");
      console.error("Error submitting form:", error);
    }

    setIsSubmitting(false);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedUniversity(selectedOption);
    setFormData({
      ...formData,
      university: selectedOption ? selectedOption.value : "",
    });
  };

  const handleCourseSelectChange = (selectedOption) => {
    setSelectedCourse(selectedOption);
    setFormData({
      ...formData,
      course: selectedOption ? selectedOption.value : "",
    });
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
    setFormData({ ...formData, whatsappNumber: value });
  };

  const university = [];
  rawData.map((item) =>
    item?.universityNames?.map((item) => university.push(item))
  );

  const isFormValid =
    formData.course &&
    formData.university &&
    formData.whatsappNumber &&
    formData.email;

  return (
    <>
      <div className="banner-main-wrapper">
        <img className="banner-image-pp" src={bannerImage} alt="banner" />
        <div className="container">
          <div className="row inside-container-pp">
            <div className="col-lg-6">
              <div className="banner-content-pp">
                <div className="banner-content-title-wrapper pt-5">
                  <div className="title title-color">
                    <Renderer content={pageData?.bannerData?.title} />
                    <span className="gradient-title">
                      {pageData?.bannerData?.titleHighlight}
                    </span>
                  </div>
                </div>
                <div className="banner-content-subtitle subtitle-color">
                  <span>
                    <Renderer content={pageData?.bannerData?.description} />
                  </span>
                </div>
                <div className="container pt-5">
                  <div className="rbt-search">
                    <div className="row">
                      <div className="col-lg-12">
                        <div style={{ position: "relative", width: "100%" }}>
                          <GoSearch
                            size={16}
                            style={{
                              position: "absolute",
                              left: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: 10,
                              color: "#D444ED",
                            }}
                          />
                          <ExamPreparationSearch />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 col-md-12 prac-paper-wrapper py-5">
              <div className="right-form">
                <div className="mb-4">
                  <h3>Get Your {pageData?.subCategoryData?.name} Help</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={6}
                      xl={6}
                      className="text-start all-input mb-4"
                    >
                      <Label>
                        <b>
                          <small className="fs-4">Course Name</small>
                        </b>
                      </Label>
                      <Select
                        id="course"
                        name="course"
                        value={selectedCourse}
                        onChange={handleCourseSelectChange}
                        options={
                          Array.isArray(courseData)
                            ? courseData.map((item) => ({
                              value: item.courseName,
                              label: item.courseName,
                            }))
                            : []
                        }
                        isClearable
                        placeholder="Select a course"
                        classNamePrefix="react-select"
                        isSearchable
                        styles={{
                          singleValue: (provided) => ({
                            ...provided,
                            paddingLeft: "10px",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            paddingLeft: "10px",
                            fontSize: "1.3rem",
                          }),
                        }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={6}
                      xl={6}
                      className="text-start all-input mb-4"
                    >
                      <Label>
                        <b>
                          <small className="fs-4">University</small>
                        </b>
                      </Label>
                      <Select
                        id="university"
                        name="university"
                        value={selectedUniversity}
                        onChange={handleSelectChange}
                        options={
                          Array.isArray(rawData)
                            ? rawData.map((item) => ({
                              value: item.universityName,
                              label: item.universityName || "No name",
                            }))
                            : []
                        }
                        isClearable
                        placeholder="Select a university"
                        classNamePrefix="react-select"
                        isSearchable
                        styles={{
                          singleValue: (provided) => ({
                            ...provided,
                            paddingLeft: "10px",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            paddingLeft: "10px",
                            fontSize: "1.3rem",
                          }),
                        }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={6}
                      xl={6}
                      className="text-start"
                    >
                      <Label>
                        <b>
                          <small className="fs-4 text-start">
                            WhatsApp Number
                          </small>
                        </b>
                      </Label>
                      <InputGroup className="mb-3">
                        <PhoneInput
                          country={"ca"}
                          className=""
                          inputClass="fs-3"
                          ref={phoneInputRef}
                          inputProps={{
                            name: "whatsappNumber",
                            required: true,
                            className: "fs-3 p-2 select-prac-input c-padding",
                          }}
                          value={phoneValue}
                          onChange={handlePhoneChange}
                        />
                      </InputGroup>
                    </Col>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={6}
                      xl={6}
                      className="text-start"
                    >
                      <Label>
                        <b>
                          <small className="fs-4">Email</small>
                        </b>
                      </Label>
                      <InputGroup className="overflow-hidden mb-3">
                        <Input
                          placeholder="Enter email"
                          className="fs-3 px-4 py-2 select-prac-input"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  {errorMessage && (
                    <Row className="justify-content-center">
                      <Col xs={12}>
                        <p className="error-msg">{errorMessage}</p>
                      </Col>
                    </Row>
                  )}
                  <div className="chat-with-us mt-4">
                    <div className="chat-with-us-btn">
                      <Link
                        to={`https://wa.me/${setting?.contactNumber}`}
                        target="_blank"
                        className="text-decoration-none"
                      >
                        <i
                          className="fa-brands fa-whatsapp fs-1"
                          style={{ color: "#fff", }}
                        ></i>
                        <span style={{ color: "#fff" }}>
                          Chat with us
                        </span>
                      </Link>
                    </div>
                    <Button
                      className={`${!isFormValid
                        ? "right-form-search-btn2"
                        : "right-form-search-btn"
                        }`}
                      disabled={!isFormValid || isSubmitting}
                      type="submit"
                      varient="linear-gradient-primary"
                      text="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonBanner;
