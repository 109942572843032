import axiosInstance from "../axiosInterceptor";

export async function getCourseByUniversitySlugFilter(page = 1, slug, filter) {
    try {
        const payload = {
            pageNo: page,
            perPage: 10,
            ...filter,
        };

        const response = await axiosInstance.post(
            `admin/course/getCourseByUniversitySlugFilter/${slug}`,
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error fetching courses:", error);
        throw error;
    }
}

