import React, { useState, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Navbar from "../Components/Navbar";
import bannerimage from "../Assets/images/background/icon-bg.png";
import { createWorker } from "tesseract.js";
import RecentlyAskedQue from "../Components/RecentlyAskedQue"
import Footer from "../Components/Footer";
// import Button from "../Components/Buttons/Button";

const Demo = () => {
    const [text, setText] = useState("");
    const [modal, setModal] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const toggleModal = () => setModal(!modal);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setUploadedImage(e.target.result);
                toggleModal();
            };
            reader.readAsDataURL(file);
        }
    };

    const closeBtn = (
        <button
            className="view-popup-close-btn"
            onClick={toggleModal}
            style={{ zIndex: 1 }}
            type="button"
        >
            X
        </button>
    );

    const handleTranscript = useCallback(async () => {
        if (uploadedImage) {
            setIsLoading(true);
            try {
                // Create worker each time to avoid cloning issues
                const worker = await createWorker();
                await worker.load();
                await worker.loadLanguage('eng');
                await worker.initialize('eng');

                const { data: { text: extractedText } } = await worker.recognize(uploadedImage);
                setText(extractedText);

                await worker.terminate();
            } catch (error) {
                console.error("Error extracting text:", error);
                alert("Failed to extract text from image");
            } finally {
                setIsLoading(false);
                toggleModal();
            }
        }
    }, [uploadedImage]);

    const handleReset = () => {
        setUploadedImage(null);
        setText("");
        // toggleModal();
    };

    return (
        <>
            {/* <Navbar /> */}
            <div className="banner-main-wrapper">
                <img className="banner-image" src={bannerimage} alt="Banner" />
                <div className="banner-content">
                    <div className="container pt-5">
                        <div className="rbt-search">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <label className="text-weight fs-3">Search for Question</label>
                                        <div className="text-center d-flex align-items-center gap-3">
                                            <div onClick={handleReset}>
                                                <button className="fs-4 bg-white border-0 px-3 py-2">Reset</button>
                                            </div>
                                            <label htmlFor="uploadInput" className="custom-upload-label">
                                                <span className="upload-icon me-2">
                                                    <i className="fa-regular fa-image" style={{ color: "#ff00ea" }}></i>
                                                </span>
                                                Upload Image
                                            </label>
                                            <input
                                                type="file"
                                                id="uploadInput"
                                                className="d-none"
                                                onChange={handleImageUpload}
                                                accept="image/*"
                                            />

                                        </div>
                                    </div>
                                    <form action="#" className="mt-2">
                                        <div className="inside-form">
                                            <div className="input-wrapper">
                                                <textarea
                                                    type="text"
                                                    placeholder="Type your question here e.g: Define ohm's law..."
                                                    className="search-ip py-3 px-4 fs-4"
                                                    rows={5}
                                                    value={text}
                                                    onChange={(e) => setText(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <RecentlyAskedQue />
            <Footer />

            <Modal isOpen={modal} toggle={toggleModal} size="xl">
                <ModalHeader close={closeBtn} toggle={toggleModal}><h3>Uploaded Image:</h3></ModalHeader>
                <ModalBody className="overflow-scroll position-relative" style={{ height: "calc(100vh - 200px)" }}>
                    {uploadedImage && <img src={uploadedImage} alt="Uploaded" className="img-fluid" />}

                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button
                        color="primary"
                        className="rbt-btn btn-gradient btn-sm border-0"
                        onClick={handleTranscript}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Extracting...' : 'Transcript'}
                    </Button>
                </ModalFooter>
                {isLoading && (
                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center text-white bg-black bg-opacity-75">
                        <h1>Loading...</h1>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default Demo;