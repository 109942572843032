import React from "react";

const CourseOverView = ({ overviewData }) => {
  return (
    <>
      <div
        className="p-course-data"
        dangerouslySetInnerHTML={{ __html: overviewData }}
      />
    </>
  );
};

export default CourseOverView;
