import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import "./AllNotesCards.scss";
import Button from "../../Components/Buttons/Button";
import pdf from "../../Assets/images/New/que-pdf.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoEye } from "react-icons/go";
import { Rating } from "react-simple-star-rating";
import { BsCart } from "react-icons/bs";
import ViewPopup from "../ViewPopup";
import { getCourseByUniversitySlugFilter } from "../../services/getCourseByUniversityFilter";
import { toast } from "react-toastify";
import { dateFormat } from "../dateFormat";

const AllNotesCards = ({ appliedFilter }) => {
  const { slug } = useParams();
  const navigator = useNavigate();
  const [data, setData] = useState([]);

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const fetchData = async (pageNum) => {
    try {
      const response = await getCourseByUniversitySlugFilter(
        pageNum,
        slug,
        appliedFilter
      );
      if (response.isSuccess) {
        setData(response.data);
        setTotalPages(response.totalPages);
        setCurrentPage(pageNum);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch courses");
    }
  };

  const handleViewClick = (course) => {
    const courseWithUni = {
      ...course,
      university: {
        universityName: data?.universityName,
        universityUrl: data?.universityUrl,
        _id: data?.universityId,
        countryName: data?.countryName,
      },
    };
    setSelectedCourse(courseWithUni);
    toggle();
  };

  const handleNavigate = (course) => {
    const courseWithUni = {
      ...course,
      university: {
        universityName: data?.universityName,
        universityUrl: data?.universityUrl,
        _id: data?.universityId,
        countryName: data?.countryName,
      },
    };

    navigator("/paynow", {
      state: courseWithUni,
    });
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, slug, appliedFilter]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between in-mobile">
        <h3>All {data?.courseData?.length} Results</h3>
        <p className="que-course-sub2">
          Notes available for the following studies at {data?.universityName}
        </p>
      </div>

      <div>
        <Container className="w-100 dashboard dash-mobile question-bank-history bg-white mt-0">
          {data?.courseData?.length > 0 ? (
            data?.courseData?.map((course) => (
              <div className="question-bank-papers mb-5" key={course?._id}>
                <Row className="py-5">
                  <Col md="3">
                    <div className="d-flex flex-column align-items-center">
                      <img src={pdf} alt="pdf" className="w-50" />
                      <div className="mt-2">
                        <Rating
                          size={20}
                          initialValue={parseFloat(course?.ratings)}
                          readonly
                        />
                      </div>
                      <h5 className="mt-2 que-bank-text">
                        <BsCart className="mx-2" />
                        {course?.purchases || 0} purchases
                      </h5>
                    </div>
                    <h6 className="que-bank-text">
                      {course?.views || 0} views
                    </h6>
                  </Col>
                  <Col md="9">
                    <div className="text-start que-course-right">
                      <p className="que-course-name">
                        <Link
                          to={`/course/${course?.universityUrl}/${course?.courseUrl}`}
                          className="text-decoration-none"
                        >
                          <span className="fs-2 fw-bold me-5">
                            {course?.courseName}
                          </span>
                        </Link>

                        {course?.university && `(${course?.university})`} -{" "}
                        {course?.tempCourse?.courseType}
                      </p>
                      <p className="que-course-sub1">

                        {course?.title}
                      </p>
                      <p className="que-bank-text que-course-sub2">
                        {course?.subTitle}
                      </p>
                      <hr />
                      <p className="que-course-name mb-2">
                        <span className="fs-2 fw-bold me-5">
                          $ {course?.tempCourse?.coursePrice}
                          {/* {course?.type === "Special Study Notes"
                            ? course?.studyNotes?.price
                            : course?.type === "Practice Papers"
                            ? course?.practicePapers?.price
                            : course?.type === "Question Bank"
                            ? course?.questionBank?.price
                            : "N/A"} */}
                        </span>
                        {course?.updatedAt && dateFormat(course?.updatedAt)}
                      </p>
                      <div className="d-flex align-items-center que-course-btn">
                        <h4
                          onClick={() => handleViewClick(course)}
                          className="cursor-pointer mobile-none"
                        >
                          <GoEye /> view
                        </h4>
                        <Button
                          varient="linear-gradient-primary"
                          className="p-2 px-4 mx-3"
                          text="Buy Now"
                          onClick={() => {
                            handleNavigate(course);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <p>No courses found for this university.</p>
          )}

          {totalPages > 1 && (
            <Row className="float-end">
              <Col className="text-center">
                <Pagination>
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => handlePageClick(currentPage - 1)}
                    />
                  </PaginationItem>

                  {Array.from(
                    { length: totalPages },
                    (_, index) => index + 1
                  ).map((page) => {
                    if (
                      page === 1 ||
                      page === totalPages ||
                      Math.abs(currentPage - page) <= 1
                    ) {
                      return (
                        <PaginationItem
                          key={page}
                          active={page === currentPage}
                        >
                          <PaginationLink onClick={() => handlePageClick(page)}>
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }
                    if (
                      (page === currentPage - 2 || page === currentPage + 2) &&
                      totalPages > 5
                    ) {
                      return (
                        <PaginationItem key={`ellipsis-${page}`} disabled>
                          <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                      );
                    }
                    return null;
                  })}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => handlePageClick(currentPage + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <style>
        {`
                @media (max-width: 600px) {
                    .que-course-btn h4 {
                        display: none;
                    }
                }
            `}
      </style>

      {isModelOpen ? (
        // <CommonForm isOpen={isModelOpen} toggle={toggle} typeData={typeData} />
        <ViewPopup
          isOpen={isModelOpen}
          toggle={toggle}
          course={selectedCourse}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AllNotesCards;
