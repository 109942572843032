import React, { useEffect, useState } from "react";
// import Nav from "../../Components/Nav";
// import Navbar from "../../Components/Navbar";
import { Link, useParams } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import AllSubjectsBanner from "../../Components/AllSubjectsBanner";
// import RecentlyAskedQue from "../../Components/RecentlyAskedQue";
import ChatHelpExperts from "../../Components/ChatHelpExperts";
import StudentStories from "../../Components/StudentStories";
import Footer from "../../Components/Footer";
import ScrollTop from "../../Components/Buttons/ScrollTop";
// import Hashtags from "../../Components/Hashtags";
import CommonForm from "../HomePage/Form/CommonForm";
import { getSubSubjectBySlug } from "../../services/getSubSubjectBySlug";
import { toast } from "react-toastify";
import AllSubjectsReadMoresec from "../../Components/AllSubjectsReadMoresec";
import RecentlyAskedQueSubSubject from "../../Components/RecentlyAskedQueSubSubject";
import { Helmet } from "react-helmet";
import SubHashtags from "../../Components/SubHashtags";

const SubjectSubcategory = () => {
  const subCategoryId = "655659b9b9cbef8acade1d7c";
  const { slug, subslug } = useParams();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const toggle = () => setIsModelOpen(!isModelOpen);
  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      const response = await getSubSubjectBySlug(slug, subslug);
      if (response.isSuccess) {
        setData(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchData();
  }, [slug, subslug]);

  return (
    <>
      {/* <Nav />
      <Navbar /> */}
      <Helmet>
        <title>{`Expert ${data?.subSubjectName} Assignment & Homework Help | Get Personalized Academic Success with DoubtQ`}</title>

        <meta
          name="description"
          content={`Get professional assistance with your ${data?.subSubjectName} assignments and homework from top tutors. Receive expert guidance, timely solutions, and high-quality support to help you excel in your studies!`}
        />

        <meta
          property="og:title"
          content={`${data?.subSubjectName} Page - DoubtQ`}
        />

        <meta
          property="og:description"
          content={`Get professional assistance with your ${data?.subSubjectName} assignments and homework from top tutors. Receive expert guidance, timely solutions, and high-quality support to help you excel in your studies!`}
        />
      </Helmet>

      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">
                {data?.subSubjectName}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <AllSubjectsBanner setIsModelOpen={setIsModelOpen} data={data} />
      <RecentlyAskedQueSubSubject subjectId={data?._id} />
      <div className="container" style={{ paddingTop: "70px" }}>
        <h2 className="title">
          Popular Subjects for{" "}
          {data?.subjectId?.questionSubject?.replace(" Homework Help", "")}
        </h2>
        <p className="description">
          You can get the best rated step-by-step problem explanation from
          65000+ expert tutors by ordering DoubtQ{" "}
          {data?.subjectId?.questionSubject}
        </p>
        {/* <Hashtags /> */}
        <SubHashtags slug={slug}/>
      </div>
      <StudentStories />
      <ChatHelpExperts />
      {data?.description && <AllSubjectsReadMoresec data={data} />}
      <Footer />
      {isModelOpen && (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "AssignmentHelp",
            type: "assignmentHelp",
            totalSteps: 4,
          }}
          subCategory={subCategoryId}
        />
      )}
      <ScrollTop />
    </>
  );
};

export default SubjectSubcategory;
