import React, { useEffect, useState } from "react";
import "./BonusOffersSection.scss";
import Button from "./Buttons/Button";
import arrowRight from "../Assets/icons/arrowRight.svg";
import tag from "../Assets/icons/tag.svg";
import { getAllBonusOffer } from "../services/getAllBonusOffer";
import { Row, Col } from "reactstrap";

const BonusOffersSection = ({
  id = "",
  setIsModelOpen = () => {},
  btnDisabled = false,
}) => {
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getAllBonusOffer()
      .then((data) => {
        setPageData(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bonussection">
      <div className="title">Bonus Offers</div>
      <Row className="my-4">
        {pageData?.map((item, index) => (
          <Col sm={12} md={6} lg={4} key={index} className="mb-4">
            <BonusOffersCard
              className={index % 2 ? "blue" : "violet"}
              details={item}
            />
          </Col>
        ))}
      </Row>
      {!btnDisabled ? (
        <Button
          className="mt-4"
          text="Place Your Help"
          varient="primary"
          onClick={() => setIsModelOpen(true)}
        ></Button>
      ) : (
        <></>
      )}
    </div>
  );
};

const BonusOffersCard = ({ details, className = "" }) => {
  return (
    <div className={`card ${className}`}>
      <div className="label">{details.name}</div>
      <div className="discount-wrapper">
        <div className="bonus">BONUS</div>
        <div className="discount">{details.percentage}%</div>
        <div className="off">OFF</div>
        <div className="arrow">
          <img src={arrowRight}></img>
        </div>
      </div>
      <div className="message">{details.description}</div>
      <img className="tag1" src={tag}></img>
      <img className="tag2" src={tag}></img>
    </div>
  );
};
export default BonusOffersSection;
