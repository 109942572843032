import React, { useState } from "react";
import "./ReadMoreSection.scss";

const AllSubjectsReadMoresec = ({ data }) => {

  const [isExpanded, setIsExpanded] = useState(false);

  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const handleReadMoreToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const description = data?.description || "";
  const displayedText = isExpanded
    ? description
    : truncateText(description, 100);

  return (
    <div className="read-more-section-container">
      <div className="read-more-section-description">
        <p dangerouslySetInnerHTML={{ __html: displayedText }} />
        {description.split(" ").length > 100 && (
          <button
            onClick={handleReadMoreToggle}
            className="rbt-btn btn-gradient btn-sm mt-4"
          >
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        )}
      </div>
    </div>
  );
};

export default AllSubjectsReadMoresec;
