import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { scroller } from "react-scroll";
// import Nav from "../../Components/Nav";
// import Navbar from "../../Components/Navbar";
import "../../Components/Course/ParticularCourse.scss";
import CourseOverView from "../../Components/Course/CourseOverView";
import CourseSpStudyNotes from "../../Components/Course/CourseSpStudyNotes";
import CoursePracPaper from "../../Components/Course/CoursePracPaper";
import CourseQuestionBank from "../../Components/Course/CourseQuestionBank";
// import CourseCombinedPackage from "../../Components/Course/CourseCombinedPackage";
import CourseReview from "../../Components/Course/CourseReview";
import CourseFaq from "../../Components/Course/CourseFaq";
import CourseBanner from "../../Components/Course/CourseBanner";
import CourseBenefits from "../../Components/Course/CourseBenefits";
import Footer from "../../Components/Footer";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import { getCourseBySlug } from "../../services/getCourseBySlug";
import { GoChevronRight } from "react-icons/go";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const ParticularCourse = () => {
  const location = useLocation();
  const state = location.state;
  const [activeTab, setActiveTab] = useState("1");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 425);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { universitySlug, courseSlug } = useParams();
  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      const response = await getCourseBySlug(universitySlug, courseSlug);
      if (response.isSuccess) {
        setData(response?.data);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch courses");
    }
  };

  useEffect(() => {
    if (state?.id) {
      handleTabClick(state.id);
    } else {
      handleTabClick("1");
    }
    fetchData();
  }, [universitySlug, courseSlug]);

  const handleTabClick = (id) => {
    setActiveTab(id);
    if (isMobile) {
      scroller.scrollTo("all-alphabet", {
        duration: 300,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -80,
      });
    }
  };

  /* 
  ++++++++++++++++++++++++++++++++++NOTE++++++++++++++++++++++++++++++++++++++++
  Whenever you change the id of the tabs, make sure to change the id in every components of 
  Special Study Notes, Practice Papers, Question Bank  as well.......
  */
  const tabs = [
    { id: "1", label: "Overview" },
    { id: "2", label: "Special Study Notes" },
    { id: "3", label: "Practice Papers" },
    { id: "4", label: "Question Bank" },
    // { "id": "5", "label": "Combined Package" },
    { id: "5", label: "Reviews" },
    { id: "6", label: "FAQ's" },
  ];

  const activeTabLabel = tabs.find((tab) => tab.id === activeTab)?.label || "";

  return (
    <>
      {/* <Nav />
      <Navbar /> */}
      <Helmet>
        <title>{`Get Certified in ${data?.courseName} | Expert-Led Online Course on DoubtQ`}</title>
        <meta
          name="description"
          content={`Enhance your knowledge and skills in ${data?.courseName} with expert-led courses on DoubtQ. Get comprehensive study materials, personalized guidance, and hands-on learning to achieve academic excellence!`}
        />
        <meta
          property="og:title"
          content={`${data?.courseName} Page - DoubtQ`}
        />
        <meta
          property="og:description"
          content={`Enhance your knowledge and skills in ${data?.courseName} with expert-led courses on DoubtQ. Get comprehensive study materials, personalized guidance, and hands-on learning to achieve academic excellence!`}
        />
      </Helmet>
      <CourseBanner data={data} />
      <div className="container my-5">
        <div className="subject-tabs-div mb-5">
          <div className="row border">
            <div className="col-lg-3 col-md-4 px-0">
              <ul className="ft-link nav-tabs">
                {tabs.map((tab) => (
                  <li
                    key={tab.id}
                    className={`nav-item ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                    onClick={() => handleTabClick(tab.id)}
                  >
                    <Link to="#" className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-3">
                        {tab.label}
                      </div>
                      <i>
                        <GoChevronRight
                          className="visibility-hover"
                          size={25}
                        />
                      </i>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="row all-alphabet ps-4 pt-4">
                <h2 className="p-0">{activeTabLabel}</h2>
                {activeTab === "1" && (
                  <CourseOverView overviewData={data?.overview} />
                )}
                {activeTab === "2" && (
                  <CourseSpStudyNotes
                    Link={data?.studyNotes}
                    course={data}
                    id={"spsn"}
                  />
                )}
                {activeTab === "3" && (
                  <CoursePracPaper
                    Link={data?.practicePapers}
                    course={data}
                    id={"pp"}
                  />
                )}
                {activeTab === "4" && (
                  <CourseQuestionBank
                    Link={data?.questionBank}
                    course={data}
                    id={"qb"}
                  />
                )}
                {/* {activeTab === "5" && (
                                    <CourseCombinedPackage Link={data?.combinedPackage} />
                                )} */}
                {activeTab === "5" && (
                  <CourseReview
                    courseID={data?._id}
                    avgRatings={data?.ratings}
                  />
                )}
                {activeTab === "6" && <CourseFaq />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CourseBenefits />
      <Footer />
      <ScrollTop />
    </>
  );
};

export default ParticularCourse;
