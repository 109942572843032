import axiosInstance from "../axiosInterceptor";

export const getAllCourseForStudentDb = async (page = 1) => {
    try {
        const response = await axiosInstance.get(`/admin/course/getAllCourseForStudentDb`, {
            params: {  
                pageNo: page,
                perPage: 5
            }
        });
        return response?.data;
    }
    catch (error) {
        console.error(error);
        return {
            isSuccess: false,
            message: "Failed to fetch courses",
            data: []
        };
    }
}