import axiosInstance from "../axiosInterceptor";

export async function getCourseByUniversitySlug(page = 1, slug) {

    const response = await axiosInstance.get(`admin/course/getCourseByUniversitySlug/${slug}`,
        {
            perPage: 10,
            pageNo: page,
        });
    const data = response?.data;
    return data;
}