import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ExamPreparationCards.scss";
import { getExamPrepDoc } from "../../services/getFreeDocumentsWithoutPagination";
import { Link } from "react-router-dom";
import File from "../../Assets/images/fileImg.png";
import { ImageLink } from "../../constants";

const Formatting = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
    },
  };

  const [examPrepData, setExamPrepData] = useState([]);
  const fetchData = async () => {
    await getExamPrepDoc()
      .then((data) => {
        setExamPrepData(data.data.slice(0, 5));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {examPrepData.length > 0 && (
        <div className="student-stories-container prac-paper-container">
          <div className="mb-3 text-center">
            <h2 className="title mb-2">Get Look Over The Formatting Style</h2>
          </div>
          <div className="carousel-container">
            <Carousel
              responsive={responsive}
              autoPlay={false}
              arrows={true}
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              // partialVisible={false}
            >
              {examPrepData.map((e, index) => {
                return (
                  <div key={index} className="carousel story py-5 px-5 m-4">
                    <Link
                      className="text-decoration-none"
                      to={ImageLink(e?.documentFile)}
                      target="_blank"
                    >
                      <div className="carousel-img-container text-center mb-5">
                        <img className="carousel-img" src={File} alt="file" />
                      </div>
                      <div className="subtitle-lh mt-2">{e.documentName}</div>
                      <div>
                        <button className="dwn-btn">Download</button>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
};

export default Formatting;
