import axiosInstance from "../axiosInterceptor";

export async function getAllFeaturedUniversity(search) {
    const response = await axiosInstance.get(`/admin/universities/getAllFeaturedUniversity`,
        {
            params: {
                search: search
            }
        }
    );
    const data = response?.data;
    return data;
}