import React, { useState, useEffect } from "react";
import "../../Pages/Staticpages/Static.scss";
import { Collapse, Button, CardBody, CardHeader, Container } from "reactstrap";
import { getCourseFaqsById } from "../../services/getCourseFaqsById";
import { toast } from "react-toastify";

const CourseFaq = () => {

    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const [accordionData, setAccordionData] = useState([]);

    const fetchFaqs = async () => {
        try {
            const response = await getCourseFaqsById();
            if (response.isSuccess) {
                setAccordionData(response?.data?.faqs || []); 
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch FAQs");
        }
    };

    useEffect(() => {
        fetchFaqs();
    }, []);

    const toggleAccordion = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <Container fluid className="ps-0">
            <main className="rbt-main-wrapper questionAnswerjustify">
                <div className="rbt-accordion-area accordion-style-1 rbt-section-gap">
                    <div className="container ps-0">
                        <div className="rbt-accordion-style accordion">
                            <div className="rbt-accordion-style rbt-accordion-04 accordion">
                                <div className="accordion" id="accordionExamplec3">
                                    {accordionData?.map((item, index) => (
                                        <div className="acc-border" key={index}>
                                            <CardHeader id={`heading${index}`}>
                                                <Button
                                                    className={`accordion-button acc-btn ${openAccordionIndex === index ? "" : "collapsed"}`}
                                                    color="link"
                                                    onClick={() => toggleAccordion(index)} 
                                                    aria-expanded={openAccordionIndex === index ? "true" : "false"}
                                                    aria-controls={`collapseThree${index}`}
                                                >
                                                    {item.question}
                                                </Button>
                                            </CardHeader>
                                            <Collapse
                                                isOpen={openAccordionIndex === index}
                                                aria-labelledby={`heading${index}`}
                                                data-parent="#accordionExamplec3"
                                            >
                                                <CardBody className="card-acc acc-content">
                                                    {item.answer}
                                                </CardBody>
                                            </Collapse>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Container>
    );
};

export default CourseFaq;
