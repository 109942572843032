import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./ParticularCourse.scss";
import DashDocPreview from "./DashDocPreview";
import { ImageLink } from "../../constants";
import Button from "../Buttons/Button";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const DashQuestionBank = ({ Link, course, id }) => {
  const navigator = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const isPaid = false;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const courseWithType = {
    ...course,
    tempCourse: {
      courseType: "Question Bank",
      coursePrice: Link?.price,
      courseFilePath: Link?.filePath,
      courseTypeId: Link?._id,
    },
  };

  return (
    <div className="course-study-note">
      {Link?.filePath ? (
        <>
          <p className="page-count">Preview 1 out of 20 Pages</p>
          <div className="row">
            <div className="col-lg-12">
              <div className="pdf-preview-container">
                {isPaid ? (
                  <div className="pdf-scrollable">
                    <Document
                      file={ImageLink(Link.filePath)}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ))}
                    </Document>
                  </div>
                ) : (
                  <div className="pdf-preview">
                    <Document
                      file={ImageLink(Link.filePath)}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <div className="pdf-page-wrapper">
                        <Page pageNumber={1} />
                      </div>

                      <div className="pdf-page-wrapper">
                        <div className="blurred">
                          <Page pageNumber={2} />
                        </div>
                        <div className="blur-overlay-pdf">
                          <div className="overlay-content">
                            <h1>Export Study Material</h1>
                            <h1>${Link?.price}</h1>
                            <p>
                              This course examines number of theories of
                              personality,
                            </p>
                            <Button
                              type="submit"
                              text="Buy Now"
                              varient="linear-gradient-primary"
                              className="mt-3 mb-3"
                              onClick={() => {
                                navigator("/dashboard/paynow", {
                                  state: { ...course, price: Link?.price || 0 },
                                });
                              }}
                            >
                              Buy Now
                            </Button>
                            <p className="mb-4">
                              All Rights Reserved to DoubtQ !
                            </p>

                            <div className="overlay-content-list">
                              <div className="overlay-content-item">
                                <GiCheckMark className="overlay-content-item-icon" />
                                <p>100% satisfaction guarantee</p>
                              </div>
                              <div className="overlay-content-item">
                                <GiCheckMark className="overlay-content-item-icon" />
                                <p>Accurate Study Notes</p>
                              </div>
                              <div className="overlay-content-item">
                                <GiCheckMark className="overlay-content-item-icon" />
                                <p>Available in PDF</p>
                              </div>
                              <div className="overlay-content-item">
                                <GiCheckMark className="overlay-content-item-icon" />
                                <p>No Additional Costs</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Document>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <DashDocPreview courseData={courseWithType} id={id} />
      )}
    </div>
  );
};

export default DashQuestionBank;
