import axiosInstance from "../axiosInterceptor";

export const getSubjectBySlug = async (slug) => {
    try {
        const response = await axiosInstance.get(`/admin/subject/getSubjectBySlug/${slug}`);
        const data = response?.data;
        return data;
    } catch (error) {
        return error;
    }
};
