import React, { useEffect, useState } from "react";
import "./FilterUniversity.scss";
import AllNotesCards from "./AllNotesCards";
import FilterComp from "./FilterComp";

const FilterUniversity = ({ initialFilter }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [openMenu, setOpenMenu] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState(
    initialFilter ? initialFilter : {}
  );

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFilterMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleClose = () => [setOpenMenu(false)];
  return (
    <>
      <div className="container filter-university">
        <div className="row">
          <div className="col-lg-3 col-12 mt-5 text-start">
            {isMobile ? (
              <>
                {openMenu ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <h2>Select Filters</h2>
                      <i className="feather-x fs-2" onClick={handleClose}></i>
                    </div>
                    <FilterComp
                      setAppliedFilter={setAppliedFilter}
                      initialFilter={initialFilter}
                    />                  </>
                ) : (
                  <>
                    <span
                      onClick={handleFilterMenu}
                      className="d-flex align-items-center gap-3"
                      style={{ width: "95px" }}
                    >
                      <i className="feather-menu fs-2"></i>
                      <h4 className="mb-0">Filters</h4>
                    </span>
                  </>
                )}
              </>
            ) : (

              <FilterComp
                setAppliedFilter={setAppliedFilter}
                initialFilter={initialFilter}
              />)}
          </div>
          <div className="col-lg-9  col-12 mt-5">
            <AllNotesCards appliedFilter={appliedFilter} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterUniversity;
