import React, { useEffect, useState } from "react";
import { Col, Container, FormGroup, Row } from "reactstrap";
// import { getAllSubject } from "../services/getAllSubject";
import "../Pages/dashboard/Library.scss";
import Button from "./Buttons/Button";
import Select from "react-select";
import ReactQuill from "react-quill";
import { getCategoryWiseSubcategory } from "../services/getCategoryWiseSubcategory";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonForm from "../Pages/HomePage/Form/CommonForm";
import { getAllsubSubject } from "../services/getAllSubSubject";

const AskQue = () => {
  const [formData, setFormData] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subcategory, setSubcategory] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [question, setQuestion] = useState("");

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [typeData, setTypeData] = useState(null);

  const fetchSubjectData = async () => {
    try {
      // const response = await getAllSubject();
      const response = await getAllsubSubject();
      setFormData(response.data);
    } catch (error) {
      console.error("Error fetching subject data:", error);
    }
  };

  useEffect(() => {
    fetchSubjectData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getCategoryWiseSubcategory("65524e5e3c005ef3b51907c6");
      setSubcategory(data.subCategory);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ["bold", "italic", "underline", "blockquote"],
        [{ color: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean",
  ];

  const subjectOptions = formData.map((subject) => ({
    value: subject._id,
    // label: subject.questionSubject,
    label: subject.subSubjectName,
  }));

  const typeOptions = subcategory.map((type) => ({
    value: type._id,
    label: type.name,
  }));

  const handleSubjectChange = (selectedOption) => {
    setSelectedSubject(selectedOption);
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption);
  };

  const handleQuestionChange = (value) => {
    setQuestion(value);
  };

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handlePopup = () => {
    if (!selectedSubject || !selectedType || !question) {
      toast.error("Please fill in all the fields before continuing.");
      return;
    }

    setIsModelOpen(true);
    const typeData = {
      serviceType: "HomeworkHelp",
      type: "mcqHelp",
      totalSteps: 3,
      subject: selectedSubject,
      typeSelected: selectedType,
      questionText: question,
    };
    setTypeData(typeData);
    localStorage.setItem("serviceType", JSON.stringify(typeData));
  };

  return (
    <div>
      <Container className="py-5 w-85">
        <div className="library-quespost">
          <form>
            <div>
              <div className="library-post-box">
                <h1>Ask Question</h1>
                <h4>Get Instant Solutions</h4>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        value={selectedSubject}
                        onChange={handleSubjectChange}
                        options={subjectOptions}
                        placeholder="Select your subject"
                        name="subject"
                        className="question-select mt-5 select-subject text-start"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        value={selectedType}
                        onChange={handleTypeChange}
                        options={typeOptions}
                        placeholder="Select your type"
                        name="type"
                        className="question-select mt-5 select-subject text-start"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12} className="pt-4">
                    <ReactQuill
                      value={question}
                      onChange={handleQuestionChange}
                      modules={modules}
                      formats={formats}
                      className="question-textarea text-start"
                      placeholder="Type your question here..."
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <Row className="library-quespost-btn">
              <Col md={12} className="text-center pt-4">
                <Button
                  type="button"
                  text={"Continue"}
                  varient="linear-gradient-primary"
                  className="library-continue-btn"
                  onClick={handlePopup}
                />
              </Col>
            </Row>
          </form>
        </div>
      </Container>
      <ToastContainer />

      {isModelOpen && (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={typeData}
          question={question}
          selectedSubject={selectedSubject}
          selectedType={selectedType}
        />
      )}
    </div>
  );
};

export default AskQue;
