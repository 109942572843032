import React from "react";
import bannerimage from "../Assets/images/background/icon-bg.png";
import "./ExamPreparationBanner.scss";
import { GoSearch } from "react-icons/go";
import ExamPreparationSearch from "../Components/ExamPrepSearch";

const ExamPreparationBanner = () => {

  return (
    <>
      <div className="banner-main-wrapper">
        <img className="banner-image-ep" src={bannerimage} alt="bannerimage" />
        <div className="banner-content">
          <div className="banner-content-title-wrapper">
            
                <p>
                  <strong className="ql-size-huge ql-font-monospace all-sub-banner-title">
                    <strong className="text-white" style={{fontWeight: "600"}}> 
                      Ace Your Exams with the Best Study Resources
                    </strong>
                  </strong>
                </p>
             
          </div>
          <div className="banner-content-subtitle-ep">
            <span>
              <div className="all-sub-banner-subtittle">
                Prepare effectively for your exams with class notes, textbook
                summaries, and expert insights from fellow students. Focus on
                what matters and grasp key concepts quickly for better results!
              </div>
            </span>
          </div>

          {/* Search Section */}
          <div className="container pt-5">
            <div className="rbt-search">
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ position: "relative", width: "100%" }}>
                    <GoSearch
                      size={16}
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 10,
                        color: "#D444ED",
                      }}
                    />
                    <ExamPreparationSearch />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Search Section */}
        </div>
      </div>
    </>
  );
};

export default ExamPreparationBanner;
