import React, { useState, useEffect } from "react";
import "./Certificate.scss";
// import certificate from "../../Assets/images/certificate.png";
import certified1 from "../../Assets/images/certified1.png";
import certified2 from "../../Assets/images/certified2.png";
import certified3 from "../../Assets/images/certified3.png";
import certified4 from "../../Assets/images/certified4.png";
import { getSettings } from "../../services/getSettings";
import { ImageLink } from "../../constants";


const Certificate = () => {

    const [data, setData] = useState([])   

    const fetchSettingData = async () => {
        try {
            const res = await getSettings();
            setData(res?.data)

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchSettingData();
    }, [])

    if (!data?.certificateImage) return null;

    return (
        <div className="certificate text-center py-5">
            <h2 className="title mb-2">Verified & Certified Resources</h2>
            <p className="description mb-4">From DoubtQ</p>
            <div className="container position-relative">
                {/* Certificate Image */}
                <div className="certificate-image mx-auto">
                    <img
                        src={ImageLink(data?.certificateImage)}
                        alt="Certificate"
                        className="img-fluid"
                    />
                </div>
                {/* Certified Icons */}
                <div className="">
                    <div className="certified1">
                        <img
                            src={certified1}
                            alt="Verified Icon"
                            className="img-fluid"
                        />
                    </div>
                    <div className="certified2">
                        <img
                            src={certified2}
                            alt="Certified2"
                            className="img-fluid"
                        />
                    </div>
                    <div className="certified3">
                        <img
                            src={certified3}
                            alt="Certified3"
                            className="img-fluid"
                        />
                    </div>
                    <div className="certified4">
                        <img
                            src={certified4}
                            alt="Verified Icon"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Certificate;



