import axiosInstance from "../axiosInterceptor";

export async function getCourseByTypeId(id, typeId) {
  const response = await axiosInstance.post(
    `admin/course/getCourseByTypeId/${id}`,
    { typeId: typeId }
  );
  const data = response?.data;
  return data;
}
