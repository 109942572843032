import React, { useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import HelpSteps from "../../../Components/HelpSteps";
import StudentStories from "../../../Components/StudentStories";
import HelpService from "../../../Components/HelpService";
import AdvantagesOfQuestions from "../../../Components/AdvantagesOfQuestions";
import Footer from "../../../Components/Footer";
import BonusSection from "../../../Components/BonusSection";
import FillInTheBlankImg from "../../../Assets/images/background/fill-in-the-blank-method.svg";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";
import Navbar from "../../../Components/Navbar";
import Nav from "../../../Components/Nav";
import GetPopup from "../../Staticpages/GetPopup";
import Milestonerewards from "../../../Components/Milestonerewards";
import Universities from "../../../Components/Universities";

const FillupsService = () => {
  const subCategoryId = "65565726b9cbef8acade1d6a";
  const categoryId = "65524e5e3c005ef3b51907c6";
  const [isModelOpen, setIsModelOpen] = useState(false);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  return (
    <div>
      {/* <Nav />
      <Navbar /> */}
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection />
      <AdvantagesOfQuestions
        title="fill in the blanks method"
        arr={[
          "Student are required to find information",
          "Less time on writing notes",
          "Allows more time to elaborate on certain areas",
          "Great method for Exceptional children",
          "Steers student in right direction",
          "Student must find the words and phrases in the text",
          "Student must find the information that the teacher wants them to find",
        ]}
        img={FillInTheBlankImg}
      />
      <HelpService
        helpServiceTitle
        subCategoryID={subCategoryId}
      />
      <HelpSteps subCategoryId={subCategoryId} />
      <Milestonerewards categoryId={categoryId} />
      <BonusSection
        subCategoryId={subCategoryId}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <Universities categoryId={categoryId} />
      <StudentStories />
      <Footer />
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "HomeworkHelp",
            type: "mcqHelp",
            totalSteps: 3,
          }}
          subCategory={subCategoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
      <GetPopup />
    </div>
  );
};

export default FillupsService;
