import React from "react";
import { Link } from 'react-router-dom'
import bannerimage from "../Assets/images/background/icon-bg.png";
import "./AllSubjectsBanner.scss";
import Renderer from "./Renderer";
import "./QuestionBanner.scss";


const QuestionBanner = ({
    id = "",
    setIsModelOpen = () => { },
    btnDisabled = false,
}) => {
    const pageData = {
        "isSuccess": true,
        "message": "Benefit get successfully.",
        "bannerData": {
            "_id": "6589a8097167a146d77faa2d",
            "subCategoryId": "6586cc85d2f7eeadeea4faee",
            "title": "<p><strong class=\"ql-size-huge ql-font-monospace\">EXERCISE 1-3 (LO4) Calculating Missing ...</strong></p>",
            "description": "< Back to search results",
            "createdAt": "2023-12-25T16:04:25.358Z",
            "updatedAt": "2024-03-08T19:52:05.976Z",
            "__v": 0
        },

    }
    // const [pageData, setPageData] = useState([]);


    // const fetchData = async () => {
    //   await getSubcategoryWiseContent(id)
    //     .then((data) => {
    //       setPageData(data);
    //     })
    //     .catch((err) => console.log(err));
    // };

    // console.log(pageData);

    // useEffect(() => {
    //   fetchData();
    // }, [id]);

    return (
        <>
            <div className="banner-main-wrapper">
                <img className="banner-image" src={bannerimage} alt="Que-banner"/>
                <div className="banner-content">
                    <div className="banner-content-title-wrapper">
                        <div className="title title-que-banner">
                            <Renderer content={pageData?.bannerData?.title} />
                        </div>
                    </div>
                    <div className="banner-content-subtitle">
                        <Link to="/search" className="back-to-search">
                            <span>
                                <Renderer content={pageData?.bannerData?.description} />
                            </span>
                        </Link>
                    </div>

                    {/* Search Section */}
                    <div className="container pt-5">
                        <div className="rbt-search">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <label className="text-wight-que-banner">Search for Question</label>
                                        {/* <div className="text-center">
                                            <label htmlFor="uploadInput" className="custom-upload-label">
                                                <span className="upload-icon me-2">
                                                    <i className="fa-regular fa-image" style={{ color: " #ff00ea" }}></i>
                                                </span>
                                                Upload Image
                                            </label>
                                            <input type="file" id="uploadInput" className="d-none" />
                                        </div> */}
                                    </div>
                                    <form action="#" className="mt-2">
                                        <div className="inside-form">
                                            <div className="input-wrapper">
                                                <i className="feather-search fs-icon"></i>
                                                <input
                                                    type="text"
                                                    placeholder="Type your question here e.g: Define ohm's law..."
                                                    className="search-ip"
                                                />
                                                <button className="search-btn btn-gradient">Find Answer</button>
                                            </div>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Search Section */}

                </div>

            </div>
        </>
    );
};

export default QuestionBanner;
