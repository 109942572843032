import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./ViewPopup.scss";
import { Document, Page } from "react-pdf";
import Button from "./Buttons/Button";
import { Rating } from "react-simple-star-rating";
import { dateFormat } from "./dateFormat";
import DocPreview from "./DocPreview";
import { ImageLink } from "../constants";

const ViewPopup = ({ isOpen, toggle, course }) => {
  const navigator = useNavigate();
  const [numPages, setNumPages] = useState(null);

  var pdfUrl = "";
  if (course?.type === "Practice Papers") {
    pdfUrl = course?.practicePapers.filePath;
  } else if (course?.type === "Question Bank") {
    pdfUrl = course?.questionBank.filePath;
  } else if (course?.type === "Special Study Notes") {
    pdfUrl = course?.studyNotes.filePath;
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const closeBtn = (
    <button
      className="view-popup-close-btn"
      onClick={toggle}
      style={{ zIndex: 1 }}
      type="button"
    >
      X
    </button>
  );

  return (
    <>
      <div className="view-popup">
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          size="xl"
          className="view-popup-modal"
        >
          <ModalHeader close={closeBtn} className="border-0"></ModalHeader>
          <ModalBody
            style={{ padding: "0px 0px 0px 10px", marginBottom: "0px" }}
          >
            <Row>
              <Col lg={6} md={12} className="view-popup-left">
                <div className="view-popup-left-header">
                  <p>Preview 2 out of 6 Pages</p>
                </div>

                {pdfUrl ? (
                  <div className="view-popup-pdf-container">
                    <div className="view-popup-pdf">
                      <Document
                        file={ImageLink(pdfUrl)}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {Array.from(
                          new Array(Math.min(numPages, 2)),
                          (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                            />
                          )
                        )}
                      </Document>
                    </div>
                  </div>
                ) : (
                  <DocPreview course={course} />
                )}
              </Col>
              <Col lg={6} md={12}>
                <div className="view-popup-right">
                  <div className="view-popup-right-header">
                    <p>{course?.tempCourse?.courseType}</p>
                    <div className="pb-1">
                      <Rating
                        size={15}
                        initialValue={course?.ratings}
                        readonly
                      />
                    </div>
                    <span> ({course?.ratings})</span>
                  </div>
                  <div className="view-popup-right-content">
                    <h2>{course?.title}</h2>
                    <p>{course?.subTitle}</p>
                    <p className="view-popup-price">
                      {" "}
                      <span>
                        $ {course?.tempCourse?.coursePrice}
                        {/* {course?.type === "Special Study Notes"
                          ? course?.studyNotes?.price
                          : course?.type === "Practice Papers"
                            ? course?.practicePapers?.price
                            : course?.type === "Question Bank"
                              ? course?.questionBank?.price
                              : "N/A"} */}
                      </span>{" "}
                      <span className="course-date">
                        {course?.updatedAt && dateFormat(course?.updatedAt)}
                      </span>
                    </p>
                    <div className="view-popup-right-footer">
                      <Button
                        varient="linear-gradient-primary"
                        className="pt-3 pb-3 ps-4 pe-4"
                        text="Buy Now"
                        onClick={() => {
                          navigator("/paynow", {
                            state: course,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};
export default ViewPopup;
