import axiosInstance from "../axiosInterceptor";

export async function getCourseByQuestionBank(params = {}) {
    const {
        search = '',
        page = 1,
        limit = 6
    } = params;

    const queryParams = new URLSearchParams({
        search,
        page,
        limit
    }).toString();

    const response = await axiosInstance.get(
        `admin/course/getCourseByQuestionBank?${queryParams}`
    );
    return response?.data;
}

export async function getCourseBySpecialStudyNotes(params = {}) {
    const {
        search = '',
        page = 1,
        limit = 6
    } = params;

    const queryParams = new URLSearchParams({
        search,
        page,
        limit
    }).toString();

    const response = await axiosInstance.get(
        `admin/course/getCourseBySpecialStudyNotes?${queryParams}`
    );
    return response?.data;
}


export async function getCourseByPracticePapers(params = {}) {
    const {
        search = '',
        page = 1,
        limit = 6
    } = params;

    const queryParams = new URLSearchParams({
        search,
        page,
        limit
    }).toString();

    const response = await axiosInstance.get(
        `admin/course/getCourseByPracticePapers?${queryParams}`
    );
    return response?.data;
}