import axiosInstance from "../axiosInterceptor";

export async function purchaseCourse(body) {
  try {
    const response = await axiosInstance.post(`student/purchaseCourse`, body);
    const data = response?.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
