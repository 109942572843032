import React from 'react'
import "./CourseBenefits.scss"
import icon from "../../Assets/images/Group 636.png"

const CourseBenefits = () => {

    const data = [
        {
            id: 1,
            img: icon,
            title: "Guaranteed quality through customer reviews",
            para: "Stuvia customers have reviewed more than 700,000 summaries.This how you know that you are buying the best documents."
        },
        {
            id: 2,
            img: icon,
            title: "Quick and easy check-out",
            para: "You can quickly pay through credit card or Stuvia-credit for the summaries. There is no membership needed."
        },
        {
            id: 3,
            img: icon,
            title: "Guaranteed quality through customer reviews",
            para: "Stuvia customers have reviewed more than 700,000 summaries.This how you know that you are buying the best documents."
        },
        {
            id: 4,
            img: icon,
            title: "Guaranteed quality through customer reviews",
            para: "Stuvia customers have reviewed more than 700,000 summaries.This how you know that you are buying the best documents."
        },
        {
            id: 5,
            img: icon,
            title: "Quick and easy check-out",
            para: "You can quickly pay through credit card or Stuvia-credit for the summaries. There is no membership needed."
        },
        {
            id: 6,
            img: icon,
            title: "Guaranteed quality through customer reviews",
            para: "Stuvia customers have reviewed more than 700,000 summaries.This how you know that you are buying the best documents."
        },

    ]

    return (
        <>
            <div className="course-benefits">
                <div className="course-benefits__container">
                    <div className='container'>
                        <h2 className="course-benefits__title">Benefits of using DoubtQ Materials</h2>
                        <div className="row">
                            {
                                data.map((e, index) => {
                                    return (
                                        <div className='col-lg-4 col-md-6' key={index}>
                                            <div className="course-benefits__card">
                                                <img src={e.img} alt="icon" />
                                                <h3>{e.title}</h3>
                                                <p className='cb-para'>{e.para}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CourseBenefits;