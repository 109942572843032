import axiosInstance from "../axiosInterceptor";

export async function recentQuestionBySubSubject(pageNo, perPage, subjectId) {
  const response = await axiosInstance.post(`student/getRecentQuestionsBySubSubject`, {
    pageNo: pageNo,
    perPage: perPage,
    subjectId: subjectId,
  });
  const data = response?.data;
  return data;
}
