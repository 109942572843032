import axiosInstance from "../axiosInterceptor";

export async function recentQuestionBySubject(pageNo, perPage, subjectId) {
  const response = await axiosInstance.post(`student/getRecentQuestionsBySubject`, {
    pageNo: pageNo,
    perPage: perPage,
    subjectId: subjectId,
  });
  const data = response?.data;
  return data;
}
