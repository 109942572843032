import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./DashboardPaynow.scss";
import pdf from "../../Assets/images/New/que-pdf.png";
import { Rating } from "react-simple-star-rating";
import { BsCart } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import Button from "../../Components/Buttons/Button";
import { dateFormat } from "../../Components/dateFormat";
import { getSessionDataByStudentCourse } from "../../services/getSessionDataByStudentCourse";
import { getCourseByTypeId } from "../../services/getCourseByTypeId";
import { purchaseStudentCourse } from "../../services/purchaseStudentCourse";
import { Helmet } from "react-helmet";

const DashboardPaynow = () => {
  const location = useLocation();
  const courseData = location.state;
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  // const price = courseData.price || 0;
  // const [typeId, setTypeId] = useState("");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // useEffect(() => {
  //   setTypeId(courseData.typeId || "");
  // }, []);
  // if (typeId === "") {
  //   setTypeId(
  //     courseData?.type === "Special Study Notes"
  //       ? courseData?.studyNotes?._id
  //       : courseData?.type === "Practice Papers"
  //       ? courseData?.practicePapers?._id
  //       : courseData?.type === "Question Bank"
  //       ? courseData?.questionBank?._id
  //       : ""
  //   );
  // }

  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    const fetchselelctedData = async () => {
      try {
        const response = await getCourseByTypeId(
          courseData?._id,
          courseData?.tempCourse?.courseTypeId
        );
        setSelectedData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchselelctedData();
  }, []);

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  const toggle = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  const handleRemoveClick = () => {
    navigate("/dashboard/home");
  };

  const handlePayment = async () => {
    setIsSubmitting(true);
    setErrorMessage("");

    try {
      const response = await purchaseStudentCourse({
        courseId: selectedData.courseId,
        courseInnerType: selectedData.TypeName,
        courseInnerId: selectedData.courseTypeId,
        price: selectedData.courseTypePrice,
      });
      if (response.isSuccess) {
        if (response?.billingDetails) {
          const responseData = await getSessionDataByStudentCourse(
            response?.data
          );
          if (responseData.clientSecret) {
            navigate(`/course-checkout/${response.data._id}`, {
              state: {
                clientSecret: responseData.clientSecret,
              },
            });
          }
        } else {
          navigate(
            `/billingDetailscourse/${response?.data?.paymentMethod}/${response?.data?._id}`,
            { state: response?.data?._id }
          );
        }
        setErrorMessage(response.message || "Purchase failed");
      }
    } catch (error) {
      console.error("Purchase error:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Master Study Cart | Expert-Led Online Courses on DoubtQ`}</title>
        <meta
          name="description"
          content="Boost your knowledge and skills with Study Cart courses on DoubtQ. Access expert-led lessons, comprehensive study materials, and personalized learning to achieve academic success!"
        />
        <meta
          property="og:title"
          content="Study Cart - Expert-Led Online Learning | DoubtQ"
        />
        <meta
          property="og:description"
          content="Advance your learning with Study Cart courses on DoubtQ. Get high-quality study materials, expert guidance, and hands-on training to excel in your studies!"
        />
      </Helmet>
      <div className="main-container">
        <div className="inside-container">
          <div className="row p-3">
            <div className="col-lg-12 pn-pg-main">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="pn-progress-step">
                  <div className="progress-circle">1</div>
                  <p> Payment Method</p>
                </div>
                <div className="pn-progress-step pn-inactive">
                  <div className="progress-circle">2</div>
                  <p> Checkout</p>
                </div>
                <div className="pn-progress-step pn-inactive">
                  <div className="progress-circle">3</div>
                  <p> Download</p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 pn-left-part">
              <div className="mt-5 mb-5">
                <h2>Study Cart</h2>
              </div>
              <div className="row">
                <div className="col-md-3 col-12">
                  <div className="cart-item">
                    <img src={pdf} alt="cart-item" />
                    <div className="mt-2">
                      <Rating
                        size={22}
                        initialValue={parseFloat(courseData?.ratings)}
                        readonly
                      />
                    </div>
                    <div className="pn-purchase mt-1">
                      <BsCart className="mx-2" /> {courseData?.purchases}{" "}
                      purchases
                    </div>
                    <div className="pn-views mt-1">
                      {courseData?.views} Views
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-12 pt-4 pb-5 ps-5 pe-5">
                  <div className="cart-item-details">
                    <p className="pn-que-course-name text-start mb-2">
                      <span className="fs-3 me-3">
                        {courseData?.courseName}
                      </span>{" "}
                      ({courseData?.university?.universityName}) -{" "}
                      {courseData?.tempCourse?.courseType}
                    </p>
                    <p className="pn-que-course-sub1 text-start mb-2">
                      <Link
                        to={`/dashboard/home/course/${courseData?.university?.universityUrl}/${courseData?.courseUrl}`}
                        className="text-decoration-none"
                      >
                        {courseData?.title}
                      </Link>
                    </p>
                    <p className="pn-que-bank-text pn-que-course-sub2 text-start">
                      {courseData?.subTitle}
                    </p>
                    <hr className="mt-4 mb-4" />
                    <div className="d-flex justify-content-between align-items-center pe-3">
                      <p className="pn-que-course-name text-start mb-2">
                        <span className="fs-3 me-3">
                          ${courseData?.tempCourse?.coursePrice}
                        </span>
                        (
                        {courseData?.updatedAt &&
                          dateFormat(courseData?.updatedAt)}
                        )
                      </p>
                      <RiDeleteBinLine
                        className="fs-2 cursor-pointer"
                        onClick={toggleNested}
                      />
                    </div>
                    {errorMessage && (
                      <div className="text-danger mt-3">{errorMessage}</div>
                    )}
                    <div className="text-start mt-3">
                      <Button
                        onClick={handlePayment}
                        text={isSubmitting ? "Processing..." : "Pay Now"}
                        varient="linear-gradient-primary"
                        className="py-3 px-4"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={nestedModal}
        toggle={toggleNested}
        centered
        className="rounded-5 rounded"
        onClosed={closeAll ? toggle : undefined}
      >
        <ModalBody>
          <div className="p-5">
            <div className="nestedPopup">
              Are you sure you want to Remove this item?
            </div>
            <div className="pt-3 d-flex justify-content-end gap-3">
              <span
                className="fs-3 resumeBtn text-white bg-primary px-3 py-2 rounded-3"
                onClick={handleRemoveClick}
              >
                Remove
              </span>
              <span
                className="fs-3 exitBtn text-white bg-danger px-3 py-2 rounded-3"
                onClick={toggle}
              >
                Cancel
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DashboardPaynow;
