import axiosInstance from "../axiosInterceptor";

export async function getAllCourseForStudent(search) {
    const response = await axiosInstance.get(`/admin/course/getAllCourseForStudent`,
        {
            params: {
                search: search
            }
        }
    );
    const data = response?.data;
    return data;
}