import React, { useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import BenefitsSection from "../../../Components/BenefitsSection";
import HelpSteps from "../../../Components/HelpSteps";
import BonusSection from "../../../Components/BonusSection";
import StudentStories from "../../../Components/StudentStories";
import ChatHelpExperts from "../../../Components/ChatHelpExperts";
import Footer from "../../../Components/Footer";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";
import Navbar from "../../../Components/Navbar";
import Nav from "../../../Components/Nav";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import AssignmentList from "../../../Components/AssignmentList";
import Universities from "../../../Components/Universities";
import Milestonerewards from "../../../Components/Milestonerewards";
import Page from "../../Metadata/Page";
import ComWorldWide from "../../../Components/ComWorldWide";
import ComReadMoreSection from "../../../Components/ComReadMoreSection";

const ProjectLabReportWriting = () => {
  const subCategoryId = "6586d9a9dafb1f4a8f461274";
  const categoryId = "65524e633c005ef3b51907c9";

  const [isModelOpen, setIsModelOpen] = useState(false);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  return (
    <>
      <Page subCategoryId={subCategoryId} categoryId={categoryId} />
      {/* <Nav />
      <Navbar /> */}
      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item">Assignment Help</li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">
                Project Lab Report Writing Help
              </li>
            </ul>
          </div>
        </div>
      </div>
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection />
      <BenefitsSection
        subCategoryId={subCategoryId}
        setIsModelOpen={setIsModelOpen}
      />
      <AssignmentList subCategoryId={subCategoryId} />
      <HelpSteps subCategoryId={subCategoryId} />
      <Milestonerewards categoryId={categoryId} />
      <BonusSection
        subCategoryId={subCategoryId}
        setIsModelOpen={setIsModelOpen}
      />
      <Universities categoryId={categoryId} setIsModelOpen={setIsModelOpen} />
      <StudentStories />
      <ChatHelpExperts />
      <ComWorldWide id={subCategoryId} />
      <ComReadMoreSection id={subCategoryId} />
      <Footer />
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "AssignmentHelp",
            type: "assignmentHelp",
            totalSteps: 4,
          }}
          subCategory={subCategoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
    </>
  );
};

export default ProjectLabReportWriting;
