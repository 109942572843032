import axiosInstance from "../axiosInterceptor";

export async function getCourseFaqsById() {
    try {
        const response = await axiosInstance.get(`admin/courseFaqs/getCourseFaqById`);
        const data = response?.data;
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return { isSuccess: false, message: "Failed to fetch course faqs" };
    }
}
