import React, { useState, useEffect, useCallback } from "react";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";
import { LuSearch } from "react-icons/lu";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import Button from "../../Components/Buttons/Button";
import {
  getCourseBySpecialStudyNotes,
  getCourseByQuestionBank,
  getCourseByPracticePapers,
} from "../../services/courseServices";

import "./DashboardUniversity.scss";
import { Helmet } from "react-helmet";

const DashboardUniversity = () => {
  // State management for different course types
  const [storyData, setStoryData] = useState({
    data: [],
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
  });
  const [quebankData, setQuebankData] = useState({
    data: [],
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
  });
  const [prapData, setPrapData] = useState({
    data: [],
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
  });

  // Search state
  const [storySearch, setStorySearch] = useState("");
  const [quebankSearch, setQuebankSearch] = useState("");
  const [prapSearch, setPrapSearch] = useState("");

  // Loading state
  const [isLoading, setIsLoading] = useState(false);

  // Dynamic data fetching function
  const fetchData = useCallback(async (type, page, search, setState) => {
    setIsLoading(true);
    try {
      let response;
      switch (type) {
        case "story":
          response = await getCourseBySpecialStudyNotes({ search, page });
          break;
        case "quebank":
          response = await getCourseByQuestionBank({ search, page });
          break;
        case "prap":
          response = await getCourseByPracticePapers({ search, page });
          break;
        default:
          response = null;
      }

      setState({
        data: response?.data || [],
        currentPage: response?.pagination?.page || page,
        totalPages: response?.pagination?.totalPages || 0,
        totalItems: response?.pagination?.total || 0,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Initial data fetch
  useEffect(() => {
    fetchData("story", 1, "", setStoryData);
    fetchData("quebank", 1, "", setQuebankData);
    fetchData("prap", 1, "", setPrapData);
  }, [fetchData]);

  // Search handler
  const handleSearch = (searchTerm, type) => {
    switch (type) {
      case "story":
        fetchData("story", 1, searchTerm, setStoryData);
        break;
      case "quebank":
        fetchData("quebank", 1, searchTerm, setQuebankData);
        break;
      case "prap":
        fetchData("prap", 1, searchTerm, setPrapData);
        break;
      default:
        break;
    }
  };

  // Pagination handler
  const handlePaginationClick = (pageNo, type) => {
    const searchTerm =
      type === "story"
        ? storySearch
        : type === "quebank"
        ? quebankSearch
        : prapSearch;

    switch (type) {
      case "story":
        fetchData("story", pageNo, searchTerm, setStoryData);
        break;
      case "quebank":
        fetchData("quebank", pageNo, searchTerm, setQuebankData);
        break;
      case "prap":
        fetchData("prap", pageNo, searchTerm, setPrapData);
        break;
      default:
        break;
    }
  };

  // Render pagination component
  const renderPagination = (data, type) => {
    const { currentPage, totalPages } = data;

    return (
      <Pagination className="mt-4">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handlePaginationClick(currentPage - 1, type)}
          />
        </PaginationItem>

        {[...Array(totalPages)].map((_, index) => {
          const pageNumber = index + 1;
          if (
            pageNumber === 1 ||
            pageNumber === totalPages ||
            (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
          ) {
            return (
              <PaginationItem
                key={pageNumber}
                active={currentPage === pageNumber}
              >
                <PaginationLink
                  onClick={() => handlePaginationClick(pageNumber, type)}
                >
                  {pageNumber}
                </PaginationLink>
              </PaginationItem>
            );
          }
          if (
            pageNumber === currentPage - 2 ||
            pageNumber === currentPage + 2
          ) {
            return (
              <PaginationItem disabled key={pageNumber}>
                <PaginationLink>...</PaginationLink>
              </PaginationItem>
            );
          }
          return null;
        })}

        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            next
            onClick={() => handlePaginationClick(currentPage + 1, type)}
          />
        </PaginationItem>
      </Pagination>
    );
  };

  // Render course card
  const renderCourseCard = (course) => (
    <div className="db-suggestion-card" key={course.id}>
      <div>
        <div className="uni-name text-start">
          <p>
            {course?.courseName?.length > 15
              ? `${course?.courseName.substring(0, 15)}...`
              : course?.courseName}
            <span>
              (
              {course?.university?.universityName?.length > 15
                ? `${course.university?.universityName.substring(0, 15)}...`
                : course?.university?.universityName}
              )
            </span>
          </p>
        </div>
        <div className="d-flex align-items-center gap-3">
          <div className="rating-stars">
            <Rating
              size={20}
              initialValue={parseFloat(course?.courseRatings)}
              readonly
            />
          </div>
          <div className="purchase-text">
            <i className="fa-solid fa-cart-shopping pe-2"></i>
            <span>{course?.purchases} Purchases</span>
          </div>
        </div>
      </div>
      <div>
        <Link
          to={`/dashboard/home/course/${course?.university?.universityUrl}/${course?.courseUrl}`}
        >
          <Button
            varient="linear-gradient-primary"
            className="px-3 py-2 fs-5"
            text="View"
          />
        </Link>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>
          Find My University | Discover Top Universities & Academic Support with
          DoubtQ
        </title>
        <meta
          name="description"
          content="Explore top universities worldwide and find the best academic support with DoubtQ. Get insights, resources, and expert guidance to help you succeed!"
        />
        <meta
          property="og:title"
          content="Find My University - Discover Top Institutions & Academic Support | DoubtQ"
        />
        <meta
          property="og:description"
          content="Looking for the best university for your studies? Explore top institutions, compare academic programs, and get expert guidance with DoubtQ!"
        />
      </Helmet>
      <div className="container">
        {/* Special Study Notes Section */}
        <div className="db-special-study-notes">
          <div className="db-title-search">
            <div className="mb-1">
              <h1 className="text-start">Special Study Notes</h1>
              <p className="text-start">Recently Prepared DoubtQ</p>
            </div>
            <div className="db-prac-paper-search mb-3">
              <LuSearch className="fs-2 db-search-icon" />
              <input
                type="text"
                placeholder="Find Course, University"
                value={storySearch}
                onChange={(e) => {
                  setStorySearch(e.target.value);
                  handleSearch(e.target.value, "story");
                }}
              />
            </div>
          </div>
          {isLoading ? (
            <p className="text-center mt-4">Loading...</p>
          ) : storyData.data.length > 0 ? (
            <>
              <div className="db-university-card">
                {storyData.data.map(renderCourseCard)}
              </div>
              <div className="float-end">
                {renderPagination(storyData, "story")}
              </div>
            </>
          ) : (
            <p className="text-center mt-4">
              {storySearch.length > 0 && storySearch.length < 3
                ? "Please enter at least 3 characters to search"
                : "No results found"}
            </p>
          )}
        </div>

        {/* Practice Paper Section */}
        <div className="mt-5 db-practice-paper">
          <div className="db-title-search">
            <div className="mb-1">
              <h1 className="text-start">Practice Paper</h1>
              <p className="text-start">Recently Prepared DoubtQ</p>
            </div>
            <div className="db-prac-paper-search mb-3">
              <LuSearch className="fs-2 db-search-icon" />
              <input
                type="text"
                placeholder="Find Course, University"
                value={prapSearch}
                onChange={(e) => {
                  setPrapSearch(e.target.value);
                  handleSearch(e.target.value, "prap");
                }}
              />
            </div>
          </div>
          {isLoading ? (
            <p className="text-center mt-4">Loading...</p>
          ) : prapData.data.length > 0 ? (
            <>
              <div className="db-university-card">
                {prapData.data.map(renderCourseCard)}
              </div>
              <div className="float-end">
                {renderPagination(prapData, "prap")}
              </div>
            </>
          ) : (
            <p className="text-center mt-4">
              {prapSearch.length > 0 && prapSearch.length < 3
                ? "Please enter at least 3 characters to search"
                : "No results found"}
            </p>
          )}
        </div>

        {/* Question Bank Section */}
        <div className="mt-5 db-question-bank">
          <div className="db-title-search">
            <div className="mb-1">
              <h1 className="text-start">Question Bank</h1>
              <p className="text-start">Recently Prepared DoubtQ</p>
            </div>
            <div className="db-prac-paper-search mb-3">
              <LuSearch className="fs-2 db-search-icon" />
              <input
                type="text"
                placeholder="Find Course, University"
                value={quebankSearch}
                onChange={(e) => {
                  setQuebankSearch(e.target.value);
                  handleSearch(e.target.value, "quebank");
                }}
              />
            </div>
          </div>
          {isLoading ? (
            <p className="text-center mt-4">Loading...</p>
          ) : quebankData.data.length > 0 ? (
            <>
              <div className="db-university-card">
                {quebankData.data.map(renderCourseCard)}
              </div>
              <div className="float-end">
                {renderPagination(quebankData, "quebank")}
              </div>
            </>
          ) : (
            <p className="text-center mt-4">
              {quebankSearch.length > 0 && quebankSearch.length < 3
                ? "Please enter at least 3 characters to search"
                : "No results found"}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardUniversity;
