import axiosInstance from "../axiosInterceptor";

export async function purchaseStudentCourse(body) {
    try {
        const response = await axiosInstance.post(`student/purchaseStudentCourse`, body);
        const data = response?.data;
        return data;
    } catch (error) {
        return error.response.data;
    }
}
