import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import "./Navbar.scss";
import { CategoryContext } from "../categoryContext";
import logo from "../Assets/logo/logo.svg";
import Home from "../Assets/images/home.svg";
import { ImageLink } from "../constants";
import { RxHamburgerMenu } from "react-icons/rx";
import { HiOutlineXMark } from "react-icons/hi2";
import { GoChevronDown, GoChevronRight } from "react-icons/go";

const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [newSubmenu, setNewSubmenu] = useState(null);
  const [navbarMenu, setNavbarMenu] = useState([]);
  const [navMenu, setNavMenu] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [socialMedia, setsocialMedia] = useState([]);
  const [subject, setSubject] = useState("");
  const [hoveredSubjectId, setHoveredSubjectId] = useState(null);

  const handleSubjectHover = (subjectId) => {
    setHoveredSubjectId(subjectId);
  };

  const fetchSocialMediaData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/socialMedia/getSocialMedia`);
      setsocialMedia(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await axiosInstance.get(
        "/admin/category/getCategoryWiseSubcategory"
      );
      setNavbarMenu(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const modifiedMenu = useMemo(() => {
    if (navbarMenu.length === 0) return [];
    const menuCopy = [...navbarMenu];
    menuCopy.splice(3, 0, { categoryName: "Subjects" });
    return menuCopy;
  }, [navbarMenu]);

  const fetchAllCategory = async () => {
    try {
      const res = await axiosInstance.get(`/admin/category/getAllCategory`);
      setNavMenu(res.data.data[0].name);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllSubjectWiseSubsubject = async () => {
    try {
      const res = await axiosInstance.get(
        `admin/subSubject/getSubjectWiseSubSubject`
      );

      setSubject(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchAllCategory();
    fetchSocialMediaData();
    fetchAllSubjectWiseSubsubject();
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen);

  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const toggleNewSubmenu = (id) => {
    setNewSubmenu(newSubmenu === id ? null : id);
  };

  const handleMouseEnter = (index) => {
    setOpenSubmenu(index);
  };

  const handleMouseLeave = () => {
    setOpenSubmenu(null);
    setHoveredSubjectId(null);
  };

  const handleSubMenuRightClick = () => {
    setHoveredSubjectId(null);
  };

  const handleLinkClick = () => {
    setHoveredSubjectId(null);
  };

  const { setCategoryId } = useContext(CategoryContext);

  function truncateText(text) {
    const maxLength = 25;
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  }

  return (
    <>
      {/* Desktop Nav Menu */}
      <header
        className={`rbt-header rbt-header-10 ${
          isSticky ? "header-sticky" : ""
        }`}
      >
        <div className="rbt-header-wrapper header-space-between">
          <div className="container-fluid">
            <div className="mainbar-row rbt-navigation-center align-items-center">
              <div className="mobile-menu-bar d-block d-xl-none">
                <div className="hamberger">
                  <button
                    className="hamberger-button rbt-round-btn"
                    onClick={toggleNavbar}
                  >
                    <RxHamburgerMenu color="black" />
                  </button>
                </div>
              </div>
              <div className="header-left rbt-header-content">
                <div className="header-info">
                  <div className="logo">
                    <Link to="/">
                      <img src={logo} alt="Vaidik Logo Images" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="rbt-main-navigation d-none d-xl-block">
                <nav className="mainmenu-nav">
                  {navMenu.length > 0 && (
                    <ul className="mainmenu scrollable-menu">
                      {modifiedMenu.length > 0 && (
                        <li>
                          <Link to="/">
                            <img
                              src={Home}
                              alt="home"
                              width={20}
                              height={20}
                              className="home-img"
                            />
                          </Link>
                        </li>
                      )}

                      {modifiedMenu
                        .filter((nav) => nav.categoryName !== "Home")
                        .map((category, index) => {
                          const isOpen = openSubmenu === index;
                          if (category.categoryName === "Subjects") {
                            return (
                              <li
                                key="subjects-menu"
                                className={`has-dropdown has-menu-child-item ${
                                  isOpen ? "open" : ""
                                }`}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <Link to="/all-subjects">
                                  {category.categoryName}
                                  {subject?.length > 0 && (
                                    <i>
                                      <GoChevronDown size={16} />
                                    </i>
                                  )}
                                </Link>

                                <ul
                                  className="submenu"
                                  style={{
                                    maxHeight:
                                      subject?.length > 5 ? "300px" : "auto",
                                  }}
                                >
                                  {subject?.length > 0 &&
                                    subject
                                      .filter((item) => item.showInHeader)
                                      .map((item) => (
                                        <li
                                          className="has-dropdown"
                                          key={item._id || item.mainSubject}
                                          onMouseEnter={() =>
                                            handleSubjectHover(
                                              item.mainSubjectId
                                            )
                                          }
                                        >
                                          <Link
                                            to={`/subject/${item.subjectUrl}`}
                                            className="d-flex gap-3 align-items-center text-img-adj"
                                          >
                                            <img
                                              src={ImageLink(
                                                item?.mainSubjectImage
                                              )}
                                              alt=""
                                              width={25}
                                              height={25}
                                              className="home-img"
                                            />
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                              <span className="text-capitalize">
                                                {" "}
                                                {item?.mainSubject?.split(" Academic Help")[0]}
                                              </span>
                                              {item?.subSubject?.length > 0 &&
                                                item.subSubject.some(
                                                  (sub) => sub.isActive === true
                                                ) && (
                                                  <GoChevronRight
                                                    size={14}
                                                    color="black"
                                                  />
                                                )}
                                            </div>
                                          </Link>
                                        </li>
                                      ))}
                                </ul>

                                {/* Separate submenu-right (sub-submenu) */}
                                {subject?.length > 0 &&
                                  subject.map((item) =>
                                    item.subSubject?.length > 0 ? (
                                      <ul
                                        className="submenu-right "
                                        key={item._id}
                                        style={{
                                          display:
                                            hoveredSubjectId ===
                                            item.mainSubjectId
                                              ? "block"
                                              : "none",
                                          maxHeight:
                                            item.subSubject.length > 5
                                              ? "300px"
                                              : "auto",
                                        }}
                                      >
                                        {item.subSubject
                                          .filter(
                                            (subItem) => subItem?.isActive
                                          )
                                          .map((subItem) => (
                                            <li
                                              key={
                                                subItem._id ||
                                                subItem.subSubjectName
                                              }
                                            >
                                              <Link
                                                to={`/${item.subjectUrl}/${subItem.subSubjectUrl}`}
                                                className="d-flex gap-3 align-items-center"
                                                onClick={handleLinkClick}
                                              >
                                                <img
                                                  src={ImageLink(
                                                    subItem?.image
                                                  )}
                                                  alt={
                                                    subItem?.subSubjectName ||
                                                    "Sub Subject"
                                                  }
                                                  width={25}
                                                  height={25}
                                                  className="home-img"
                                                />
                                                <span className="text-capitalize">
                                                {truncateText(subItem?.subSubjectName?.replace(/ Academic Help/g, ""))}
                                                </span>
                                              </Link>
                                            </li>
                                          ))}
                                      </ul>
                                    ) : null
                                  )}
                              </li>
                            );
                          }

                          // Regular category handling
                          return (
                            <li
                              key={category._id}
                              className={`has-dropdown has-menu-child-item ${
                                isOpen ? "open" : ""
                              }`}
                              onMouseEnter={() => {
                                handleMouseEnter(index);
                                handleSubjectHover(null);
                              }}
                              onMouseLeave={handleMouseLeave}
                            >
                              <Link
                                to={
                                  category?.categoryId ===
                                  "67b57f038a01c99610fc51b2"
                                    ? "/exam-preparation"
                                    : "#"
                                }
                              >
                                {category.categoryName}
                                <i>
                                  <GoChevronDown size={16} />
                                </i>
                              </Link>

                              <ul
                                className="submenu"
                                style={{
                                  maxHeight:
                                    category.subcategories?.length > 5
                                      ? "300px"
                                      : "auto",
                                }}
                              >
                                {category.subcategories?.map((item) => (
                                  <li
                                    key={item._id}
                                    className="has-dropdown"
                                    onMouseEnter={() =>
                                      handleSubjectHover(item.mainSubjectId)
                                    }
                                  >
                                    <Link
                                      to={item.path}
                                      onClick={() => setCategoryId(item._id)}
                                      className="d-flex gap-3 align-items-center text-img-adj"
                                    >
                                      <img
                                        src={ImageLink(item?.image)}
                                        alt={item.name}
                                        width={25}
                                        height={25}
                                        className="home-img"
                                      />
                                      {truncateText(item.name)}
                                    </Link>
                                    {hoveredSubjectId === item.mainSubjectId &&
                                      item.subSubject?.length > 0 && (
                                        <ul
                                          className="submenu-right"
                                          onClick={handleSubMenuRightClick}
                                        >
                                          {item.subSubject
                                            .filter(
                                              (subItem) => subItem?.isActive
                                            )
                                            .map((subItem) => (
                                              <li
                                                key={
                                                  subItem._id ||
                                                  subItem.subSubjectName
                                                }
                                              >
                                                <Link
                                                  to={`/${item.subjectUrl}/${subItem.subSubjectUrl}`}
                                                  className="d-flex gap-3 align-items-center"
                                                >
                                                  <img
                                                    src={subItem?.imageUrl}
                                                    alt={
                                                      subItem?.subSubjectName ||
                                                      "Sub Subject"
                                                    }
                                                    width={25}
                                                    height={25}
                                                    className="home-img"
                                                  />
                                                  <span>
                                                    {subItem?.subSubjectName}
                                                  </span>
                                                </Link>
                                              </li>
                                            ))}
                                        </ul>
                                      )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </nav>
              </div>
              <div className="header-right">
                <ul className="quick-access">
                  <li className="account-access rbt-user-wrapper d-none d-xl-block">
                    <Link className="rbt-btn btn-gradient btn-sm" to="/login">
                      <i className="feather-user" />
                      Sign In
                    </Link>
                  </li>
                  <li className="access-icon rbt-user-wrapper d-block d-xl-none">
                    <Link to="/login">
                      <span style={{ fontSize: "16px" }}>Sign In</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Nav Menu */}
      <div
        className={`popup-mobile-menu ${isNavbarOpen ? "active" : ""}`}
        onClick={() => setIsNavbarOpen(false)}
      >
        <div className="inner-wrapper" onClick={(e) => e.stopPropagation()}>
          <div className="inner-top">
            <div className="content">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Logo Vaidik" />
                </Link>
              </div>
              <div className="rbt-btn-close">
                <button
                  className="close-button rbt-round-btn"
                  onClick={() => setIsNavbarOpen(false)}
                >
                  <HiOutlineXMark />
                </button>
              </div>
            </div>
          </div>
          <nav className="mainmenu-nav">
            <ul className="mainmenu">
              {modifiedMenu
                .filter((nav) => nav.categoryName !== "Home")
                .map((category, index) => {
                  const isOpen = openSubmenu === index;

                  if (category.categoryName === "Subjects") {
                    return (
                      <li
                        className={`has-dropdown has-menu-child-item ${
                          openSubmenu === index ? "open" : ""
                        }`}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <Link
                            to="/all-subjects"
                            onClick={() => {
                              setIsNavbarOpen(false);
                            }}
                          >
                            {category.categoryName}
                          </Link>
                          <i
                            className="fa-solid fa-plus text-black"
                            onClick={() => toggleSubmenu(index)}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                        <ul
                          className={`submenu ${
                            openSubmenu === index ? "d-block" : "d-none"
                          }`}
                        >
                          {subject?.length > 0 &&
                            subject
                              .filter((item) => item.showInHeader)
                              .map((item) => (
                                <li
                                  className={`has-dropdown has-menu-child-item ${
                                    newSubmenu === item.mainSubjectId
                                      ? "open"
                                      : ""
                                  }`}
                                  key={item._id}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleNewSubmenu(item.mainSubjectId);
                                  }}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <Link
                                      to={`/subject/${item.subjectUrl}`}
                                      onClick={() => setIsNavbarOpen(false)}
                                    >
                                      {item?.mainSubject}
                                    </Link>
                                    {item?.subSubject?.length > 0 &&
                                      item.subSubject.some(
                                        (sub) => sub.isActive === true
                                      ) && (
                                        <i
                                          className="fa-solid fa-plus text-black"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            toggleNewSubmenu(
                                              item.mainSubjectId
                                            );
                                          }}
                                          style={{ cursor: "pointer" }}
                                        ></i>
                                      )}
                                  </div>
                                  {item?.subSubject?.length > 0 && (
                                    <ul
                                      className={`new-submenu  ${
                                        newSubmenu === item.mainSubjectId
                                          ? "d-block"
                                          : "d-none"
                                      }`}
                                    >
                                      {item.subSubject
                                        .filter((subItem) => subItem?.isActive)
                                        .map((subItem) => (
                                          <li
                                            key={subItem._id}
                                            onClick={(e) => e.stopPropagation()}
                                            className="has-dropdown has-menu-child-item"
                                          >
                                            <Link
                                              to={`/${
                                                subject.find(
                                                  (subject) =>
                                                    subject?.mainSubjectId ===
                                                    subItem?.subjectId
                                                )?.subjectUrl
                                              }/${subItem.subSubjectUrl}`}
                                              onClick={() =>
                                                setIsNavbarOpen(false)
                                              }
                                            >
                                              <span>
                                                {truncateText(
                                                  subItem?.subSubjectName
                                                )}
                                              </span>
                                            </Link>
                                          </li>
                                        ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                        </ul>
                      </li>
                    );
                  }

                  return (
                    <li
                      key={category._id}
                      className={`has-dropdown has-menu-child-item ${
                        isOpen ? "open" : ""
                      }`}
                      onClick={() => toggleSubmenu(index)}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <Link to="#">{category.categoryName}</Link>
                        <i
                          className="fa-solid fa-plus text-black"
                          // onClick={() => toggleSubmenu(index)}
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>

                      <ul
                        className="submenu"
                        style={{ display: `${isOpen ? "block" : "none"}` }}
                      >
                        {/* change id according to DB */}
                        {category?.categoryId === "67b57f038a01c99610fc51b2" ? (
                          <li>
                            <Link
                              to="/exam-preparation"
                              onClick={() => {
                                setIsNavbarOpen(false);
                              }}
                            >
                              Exam Preparation
                            </Link>
                          </li>
                        ) : null}
                        {category.subcategories?.map((item) => (
                          <li key={item._id}>
                            <Link
                              to={item.path}
                              onClick={() => {
                                setCategoryId(item._id);
                                setIsNavbarOpen(false);
                              }}
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
            </ul>
          </nav>
          <div className="mobile-menu-bottom text-start">
            <div className="social-share-wrapper">
              <h3 className="mb-3">Find With Us</h3>
              <ul className="social-icon social-default icon-naked justify-content-start">
                {socialMedia?.map((social) => (
                  <li key={social._id}>
                    <Link className={social.name.toLowerCase()} to={social.url}>
                      <img
                        src={`${baseURL}${social.image}`}
                        alt={social.name}
                        width={35}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
