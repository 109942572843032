import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./MultiStepProgressBar.scss";
import check from "../../Assets/images/check.svg";

export const MultiStepProgressBar = ({ step, totalSteps, stepTexts = [] }) => {

  const calculatePercentage = (currentStep, totalSteps) => {
    const incrementedPercentageValue = 100 / (totalSteps - 1);
    return incrementedPercentageValue * (currentStep - 1);
  };

  return (
    <ProgressBar
      percent={calculatePercentage(step, totalSteps)}
      filledBackground="linear-gradient(89.86deg, #5956E9 33.02%, #E541ED 91.91%)"
    >
      {Array.from({ length: totalSteps })?.map((_, index) => {
        return (
          <Step transition="scale" key={index}>
            {({ accomplished, index }) => (
              <>
                <div className={`step ${accomplished ? "completed" : null}`}>
                  <div className="step-circle">
                    {/* {index + 1} */}
                    {accomplished ? <>
                      <img src={check} alt="" />
                    </> : index + 1}
                    {/* {accomplished ? "✔" : index + 1} */}
                    {/* {index + 1 === step ? "✔" : index + 1} */}
                  </div>
                  <div className="step-text">{stepTexts[index]}</div>
                </div>
              </>
            )}
          </Step>
        );
      })}
    </ProgressBar>
  );
};