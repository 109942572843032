import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom'
import bannerimage from "../../Assets/images/background/icon-bg.png";
import "./AllUniversityBanner.scss";
import { getAllUniversity } from "../../services/getAllUniversity";
import { useNavigate } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import Select from 'react-select';


const AllUniversityBanner = ({ coursesData }) => {

  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 2) {
        fetchSearchResults();
      } else {
        setOptions([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchSearchResults = async () => {
    setIsLoading(true);
    try {
      const response = await getAllUniversity(searchTerm);

      if (response?.isSuccess && response.data.length > 0) {
        const formattedOptions = response.data.map(item => ({
          value: item.universityUrl,
          label: item.universityName || item.universityUrl || 'Untitled University',
          fullItem: item
        }));
        setOptions(formattedOptions);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error("Search failed:", error);
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {

      navigate(`/university/${selectedOption.value}`);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      padding: "4px",
      display: "flex",
      alignItems: "center",
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "16px",
      border: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      color: state.isSelected ? "white" : "black",
      ":hover": {
        backgroundColor: state.isSelected ? "#007bff" : "#f1f1f1",
      },
      fontSize: "16px",
      textAlign: "left",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
  };
  return (
    <>
      <div className="banner-main-wrapper">
        <img
          className="banner-image"
          src={bannerimage}
          alt="AllUniversity-Banner"
        />
        <div className="banner-content">
          <div className="banner-content-title-wrapper">
            <div className="title title-que-banner">
              <div>
                <p>
                  <strong className="ql-size-huge ql-font-monospace">
                    Explore the {coursesData?.universityName ? `${coursesData?.universityName} Courses` : "universities"}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="banner-content-subtitle">
            <span>
              <div>
                Get {coursesData?.universityName} Assignment Help Online From Top Tutor
              </div>
            </span>
          </div>

          {/* Search Section */}
          <div className="container pt-5">
            <div className="rbt-search">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <label className="text-wight-que-banner">
                      Search for Universities
                    </label>

                  </div>
                  <div style={{ position: 'relative', width: '100%' }}>
                    <GoSearch size={16} style={{
                      position: 'absolute',
                      left: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      zIndex: 10,
                      color: "#D444ED"
                    }} />
                    <Select
                      isSearchable
                      isClearable
                      isLoading={isLoading}
                      placeholder="Search for universities..."
                      onInputChange={(newValue) => setSearchTerm(newValue)}
                      options={options}
                      onChange={handleSelectChange}
                      styles={customStyles}
                      noOptionsMessage={() => searchTerm.length > 2 ? "No university found" : "Start typing to search"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Search Section */}
        </div>
      </div>
    </>
  );
};

export default AllUniversityBanner;
